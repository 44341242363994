import withdrawals from 'apis/withdrawals';
import admin from 'apis/admin';
import axios from 'axios';

import {
  CREATE_WITHDRAWAL,
  CANCEL_WITHDRAWAL,
  FETCH_WITHDRAWALS,
  FETCH_USERS_WITHDRAWALS,
  SET_NOTIFICATION_MESSAGE,
  WITHDRAWALS_REQ_IS_LOADING,
  WITHDRAWALS_INFO_REQ_IS_LOADING,
  FETCH_WITHDRAWAL_INFO,
  WITHDRAWALS_SELECTED_COIN,
  FETCH_WITHDRAWAL_ADDRESS,
  CREATE_WITHDRAWAL_ADDRESS,
  SEND_CONFIRMATION_WITHDRAWAL_ADDRESS,
  CONFIRM_WITHDRAWAL_ADDRESS,
  FILTER_USER_WITHDRAWALS_BY_ADDRESS
} from 'actions/types';

export const confirmWithdrawalAddress = (payload, callback) => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.post('/address/confirm', payload );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success',
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CONFIRM_WITHDRAWAL_ADDRESS, payload: response.data.message })
    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    callback(response);
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    callback(false);
  }
}

export const sendConfirmationWithdrawalAddress = (payload, callback) => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.post('/address/send-confirmation', payload );

    const notificationPayload = {
      message: response.data.message,
      type: 'Confirmation email sent',
      icon: 'success',
      duration: 6
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: SEND_CONFIRMATION_WITHDRAWAL_ADDRESS, payload: response.data.message })
    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
}

export const createWithdrawalAddress = (payload, callback) => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.post('/address', payload );

    const notificationPayload = {
      message: response.data.message,
      type: 'Confirmation email sent',
      icon: 'success',
      duration: 6
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CREATE_WITHDRAWAL_ADDRESS, payload: response.data.message })
    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
}

export const fetchWithdrawalAddress = () => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.get('/address');

    dispatch({ type: FETCH_WITHDRAWAL_ADDRESS, payload: response.data.result })

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const getGasPrice = (callback) => async (dispatch, getState) => {
  try {
    const response = await axios.get("https://ethgasstation.info/json/ethgasAPI.json");
    callback(response);
  } catch (err) {
    callback(err);
  }
}

export const confirmWithdrawal = (props, callback) => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await admin.post('/withdrawals/confirm', props);

    dispatch({ type: FETCH_USERS_WITHDRAWALS, payload: response.data.result })

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });

    callback(response);
  } catch (err) {
    const notificationPayload = {
      message: "Could not confirm withdrawal",
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });

    callback(err);
  }
}

export const fetchUsersWithdrawalsHistory = () => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await admin.get('/withdrawals');

    dispatch({ type: FETCH_USERS_WITHDRAWALS, payload: response.data.result })

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const filterUserWithdrawalsByAddress = (address) => {
  return {
    type: FILTER_USER_WITHDRAWALS_BY_ADDRESS,
    payload: address
  }
}

export const fetchWithdrawalsHistory = () => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.get('/');

    dispatch({ type: FETCH_WITHDRAWALS, payload: response.data.result })

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const fetchWithdrawalInfo = (currency, callback) => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_INFO_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.get(`/info/${currency}`);

    dispatch({ type: FETCH_WITHDRAWAL_INFO, payload: response.data.result })

    dispatch({ type: WITHDRAWALS_INFO_REQ_IS_LOADING, payload: false });

    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_INFO_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
}

export const createWithdrawal = (formProps, callback) => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.post('/', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CREATE_WITHDRAWAL, payload: response.data.message })
    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
}

export const cancelWithdrawal = (withdrawalId, callback) => async (dispatch, getState) => {
  try {
    withdrawals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: true });
    const response = await withdrawals.post(`/cancel/${withdrawalId}`);

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CANCEL_WITHDRAWAL, payload: withdrawalId })
    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }
    callback();
    dispatch({ type: WITHDRAWALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const selectWithdrawalCoin = (coinObject) => {
  return {
    type: WITHDRAWALS_SELECTED_COIN,
    payload: coinObject
  }
}

export const loadingRequest = (isLoading) => {
  return {
    type: WITHDRAWALS_REQ_IS_LOADING,
    payload: isLoading
  }
}
