import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import { fetchAllUsers, fetchUsers, filterUsersByEmail, reset2Fa, setFetchUsersDates } from 'actions/admin';
import { setNotificationMessage, clearNotificationMessage } from 'actions/shared';
import { Row, Table, Card, Input, Col, Statistic } from 'antd';
import moment from 'moment';
import exist from 'exist.js';
import Loader from "components/shared/Loader";
import CsvDownloadUsers from "components/shared/CsvDownloadUsers";
import Scrollbar from 'react-smooth-scrollbar';
import { DatePicker, Button, Icon, Modal } from 'antd';
import { pointFormatter } from "utils";
const { RangePicker } = DatePicker;

const { confirm } = Modal;

class UsersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchByEmail: '',
      startDate: false,
      endDate: false
    }
  }

  componentDidMount() {
    let firstDayPrevMonth = moment().subtract(30, 'days').unix();
    let lastDayPrevMonth = moment().unix();
    this.props.fetchUsers(firstDayPrevMonth, lastDayPrevMonth);
  }

  handleFetchUsers() {
    if (moment(this.props.fetchUsersStartDate * 1000).format('Y') === '1970') {
      this.props.setFetchUsersDates(false, false);
      this.props.fetchUsers();
    } else {
      this.props.fetchUsers(this.props.fetchUsersStartDate, this.props.fetchUsersEndDate);
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleSearch(value)  {
    let _this = this;

    _this.props.filterUsersByEmail(value)

    this.setState({
      searchByEmail: value
    })
  }

  renderExchangeValue(record) {
    const accountName = exist.get(record, "exchanges[0].accountName");
    return accountName || '-';
  }

  renderTotalBalance(record, currency) {
    const totalBalance = exist.get(record, `balances[${currency}]`);
    return totalBalance ? parseFloat(totalBalance.toFixed(6)) : '-';
  }

  renderTotalBalanceSort(record, currency) {
    const totalBalance = exist.get(record, `balances[${currency}]`);
    return totalBalance || 0;
  }

  handleRangePicker(dateObject) {
    if (dateObject.length > 0) {
      this.props.setFetchUsersDates(moment(dateObject[0]).unix(), moment(dateObject[1]).unix());
    } else {
      this.props.setFetchUsersDates(false, false);
    }
  }

  reset2FaConfirm(user) {
    let _this = this;
    confirm({
      title: `Are you sure?`,
      content: `2FA for user ${user.email} will be removed.`,
      onOk() {
        return new Promise((resolve, reject) => {
          _this.props.reset2Fa(user._id)
          resolve();
        }).catch((resolve, reject) => reject());
      },
      onCancel() {},
    });
  }

  render() {
    const columns = [
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.email < a.email,
        render: value => value ? value : '-'
      },
      {
        title: 'Exchange',
        dataIndex: 'exchanges',
        key: 'exchanges',
        render: (text, record) => this.renderExchangeValue(record)
      },
      {
        title: 'CYL',
        dataIndex: 'cyl',
        key: 'cyl',
        defaultSortOrder: 'descend',
        sorter: (a, b) => this.renderTotalBalanceSort(b, 'cyl') - this.renderTotalBalanceSort(a, 'cyl'),
        render: (text, record) => this.renderTotalBalance(record, 'cyl')
      },
      {
        title: 'ETH',
        dataIndex: 'eth',
        key: 'eth',
        defaultSortOrder: 'descend',
        sorter: (a, b) => this.renderTotalBalanceSort(b, 'eth') - this.renderTotalBalanceSort(a, 'eth'),
        render: (text, record) => this.renderTotalBalance(record, 'eth')
      },
      {
        title: 'USDT',
        dataIndex: 'usdt',
        key: 'usdt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => this.renderTotalBalanceSort(b, 'usdt') - this.renderTotalBalanceSort(a, 'usdt'),
        render: (text, record) => this.renderTotalBalance(record, 'usdt')
      },
      {
        title: 'Fees $',
        dataIndex: 'totalFees',
        key: 'totalFees',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.totalFees - a.totalFees,
        render: value => value ? pointFormatter(value) : '-'
      },
      {
        title: 'Net P/L $',
        dataIndex: 'totalNetProfit',
        key: 'totalNetProfit',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.totalNetProfit - a.totalNetProfit,
        render: value => value ? pointFormatter(value) : '-'
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Button
            icon="lock"
            className="flex items-center"
            type="secondary"
            shape="round"
            onClick={() => this.reset2FaConfirm(record)}>
            Reset 2FA
          </Button>
        ),
      },
    ];

    const dateFormat = 'YYYY/MM/DD';

    if (this.props.usersRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <Row className="mb-6 md:flex md:items-center">
            <Col span={6} className="flex items-end justify-between md:max-w-xs">
              <div className="flex flex-col mr-2 w-full">
                <div className="mb-2 font-semibold" style={{ color: "rgba(0,0,0,0.85)" }}>Search</div>
                <Input type="email" value={this.state.searchByEmail} onChange={(e) => this.handleSearch(e.target.value)} placeholder="Email or account name"></Input>
              </div>
            </Col>

            <Col span={5} offset={1} className="mt-8 md:mt-0">
              <Card size="small">
                <Statistic
                  className="text-center font-semibold"
                  title="Registered users"
                  value={this.props.usersListNotFiltered.length}
                  valueStyle={{ color: '#3f8600', fontSize: '30px', fontWeight: '500' }}
                />
              </Card>
            </Col>
            <Col span={5} offset={1} className="mt-8 md:mt-0">
              <Card size="small">
                <Statistic
                  className="text-center font-semibold"
                  title="Total fees"
                  value={pointFormatter(this.props.usersListNotFiltered.reduce((total, user) => total + user.totalFees, 0))}
                  valueStyle={{ color: '#3f8600', fontSize: '30px', fontWeight: '500' }}
                />
              </Card>
            </Col>
            <Col span={5} offset={1} className="mt-8 md:mt-0">
              <Card size="small">
                <Statistic
                  className="text-center font-semibold"
                  title="Total Net P/L"
                  value={pointFormatter(this.props.usersListNotFiltered.reduce((total, user) => total + user.totalNetProfit, 0))}
                  valueStyle={{ color: '#3f8600', fontSize: '30px', fontWeight: '500' }}
                />
              </Card>
            </Col>
          </Row>

          <Row className="fade-in">
            <React.Fragment>
              <Row className="my-6">
                <div className="flex justify-end mb-6">
                  <RangePicker
                    defaultValue={[
                      this.props.fetchUsersStartDate ? moment(this.props.fetchUsersStartDate * 1000) : '',
                      this.props.fetchUsersEndDate ? moment(this.props.fetchUsersEndDate * 1000) : ''
                    ]}
                    onChange={(dateObject) => this.handleRangePicker(dateObject)}
                    ranges={{
                      'All': [moment('1970/01/01'), moment()],
                      Today: [moment(), moment()],
                      'Last 30 days': [moment().subtract(30, 'days'), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      'This Year': [moment().startOf('year'), moment().endOf('year')],
                    }}
                    format={dateFormat}
                  />
                  <Button disabled={!this.props.fetchUsersStartDate} onClick={() => this.handleFetchUsers()} className="ml-6 flex items-center" type="primary">
                    <Icon type="login" style={{fontSize: "16px"}}/>
                    Fetch users
                  </Button>
                  {/* <Button disabled={!this.props.fetchUsersStartDate} onClick={() => this.handleFetchUsers({shouldReset: true})} className="ml-6 flex items-center" type="primary">
                    <Icon type="login" style={{fontSize: "16px"}}/>
                    Reset dates
                  </Button> */}
                  <CsvDownloadUsers className="ml-4 flex items-center" data={this.props.usersList}>Download users</CsvDownloadUsers>
                </div>
                <h3 className="title-underline font-semibold">Users</h3>
                <Scrollbar>
                  <Table
                    rowClassName="table-row"
                    dataSource={this.props.usersList}
                    columns={columns}
                  />
                </Scrollbar>
              </Row>
            </React.Fragment>
          </Row>
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    usersList: Object.values(state.admin.usersList),
    usersListNotFiltered: Object.values(state.admin.usersListNotFiltered),
    notification: state.shared.notification,
    usersRequestLoading: state.admin.usersRequestLoading,
    reset2FaRequestLoading: state.admin.reset2FaRequestLoading,
    fetchUsersStartDate: state.admin.fetchUsersStartDate,
    fetchUsersEndDate: state.admin.fetchUsersEndDate
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, { setNotificationMessage, clearNotificationMessage, fetchAllUsers, fetchUsers, filterUsersByEmail, reset2Fa, setFetchUsersDates })
)(UsersPage);
