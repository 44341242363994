import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from 'reducers/auth';
import exchanges from 'reducers/exchanges';
import trades from 'reducers/trades';
import deposits from 'reducers/deposits';
import withdrawals from 'reducers/withdrawals';
import shared from 'reducers/shared';
import admin from 'reducers/admin';
import reports from 'reducers/reports';
import profile from 'reducers/profile';
import referrals from 'reducers/referrals';
import traders from 'reducers/traders';

export default combineReducers({
  auth,
  exchanges,
  shared,
  admin,
  trades,
  deposits,
  withdrawals,
  reports,
  profile,
  referrals,
  traders,
  form: formReducer
});