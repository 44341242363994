import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchEquityGraphHistory } from 'actions/reports';
import { fetchAdminEquityGraphHistory } from 'actions/admin';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Widget from 'components/shared/Widget';

class EquityGraph extends React.Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();
  }

  render() {
    const { history, fetchEquityHistory, fetchAdminEquityGraphHistory, userEmail } = this.props;

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    const options = {
      rangeSelector: {
        allButtonsEnabled: true,
        buttons: [{
          type: 'month',
          count: 1,
          text: '1m',
          events: {
              click: function () {
                userEmail ? fetchAdminEquityGraphHistory(userEmail, 31) : fetchEquityHistory(31);
              }
          }
      }, {
          type: 'month',
          count: 3,
          text: '3m',
          events: {
            click: function () {
              userEmail ? fetchAdminEquityGraphHistory(userEmail, 92) : fetchEquityHistory(92);
            }
        }
      }, {
          type: 'month',
          count: 6,
          text: '6m',
          events: {
            click: function () {
              userEmail ? fetchAdminEquityGraphHistory(userEmail, 183) : fetchEquityHistory(183);
            }
        }
      }, {
          type: 'ytd',
          text: 'YTD',
          events: {
            click: function () {
              userEmail ? fetchAdminEquityGraphHistory(userEmail, 366) : fetchEquityHistory(366);
            }
          }
      }, {
          type: 'year',
          count: 1,
          text: '1y',
          events: {
            click: function () {
              userEmail ? fetchAdminEquityGraphHistory(userEmail, 366) : fetchEquityHistory(366);
            }
          }
      }, {
          type: 'all',
          text: 'All',
          events: {
            click: function () {
              userEmail ? fetchAdminEquityGraphHistory(userEmail, 9999) : fetchEquityHistory(9999);
            }
          }
      }]
      },
      tooltip: {
        xDateFormat: "%A, %b %d %Y",
        split: false,
        valuePrefix: '$',
        valueDecimals: 2
      },
      yAxis: {
        opposite: false,
        labels: {
          format: '${value}'
        }
      },
      series: [{
        name: 'Equity',
        data: history,
        color: 'rgb(97, 205, 187)',
      }]
    }

    return (
      <Widget>
        <div className="ant-row-flex vx-px-4 vx-pt-4" style={{marginBottom: "30px"}}>
          <h2 className="h4 vx-mb-3" style={{fontWeight: 600}}>Equity Chart</h2>
        </div>
        <div className="report-equity-graph">
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={options}
            ref={this.chart}
          />
        </div>
      </Widget>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    reportsEquityHistory: state.reports.equityHistory,
    historyEquityRequestLoading: state.reports.historyEquityRequestLoading
  }
}

export default compose(
  connect(mapStateToProps, { fetchEquityGraphHistory, fetchAdminEquityGraphHistory })
)(EquityGraph);