import React from 'react';
// import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { ResponsiveBar } from '@nivo/bar'
import Widget from '../Widget';
import EmptyState from 'components/shared/EmptyState';
import {
  EXCHANGES_PAGE_URL
} from 'containers/Router/constants';
import exist from 'exist.js';
import moment from 'moment';
import { pointFormatter } from "utils";
import Scrollbar from 'react-smooth-scrollbar';

function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className="balance-history-tooltip">
        <div className="label">{label}</div>

        <div className="record">
          <div className="dot" style={{backgroundColor: `${payload[0].fidd}`}}></div>
          <div className="currency"><b>${Number(payload[0].value).toFixed(2)}</b></div>
        </div>
      </div>
    );
  }

  return null;
}

/* eslint-disable react/prefer-stateless-function */
class BalanceHistory extends React.Component {
  handleChange(value) {
    // console.log(`selected ${value}`);
  }

  render() {
    let showGraphs = false;
    let messageEmptyState;
    const { user } = this.props;

    const accountName = exist.get(user, "exchanges[0].accountName");

    if (!accountName) {
      messageEmptyState = <div>Not enough data available<br/>Add an <b><a href={EXCHANGES_PAGE_URL}>exchange</a></b>.</div>;
    }

    let maxUSD = 0;
    let minUSD = 0;

    let divergingData;

    if (this.props.history && this.props.history.length > 0) {
      showGraphs = true;

      divergingData = this.props.history.map(entry => {
        // console.log(entry);
        if (entry.USD > maxUSD) {
          maxUSD = entry.USD;
          minUSD = -(entry.USD);
        }

        return {
          'USD > 0': (entry.USD > 0) ? entry.USD : 0,
          'USD < 0': (entry.USD < 0) ? entry.USD : 0,
          'createdAt': entry.createdAt
        };
      });
    }

    const divergingCommonProps = {
      data: divergingData,
      indexBy: 'createdAt',
      padding: 0.2,
      labelTextColor: 'inherit:darker(1.4)',
      labelSkipWidth: 16,
      labelSkipHeight: 16,
      minValue: minUSD,
      maxValue: maxUSD,
      enableGridX: false,
      enableGridY: false,
      labelTextColor: 'inherit:darker(1.2)',
      axisLeft: true,
      axisRight: null,
      enableLabel: false,
      markers: [
        {
          axis: 'y',
          value: 0,
          lineStyle: { strokeOpacity: 0 },
          textStyle: { fill: '#2ebca6' },
          legend: 'profit',
          legendPosition: 'top-left',
          legendOrientation: 'vertical',
          legendOffsetY: 50,
          legendOffsetX: -65
        },
        {
          axis: 'y',
          value: 0,
          lineStyle: { stroke: '#d2d2d2', strokeWidth: 1 },
          textStyle: { fill: '#e25c3b' },
          legend: 'loss',
          legendPosition: 'bottom-left',
          legendOrientation: 'vertical',
          legendOffsetY: 50,
          legendOffsetX: -65
        },
      ],
    };

    const theme = {
        axis: {
            ticks: {
                text: {
                    fontSize: 11,
                }
            }
        }
    }

    return (
      <div>
        <Widget styleName="vx-card-fudd">
          <div className="ant-row-flex vx-px-4 vx-pt-4" style={{marginBottom: "30px"}}>
            <h2 className="h4 vx-mb-3" style={{fontWeight: 600}}>Daily Profit & Loss History ($)</h2>
          </div>

          {showGraphs &&
            <Scrollbar>
              <div className="history-graph" style={{height: "280px"}}>
                <ResponsiveBar
                  {...divergingCommonProps}
                  keys={[ 'USD > 0', 'USD < 0' ]}
                  theme={theme}
                  colors={['#61cdbb', '#f47560']}
                  margin={{ top: 0, right: 80, bottom: 80, left: 80 }}
                  axisBottom={{
                    tickValues: 5,
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 40,
                    tickRotation: -30,
                    format: d => `${moment(d).format('MMM D')}`
                  }}
                  padding={0.3}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  innerPadding={1}
                  tooltip={({ id, value, color }) => (
                    <strong>
                      {pointFormatter(value)}
                    </strong>
                  )}
                />
              </div>
            </Scrollbar>
          }


          {!showGraphs && <EmptyState message={messageEmptyState} minHeight="120px"/>}
        </Widget>
      </div>
    );
  }
}

export default BalanceHistory;
