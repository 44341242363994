import React from 'react';

class RealizedReports extends React.Component {
  render() {
    return (
      <div className="flex">
        <div className="strike-rate-element-inner">
          <div>Total Realised P&L</div>
          <div className="positive">6.07271507 <span>BTC</span></div>
          <div>≅ 53.56 USD</div>
        </div>
        <div className="strike-rate-element-inner">
          <div>Daily Realised P&L</div>
          <div className="negative">-0.48724426 <span>BTC</span></div>
          <div>≅ -4.29 USD</div>
        </div>
        <div className="strike-rate-element-inner">
          <div>Unrealised P&L</div>
          <div className="positive">0.00000000 <span>BTC</span></div>
          <div>≅ 0.00 USD</div>
        </div>
      </div>
    )
  }
}

export default RealizedReports;