import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'actions/exchanges';
import requireAuth from 'components/requireAuth';
import ExchangeForm from 'components/shared/ExchangeForm';
import ExchangesList from 'components/ExchangesList';
import { Row, Col, Select } from 'antd';
import Loader from "components/shared/Loader";

import ExchangeIcon from 'components/shared/ExchangeIcon';
import binanceLogoSimple from 'images/icons/binance.svg';
import bybitLogoSimple from 'images/icons/bybit.svg';

const { Option } = Select;

class ExchangesPage extends React.Component {
  state = {
    selectedExchange: ''
  }

  componentDidMount() {
    this.props.fetchExchanges();
  }

  renderExchangesList() {
    return (
      <ExchangesList/>
    )
  }

  handleExchangeChange = (value) => {
    switch (value) {
      case 'binance':
        this.setState({
          selectedExchange: 'binance'
        })
        break;
      case 'binance_us':
        this.setState({
          selectedExchange: 'binance_us'
        })
        break;
      case 'bybit':
        this.setState({
          selectedExchange: 'bybit'
        })
        break;
      default:
    }
  }

  exchangeAdded = (isAdded) => {
    if (isAdded) {
      this.setState({
        selectedExchange: ''
      })
    }
  }

  renderCreateExchange() {
    return (
      <Row className="fade-in">
        <div className="font-semibold mb-2">Select an exchange</div>
        <Select
          size={"large"}
          className="w-full h-10 mb-6"
          placeholder="Select an exchange you want to connect"
          onChange={this.handleExchangeChange}>

          <Option value="binance">
            <ExchangeIcon label="Binance" icon={binanceLogoSimple} coin="binance"/>
          </Option>

          <Option value="binance_us">
            <ExchangeIcon label="Binance US" icon={binanceLogoSimple} coin="binance_us"/>
          </Option>

          <Option value="bybit">
            <ExchangeIcon label="Bybit" icon={bybitLogoSimple} coin="bybit"/>
          </Option>
        </Select>

        {this.state.selectedExchange &&
          <Col span={24} className="mb-10">
            <ExchangeForm
              type="create"
              exchange={this.state.selectedExchange}
              exchangeAdded={this.exchangeAdded}
              iconLoading={this.props.exchangesRequestLoading}>
            </ExchangeForm>
          </Col>
        }
      </Row>
    )
  }

  renderExchangesPageContent() {
    if (this.props.exchangesRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          {this.renderCreateExchange()}
          {this.renderExchangesList()}
        </React.Fragment>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderExchangesPageContent()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    exchanges: Object.values(state.exchanges.exchangesList),
    exchangesRequestLoading: state.exchanges.exchangesRequestLoading
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, actions),
)(ExchangesPage);
