import React from 'react';
import { Form } from 'antd';

import Button from 'components/shared/AuthForm/components/Button';
import Field from 'components/shared/AuthForm/components/Field';
import Label from 'components/shared/AuthForm/components/Label';
import { Field as ReduxField } from 'redux-form';
import Recaptcha from 'components/shared/Recaptcha';

class AuthForm extends React.Component {
  renderFormButton = () => {
    switch (this.props.type) {
      case "signin":
        return (
          <React.Fragment>
            <Button 
              name="Sign In"
              icon="login"
              iconLoading={this.props.iconLoading}
            />
          </React.Fragment>
        );
      case "signup":
        return (
          <React.Fragment>
            <Button 
              name="Sign Up"
              icon="login"
              iconLoading={this.props.iconLoading}
            />
          </React.Fragment>
        );
      default:
        console.log("Can not render form button");
    }
  }

  render() {
    const { onSubmit, showTwoFactorInput } = this.props;

    return (
      <Form onSubmit={onSubmit}>
        <div className="flex flex-col">
          <Label name="Email"/>
          <Field name="email" type="text"/>
        </div>

        <div className="flex flex-col">
          <Label name="Password"/>
          <Field name="password" type="password"/>
        </div>

        {showTwoFactorInput && <div className="flex flex-col">
          <Label name="2FA code"/>
          <Field name="code" type="text"/>
        </div>}

        {showTwoFactorInput && <div className="mb-6 font-semibold" style={{color: "#3867d6"}}>
          <a href="mailto:support@crystaltoken.co?subject=I lost my 2FA Authenticator">Can't access 2FA Authenticator?</a>
        </div>}

        <ReduxField
          component={Recaptcha}
          classNames="justify-content-end"
          name="recaptcha"
          ref={el => {
          this.captcha = el;
          }}
          withRef
        />

        {this.renderFormButton()}
      </Form>
    )
  }
}

export default AuthForm;