module.exports = [
  "United States",
  "United States Minor Outlying Islands",
  "Canada",
  "Singapore",
  "Cuba",
  "Crimea",
  "Sevastopol",
  "Iran", 
  "Syrian Arab Republic",
  "Syria",
  "North Korea",
  "Sudan"
]