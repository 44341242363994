import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import * as actions from 'actions/withdrawals';
import { Row, Table, Modal } from 'antd';
import WithdrawalForm from 'components/shared/WithdrawalForm';
import WithdrawalHistory from 'components/shared/Statistics/WithdrawalHistory';
import Web3 from 'web3';
import moment from 'moment';
import Loader from "components/shared/Loader";
import Scrollbar from 'react-smooth-scrollbar';

const { confirm } = Modal;
const web3 = new Web3();
// Todo: Confirm modal with REDUX and Portals

class WithdrawalPage extends React.Component {
  componentDidMount() {
    this.props.fetchWithdrawalsHistory();
    this.props.fetchWithdrawalAddress();
  }

  showConfirm(withdrawal) {
    let _this = this;
    confirm({
      title: 'Are you sure?',
      content: `The withdrawal will be cancelled.`,
      onOk() {
        return new Promise((resolve, reject) => {
          _this.props.cancelWithdrawal(withdrawal._id, (hasError) => {
            if (!hasError) {
              _this.props.fetchWithdrawalsHistory();
              resolve()
            } else {
              resolve()
            }
          });
        }).catch((err) => (err));
      },
      onCancel() {},
    });
  }

  onSubmit = (formProps) => {
    let formBody = {
      address: formProps.wallet_address,
      amount: Number(formProps.withdrawal_amount),
      currency: this.props.selectedWithdrawalCoin.name,
      balance: this.props.withdrawalInfo.balance,
      fee: this.props.withdrawalInfo.fee
    }

    this.props.createWithdrawal(formBody, () => {
      this.props.fetchWithdrawalsHistory();
    });
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderStatusButton(status) {
    let capitalizedStatus;

    if (status) {
      capitalizedStatus = this.capitalizeFirstLetter(status);
    }

    switch(status) {
      case "Pending":
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
      case "Complete":
        return <div className="btn--status completed">{capitalizedStatus}</div>
      case "Cancelled":
        return <div className="btn--status cancelled">{capitalizedStatus}</div>
      default:
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
    }
  }

  renderTransactionElement = (transactionId, currency) => {
    if (currency == 'ETH' || currency == 'USDT' || currency == 'CYL') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    } else if (currency == 'BTC') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://blockchain.info/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    }
  }

  renderTradesTable() {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: value => value ? value : '-'
      },
      {
        title: 'Wallet Address',
        dataIndex: 'address',
        key: 'address',
        render: value => value ? value : '-'
      },
      {
        title: 'Blockchain Record',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: (value, record) => value ? this.renderTransactionElement(value, record.currency) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => this.renderStatusButton(status)
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            {record.status == 'Pending' ?
            <a className="text-crystal-primary" onClick={() => {this.showConfirm(record)}}>Cancel</a>
            : ''}
          </span>
        ),
      }
    ];
    
    return (
      <Row>
        <h3 className="title-underline font-semibold">Withdrawals</h3>
        <Scrollbar>
          <Table 
            rowClassName="table-row"
            dataSource={this.props.withdrawals} 
            columns={columns}
          />
        </Scrollbar>
      </Row>
    )
  }

  renderTradesPageContent() {
    return (
      <React.Fragment>
        {this.renderTradesTable()}
      </React.Fragment>
    )
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.withdrawalsRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <WithdrawalHistory withdrawals={this.props.withdrawals}/>
          <Row className="fade-in">
            <WithdrawalForm 
              className="mb-16"
              addresses={this.props.withdrawalsAddress}
              onSubmit={handleSubmit(this.onSubmit)}
            />
            {this.renderTradesPageContent()}
          </Row>
        </React.Fragment>
      )
    }
  }
}

function confirmAddress (type, address, props) {
  let selectedWithdrawalCoin = props.selectedWithdrawalCoin.name;
  
  confirm({
    title: `Are you sure you want to whitelist this address for withdrawals?`,
    content: `${String(address)} will be whitelisted.`,
    onOk() {
      return new Promise((resolve, reject) => {
        switch(type) {
          case 'whitelist':
            props.createWithdrawalAddress({ address, currency: selectedWithdrawalCoin }, (hasError) => {
              if (!hasError) {
                props.fetchWithdrawalAddress();
                props.reset();
                resolve()
              } else {
                resolve()
              }
            });
            break;
          case 'confirm':
            props.sendConfirmationWithdrawalAddress({ address, currency: selectedWithdrawalCoin }, (hasError) => {
              if (!hasError) {
                props.fetchWithdrawalAddress();
                props.reset();
                resolve()
              } else {
                resolve()
              }
            });
            break;
          default:
            console.log('whitelist');
        }
      }).catch((err) => (err));
    },
    onCancel() {},
  });
}

const validate = (values, props) => {
  const { withdrawalsAddress } = props;

  let confirmedWithdrawalsAddress = [];
  let whitelistedWithdrawalsAddress = [];

  _.map(withdrawalsAddress, (addressObj) => {
    whitelistedWithdrawalsAddress.push(addressObj.address)
    if (addressObj.confirmed) {
      confirmedWithdrawalsAddress.push(addressObj.address)
    }
  });

  const errors = {};
  let feeValue = 0;

  if (!values.wallet_address) {
    errors.wallet_address = "Wallet address is required.";
  }

  if (props.withdrawalInfo) {
    feeValue = props.withdrawalInfo.fee;
  }

  if (values.withdrawal_amount < feeValue * 2) {
    errors.withdrawal_amount = `Minimum withdraw amount: ${props.withdrawalInfo.fee * 2}`;
  }

  if (!values.withdrawal_amount) {
    errors.withdrawal_amount = "Withdrawal amount is required.";
  }

  let walletAddressConfirmed = confirmedWithdrawalsAddress.includes(values.wallet_address);
  let walletAddressWhitelisted = whitelistedWithdrawalsAddress.includes(values.wallet_address);

  if (!web3.utils.isAddress(values.wallet_address)) {
    errors.wallet_address = "Wallet address is not valid.";
  } else if (!walletAddressWhitelisted) {
    errors.wallet_address = 
      <div>
        Please whitelist this withdrawal address. 
        <a onClick={() => {confirmAddress('whitelist', values.wallet_address, props)}}
          className="ml-1"
          target="_blank">
            <b>Whitelist address.</b>
        </a>
      </div>
  } else if (walletAddressWhitelisted && !walletAddressConfirmed) {
    errors.wallet_address = 
      <div>
        Please confirm this address. 
        <a onClick={() => {confirmAddress('confirm', values.wallet_address, props)}}
          className="ml-1"
          target="_blank">
            <b>Resend confirmation email.</b>
        </a>
      </div>
  }

  return errors;
};

const mapStateToProps = (state) => {
  return { 
    withdrawals: Object.values(state.withdrawals.withdrawalsList),
    notification: state.shared.notification,
    selectedWithdrawalCoin: state.withdrawals.selectedWithdrawalCoin,
    withdrawalInfo: state.withdrawals.withdrawalInfo,
    withdrawalsRequestLoading: state.withdrawals.withdrawalsRequestLoading,
    withdrawalsAddress: Object.values(state.withdrawals.withdrawalsAddress),
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, actions),
  reduxForm({
    form: 'withdrawal',
    enableReinitialize: true,
    validate
  })
)(WithdrawalPage);
