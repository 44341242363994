import React from 'react';

import { Field as ReduxField } from 'redux-form';
import { 
  Form, 
  Input, 
} from 'antd'

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, children, hasFeedback, label, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <Component {...input} {...rest} children={children} />
    </FormItem>
  );
};

const AInput = makeField(Input);

const Field = (props) => {
  return (
    <ReduxField
      name={props.name}
      type={props.type}
      min={props.min}
      defaultValue={props.min}
      component={AInput}
      autoComplete="something-unsupported"
      autoCorrect="something-unsupported"
      spellCheck="something-unsupported"
      placeholder={props.placeholder}
      className="w-full h-7"
    />
  )
}

export default Field;