import _ from 'lodash';

import { 
  FETCH_DEPOSITS,
  FETCH_DEPOSIT_ADDRESS,
  DEPOSITS_REQ_IS_LOADING,
  DEPOSITS_SELECTED_COIN,
  DEPOSITS_ADDRESS_REQ_IS_LOADING
} from 'actions/types';

const INITIAL_STATE = {
  depositsList: [],
  depositsRequestLoading: false,
  depositsAddressRequestLoading: false,
  selectedDepositCoin: {
    name: undefined,
    fullName: undefined,
  },
  depositAddress: undefined
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case FETCH_DEPOSITS:
      return { ...state, depositsList: _.mapKeys(action.payload, '_id') };
    case FETCH_DEPOSIT_ADDRESS:
      return { ...state, depositAddress: action.payload};
    case DEPOSITS_SELECTED_COIN:
        return { ...state, selectedDepositCoin: action.payload};
    case DEPOSITS_REQ_IS_LOADING:
      return { ...state, depositsRequestLoading: action.payload };
    case DEPOSITS_ADDRESS_REQ_IS_LOADING:
      return { ...state, depositsAddressRequestLoading: action.payload };
    default:
      return state;
  }
}
