import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import TraderForm from './TraderForm';
import * as actions from 'actions/traders';
import { Card, Button, Row, Col, Modal, Switch, Icon } from 'antd';
import {
  MARKETPLACE_URL,
  MARKETPLACE_URL_SINGLE
} from 'containers/Router/constants';

class AdminTradersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTraderForm: false,
      editMode: false,
      editTraderId: null
    }
  }

  componentDidMount() {
    this.props.fetchTraders();
  }

  onSubmit = (formProps) => {
    const _this = this;
    this.props.createTrader(formProps, () => {
      this.setState({
        showTraderForm: false
      })
      setTimeout(function() {
        _this.props.fetchTraders();
      }, 100);
    });
  };

  onEdit = (formProps) => {
    const _this = this;
    this.props.updateTrader(this.state.editTraderId, formProps, () => {
      this.setState({
        showTraderForm: false,
        editMode: false,
        editTraderId: null
      })
      setTimeout(function() {
        _this.props.fetchTraders();
      }, 100);
    });
  };

  deleteTrader = (id) => {
    this.props.deleteTrader(id);
  }

  editTrader = (id) => {
    this.setState({
      editTraderId: id,
      editMode: true,
      showTraderForm: true
    })
  }

  render() {
    return (
      <React.Fragment>
        <Button
          className="mb-4"
          onClick={() => this.setState({
            showTraderForm: !this.state.showTraderForm,
            editMode: false,
            editTraderId: null
          })}>
          {!this.state.showTraderForm ? 'Add a new trader' : 'Show traders'}
        </Button>

        {this.props.tradesRequestLoading && <div>Loading</div>}

        {!this.props.tradesRequestLoading && this.props.traders && !this.state.showTraderForm && !this.state.editMode &&
          <Row gutter={16} className="fade-in">
            {this.props.traders.map((trader) => {
              return <Col span={24} key={trader._id} className="exchange-card lg:max-w-sm">
                <Card
                  className="mb-4 text-center"
                  title={trader.name}
                  extra={
                    <>
                      <Button
                        className="mr-4"
                        onClick={() => this.editTrader(trader._id)} type="secondary">Edit</Button>
                      <Button
                        type="danger"
                        onClick={() => this.deleteTrader(trader._id)}>Delete</Button>
                    </>
                  }>
                  <div className="flex justify-center">
                  <div>
                    <img
                      className="mb-6"
                      width="150px" height="150px" src="https://d1d6vn08901bud.cloudfront.net/Crystal%20Token_logo.png">
                    </img>
                  </div>
                  </div>
                  <div className="justify-center flex">
                    <div className="font-semibold mr-1">Cost:</div>
                    <div className="mr-1">{trader.cost}</div>
                    {/* <b>CYL</b> */}
                  </div>

                  <div className="flex justify-center mb-6">
                    <div className="font-semibold mr-1">Exchange:</div>
                    {trader.exchange}
                  </div>

                  <a href={`${MARKETPLACE_URL}/${trader._id}`} className="w-full">
                    <Button className="w-full" type="primary">View more info</Button>
                  </a>
                </Card>
              </Col>
            })}
          </Row>
        }

        {this.state.showTraderForm && !this.state.editMode &&
          <TraderForm
          handleSubmit={this.onSubmit}
          iconLoading={false}/>
        }

        {this.state.showTraderForm && this.state.editMode && this.state.editTraderId &&
          <TraderForm
          traderId={this.state.editTraderId}
          handleSubmit={this.onEdit}
          iconLoading={false}/>
        }
      </React.Fragment>
    )
  }
}

const validate = values => {
  const errors = {};

  if (!values.overview) {
    errors.overview = "Overview required.";
  }

  if (!values.name) {
    errors.name = "Trader name required.";
  }

  if (!values.support_email) {
    errors.support_email = "Support email required.";
  }

  if (!values.cost) {
    errors.cost = "Cost required.";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    traders: Object.values(state.traders.tradersList),
    tradesRequestLoading: state.traders.tradesRequestLoading
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, actions),
  reduxForm({
    form: 'trader' ,
    validate
  })
)(AdminTradersPage);
