import {
  AUTH_USER,
  AUTH_VALID_TOKEN,
  AUTH_IS_LOADING,
  SET_USER_SELECTED_FEE_CURRENCY,
  FETCH_USER
} from 'actions/types';

const INITIAL_STATE = {
  authenticated: '',
  validToken: false,
  authRequestLoading: false,
  selectedFeeCurrency: ''
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case FETCH_USER:
      return { ...state, user: action.payload }
    case AUTH_USER:
      return { ...state, authenticated: action.payload.token, user: action.payload.user }
    case AUTH_IS_LOADING:
      return { ...state, authRequestLoading: action.payload }
    case AUTH_VALID_TOKEN:
      return { ...state, validToken: action.payload.tokenValid, user: action.payload.user }
    case SET_USER_SELECTED_FEE_CURRENCY:
      return { ...state, selectedFeeCurrency: action.payload.selectedFeeCurrency }
    default:
      return state;
  }
}