import { 
  FETCH_TRADES,
  TRADES_REQ_IS_LOADING
} from 'actions/types';

const INITIAL_STATE = {
  tradesList: [],
  tradesRequestLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case FETCH_TRADES:
      return { ...state, tradesList: action.payload };
    case TRADES_REQ_IS_LOADING:
      return { ...state, tradesRequestLoading: action.payload }
    default:
      return state;
  }
}
