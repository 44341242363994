import users from 'apis/users';
import signatures from 'apis/signatures';

import { 
  SET_TWO_FACTOR_SECRET,
  GET_TWO_FACTOR_SECRET,
  SET_NOTIFICATION_MESSAGE,
  USERS_REQ_IS_LOADING,
  CREATE_SIGNATURE_EMBEDDED_LINK,
  SIGNATURES_REQ_IS_LOADING
} from 'actions/types';

export const getTwoFactorSecret = (callback) => async (dispatch, getState) => {
  try {
    users.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });
    const response = await users.get('/two-factor-secret');

    dispatch({ type: GET_TWO_FACTOR_SECRET, payload: response.data.result })

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    callback(response.data);
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const setTwoFactorSecret = (formProps, callback) => async (dispatch, getState) => {
  try {
    users.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });
    const response = await users.post('/two-factor-secret', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: SET_TWO_FACTOR_SECRET, payload: response.data.message })
    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const removeTwoFactorSecret = (callback) => async (dispatch, getState) => {
  try {
    users.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });
    const response = await users.delete('/two-factor-secret');

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const createContract = (formProps, callback) => async (dispatch, getState) => {
  try {
    signatures.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: SIGNATURES_REQ_IS_LOADING, payload: true });
    const response = await signatures.post('/create', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CREATE_SIGNATURE_EMBEDDED_LINK, payload: response.data.result })
    dispatch({ type: SIGNATURES_REQ_IS_LOADING, payload: false });
    localStorage.setItem("step-2", "done");
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: SIGNATURES_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};