import React from 'react';
import { Button } from 'antd';

class Finish extends React.Component {
  handleNextStep = () => {
    const { currentStep } = this.props;
    localStorage.setItem(`step-${currentStep}`, 'done');
    this.props.nextStep();
  }

  render() {
    return (
      <div className="step-content">
        <div>Awesome! You can go explore the dashboard.</div>
        <div className="flex">
          <Button size="large" className="step-btn w-full mt-4" onClick={this.props.previousStep}>Back</Button>
          <Button size="large" type="primary" className="step-btn w-full mt-4 ml-4" onClick={this.props.handleOnBoardingClose}>Continue</Button>
        </div>
      </div>
    )
  }
}

export default Finish;