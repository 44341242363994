import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { confirmWithdrawalAddress } from 'actions/withdrawals';
import { Button } from 'antd';

import Loader from "components/shared/Loader";

class ConfirmWithdrawalAddressPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tokenExpired: true
    }
  }

  componentDidMount() {
    const { token, address } = this.props.match.params;
    const _this = this;

    this.props.confirmWithdrawalAddress({ token, address }, function(response) {
      if (response) {
        _this.setState({
          loading: false,
          tokenExpired: false
        })
      } else {
        _this.setState({
          loading: false,
          tokenExpired: true
        })
      }
    })
  }

  renderPageContent() {
    const { tokenExpired } = this.state;

    return (
      <div className="mt-32 flex justify-center items-center fade-in">
        <h3 className="text-2xl text-white font-semibold text-center">
          <div className="flex flex-col">
            <span className="">
              { tokenExpired && "Token Expired"}
              { !tokenExpired && "Successfully Added to Whitelist"}
            </span>
            <div className="text-xl mt-4 font-light">
              { !tokenExpired && "You have successfully added the withdrawal address to your Whitelist."}
              { tokenExpired && "Sorry."}
            </div>
            <Button className="mt-6" type="secondary" size="large">
              <a href="/">
                Back to app
              </a>
            </Button>
          </div>
        </h3>
      </div>
    )
  }

  generateLoader() {
    return (
      <Loader fontSize="30" position="absolute" bottom="0"/>
    )
  }

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        {loading && this.generateLoader()}
        {!loading && this.renderPageContent()}
      </React.Fragment>
    )
  }
}

export default compose(
  connect(null, { confirmWithdrawalAddress })
)(ConfirmWithdrawalAddressPage);