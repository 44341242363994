import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import requireAuth from 'components/requireAuth';
import { connect } from 'react-redux';
import { clearNotificationMessage } from 'actions/shared';
import LoginHistory from 'components/LoginHistory';
import { Col, Row, Select } from 'antd';
import ProfileWidget from 'components/shared/ProfileWidget';
import ProfileForm from 'components/shared/ProfileForm';
import { changePassword } from 'actions';

import CoinIcon from 'components/shared/CoinIcon';
import CYLIcon from 'images/coins/cyl.png';
import ETHIcon from 'images/coins/eth.png';
import USDTIcon from 'images/coins/usdt.png';

import { selectFeesCoin, setUserSelectedFeeCurrency } from 'actions/shared';
import { fetchWithdrawalInfo } from 'actions/withdrawals';

import Loader from "components/shared/Loader";

const { Option } = Select;

class ProfilePage extends React.Component {
  componentDidMount() {
    this.props.clearNotificationMessage();
  }

  componentWillReceiveProps(nextProps) {
    let { selectedFeeCurrency, user } = nextProps.auth;

    if (selectedFeeCurrency == '') {
      selectedFeeCurrency = user.selectedFeeCurrency
    }

    let selectedCoinObject = this.getSelectedCoinObject(selectedFeeCurrency);
    console.log('next props: ');
    console.log('selected coin obj: ', selectedCoinObject);
    this.props.selectFeesCoin(selectedCoinObject);
  }

  onSubmit = (formProps) => {
    this.props.changePassword(formProps, () => {
      console.log('changed password');
    });
  };

  getSelectedCoinObject(value) {
    value = value.toLowerCase();
    let selectedCoinObject = {};

    switch (value) {
      case 'eth':
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum";
        selectedCoinObject.icon = ETHIcon;
        break;
      case 'usdt':
        selectedCoinObject.name = "USDT";
        selectedCoinObject.fullName = "Tether"
        selectedCoinObject.icon = USDTIcon;
        break;
      case 'cyl':
        selectedCoinObject.name = "CYL";
        selectedCoinObject.fullName = "Crystal Token";
        selectedCoinObject.icon = CYLIcon;
        break;
      default:
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum";
        selectedCoinObject.icon = ETHIcon;
        break;
    }

    return selectedCoinObject;
  }

  handleChange = async (value) => {
    let selectedCoinObject = this.getSelectedCoinObject(value);
    const _this = this;
    await this.props.setUserSelectedFeeCurrency({ currency: selectedCoinObject.name }, () => {});
    setTimeout(function(){
      console.log('handle change select');
      _this.props.selectFeesCoin(selectedCoinObject);
    }, 200);
  }

  getSelectedCoinIcon = (coin) => {
    if (coin) {
      return <CoinIcon icon={coin.icon} coin={coin.name}/>
    } else {
      return ''
    }
  }

  render() {
    const { handleSubmit } = this.props;

    if (!this.props.auth.user.email) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <div className="vx-w-full vx-relative">
            <div className="main-content fade-in">
              <Row gutter={50} className="flex flex-col lg:flex-row">
                <Col span={24} className="lg:w-1/3">
                  <h3 className="mb-5 title-underline font-semibold">Change password</h3>
                  <ProfileForm
                    onSubmit={handleSubmit(this.onSubmit)}
                    iconLoading={this.props.auth.authRequestLoading}/>
                </Col>

                <Col span={24} className="mt-16 lg:mt-0 lg:w-1/3">
                  <h3 className="mb-5 title-underline font-semibold">Trading Fee Preferences</h3>
                  <label className="mb-2">Select a coin</label>
                  {this.props.selectedFeesCoin &&
                    <Select
                      size={"large"}
                      className="w-full h-10 mb-2"
                      placeholder="Select a coin"
                      defaultValue={this.getSelectedCoinIcon(this.props.selectedFeesCoin)}
                      onChange={this.handleChange}>

                      <Option value="cyl">
                        <CoinIcon icon={CYLIcon} coin="CYL"/>
                      </Option>

                      <Option value="eth">
                        <CoinIcon icon={ETHIcon} coin="ETH"/>
                      </Option>

                      <Option value="usdt">
                        <CoinIcon icon={USDTIcon} coin="USDT (ERC-20)"/>
                      </Option>
                    </Select>
                  }
                  {/* <div className="mb-5 text-xs">Use CYL to pay for fees (50% discount).</div> */}

                  {this.props.withdrawalInfoRequestLoading ? this.generateLoader() : ""}
                </Col>

                <Col span={24} className="lg:mt-0 lg:w-1/3">
                  <ProfileWidget
                    user={this.props.auth.user}
                    />
                </Col>
              </Row>

              <h3 className="mt-16 title-underline font-semibold">Login history</h3>
              <Row>
                <LoginHistory />
              </Row>
              </div>
          </div>
        </React.Fragment>
      )
    }
  };
}

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = "Current password required.";
  }

  if (!values.new_password) {
    errors.new_password = "New password required.";
  }

  if (!values.confirm_new_password) {
    errors.confirm_new_password = "Confirm new password required.";
  }

  if (values.confirm_new_password !== values.new_password) {
    errors.confirm_new_password = "Confirm password does not match.";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    selectedFeesCoin: state.shared.selectedFeesCoin,
    withdrawalInfoRequestLoading: state.withdrawals.withdrawalInfoRequestLoading,
    withdrawalInfo: state.withdrawals.withdrawalInfo,
  }
}

export default compose(
  requireAuth,
  connect(mapStateToProps, { clearNotificationMessage, changePassword, selectFeesCoin, setUserSelectedFeeCurrency, fetchWithdrawalInfo }),
  reduxForm({
    form: 'profile' ,
    validate
  })
)(ProfilePage);