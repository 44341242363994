import React from 'react';
import { Layout, Menu, Icon, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter} from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import crystalTokenLogo from 'images/icons/logo_crystal.svg';
import crystalTokenLogoLight from 'images/icons/logo_crystal_light.svg';
import ReactResizeDetector from 'react-resize-detector';
import {
  DASHBOARD_URL,
  EXCHANGES_PAGE_URL,
  WITHDRAWAL_PAGE_URL,
  DEPOSIT_PAGE_URL,
  PROFILE_PAGE_URL,
  INVITE_FRIENDS_PAGE_URL,
  REPORTS_PAGE_URL,
  MARKETPLACE_URL
} from 'containers/Router/constants';
import Scrollbar from 'react-smooth-scrollbar';

const sideNavigationUtils = require('./utils');

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

class SideNavigation extends React.Component {
  state = {
    theme: 'dark',
    current: '1',
    collapsed: false,
    showMobileMenu: false,
    isMobile: false
  };

  showMobileMenu = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    })
  }

  componentDidMount() {
    if (window.innerWidth <= 1200) {
      this.setState({
        isMobile: true
      })
    }
  }

  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onResize = () => {
    if (window.innerWidth <= 1200) {
      this.setState({
        isMobile: true
      })
    } else {
      this.setState({
        isMobile: false
      })
    }
  }

  renderAdminPages() {
    if (this.props.auth.user) {
      if (this.props.auth.user.isAdmin) {
        return (
          <SubMenu
            key="admin"
            title={
              <span className="flex items-center">
                <div className="nav-item-wrapper admin">
                  <Icon type="lock" />
                  <span>Admin</span>
                </div>
              </span>
            }
          >
            <Menu.Item key="20" onClick={(e) => { this.handleMenuItemClick(e) }}>
              Users
            </Menu.Item>

            <Menu.Item key="21" onClick={(e) => { this.handleMenuItemClick(e) }}>
              Withdrawals
            </Menu.Item>

            <Menu.Item key="22" onClick={(e) => { this.handleMenuItemClick(e) }}>
              Add Deposit
            </Menu.Item>

            <Menu.Item key="23" onClick={(e) => { this.handleMenuItemClick(e) }}>
              Traders
            </Menu.Item>

            <Menu.Item key="24" onClick={(e) => { this.handleMenuItemClick(e) }}>
              Reports
            </Menu.Item>

            {/* <Menu.Item key="25" onClick={(e) => { this.handleMenuItemClick(e) }}>
              Main Reports
            </Menu.Item> */}
          </SubMenu>
        )
      }
    }
  }

  handleMenuItemClick(e) {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    })
    sideNavigationUtils.handleMenuItem(e, this.props.history)
  }

  render() {
    const styleHamburgerCollapse = {
      color: "#fff",
      marginLeft: "auto",
      padding: "7px",
      zIndex: "999",
      fontSize: "20px",
      right: "40px",
      display: "none"
    }

    const styleHamburger = {
      fontSize: "20px",
      position: "absolute",
      right: "40px",
      display: "none"
    }

    const { showMobileMenu } = this.state;
    return (
      <Layout>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
        {/* {this.props.children} */}
        { this.props.auth.authenticated ? <>
          <Sider theme={this.state.theme} trigger={null} collapsible collapsed={this.state.collapsed}
            className={`app-navigation ${this.state.isMobile ? 'mobile' : 'desktop'}`} style={{display: showMobileMenu ? "block" : "none"}}>
            {
              !this.state.collapsed
                ?
                <a className="logo-d" href="https://crystalbot.io/">
                  <div className="flex items-center">
                    <img width="50" className="py-3 my-auto mx-auto navigation-logo" src={crystalTokenLogoLight}></img>
                    <Icon
                      style={{color: "#fff", position: "absolute", right: "22px"}}
                      className="menu mobile-hamburger"
                      type={showMobileMenu ? 'close' : ''}
                      onClick={this.showMobileMenu}
                    />
                  </div>
                </a>
                :
                <a className="logo-m" href="https://crystalbot.io/">
                  <div className="flex items-center justify-center">
                    <img alt="logo" className="w-12 py-3" src={crystalTokenLogoLight}></img>
                  </div>
                </a>
            }
          <Scrollbar>
          <Menu className="main-menu" theme={this.state.theme} mode="inline" defaultOpenKeys={sideNavigationUtils.isAdminPageSelected()} defaultSelectedKeys={sideNavigationUtils.getSelectedPage()}>
            <Menu.Item key="1" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={DASHBOARD_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="dashboard" />
                  <span>Dashboard</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="2" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={EXCHANGES_PAGE_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="deployment-unit" />
                  <span>Exchanges</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="3" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={REPORTS_PAGE_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="table" />
                  <span>Reports</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="4" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={DEPOSIT_PAGE_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="download" />
                  <span>Deposit</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="5" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={WITHDRAWAL_PAGE_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="upload" />
                  <span>Withdrawal</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="6" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={PROFILE_PAGE_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="user" />
                  <span>Profile</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="7" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={INVITE_FRIENDS_PAGE_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="share-alt" />
                  <span>Referral</span>
                </div>
              </Link>
            </Menu.Item>
            {this.props.auth.user && this.props.auth.user.isAdmin ?
            <Menu.Item key="8" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <Link to={MARKETPLACE_URL}>
                <div className="nav-item-wrapper">
                  <Icon type="home" />
                  <span>Marketplace</span>
                </div>
              </Link>
            </Menu.Item>
            : ''}
            <Menu.Item key="9" onClick={(e) => { this.handleMenuItemClick(e) }}>
              <a>
                <div className="nav-item-wrapper">
                  <Icon type="logout" />
                  <span>Sign Out</span>
                </div>
              </a>
            </Menu.Item>
            {this.renderAdminPages()}
          </Menu>
          </Scrollbar>
        </Sider>
        <Layout>
          <div style={{ padding: 0, paddingRight: '16px' }} className="flex items-center mobile-header">
            <Icon
              className="trigger icon-collapse"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
            <img width="50" className="py-3 my-auto navigation-logo navigation-logo-header" src={crystalTokenLogoLight}></img>
            <div className="page-title-m">
              <Breadcrumbs/>
            </div>
            <Icon
              style={showMobileMenu ? styleHamburger : styleHamburgerCollapse}
              className="menu mobile-hamburger"
              type={showMobileMenu ? 'close' : 'menu'}
              onClick={this.showMobileMenu}
            />
          </div>

          <div className="page-title">
            <Breadcrumbs/>
          </div>

          <Content
            style={{
              margin: '0px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
        </> : this.props.children}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(SideNavigation);