import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import * as actions from 'actions/deposits';
import { Row, Table } from 'antd';
import DepositForm from 'components/shared/DepositForm';
import DepositHistory from 'components/shared/Statistics/DepositHistory';
import moment from 'moment';
import Loader from "components/shared/Loader";
import Scrollbar from 'react-smooth-scrollbar';

class DepositPage extends React.Component {
  componentDidMount() {
    this.props.fetchDepositsHistory();
  }

  
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderStatusButton(status) {
    let capitalizedStatus;

    if (status) {
      capitalizedStatus = this.capitalizeFirstLetter(status);
    }

    switch(status) {
      case "Pending":
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
      case "Complete":
      case "Deposit confirmed":
      case "Converted from BTC":
        return <div className="btn--status completed">{capitalizedStatus}</div>
      case "Cancelled":
        return <div className="btn--status cancelled">{capitalizedStatus}</div>
      default:
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
    }
  }

  renderAmountElement = (size, currency) => {
    return (
      <div className="flex">
        {size}
        <div className="ml-2 font-semibold">{currency}</div>
      </div>
    )
  }

  renderTransactionElement = (transactionId, currency) => {
    if (currency == 'ETH' || currency == 'USDT' || currency == 'CYL') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    } else if (currency == 'BTC') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://blockchain.info/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    }
  }

  renderTradesTable() {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, record) => value ? this.renderAmountElement(value, record.currency) : '-'
      },
      {
        title: 'Blockchain Record',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: (value, record) => value ? this.renderTransactionElement(value, record.transactionType || record.currency) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => this.renderStatusButton(status)
      },
    ];
    
    return (
      <Row>
        <h3 className="title-underline font-semibold">Deposits</h3>
        <Scrollbar>
          <Table 
            rowClassName="table-row"
            dataSource={this.props.deposits} 
            columns={columns}
            pagination={{
              defaultPageSize: 4,
              showSizeChanger: true,
              pageSizeOptions: ['4', '10', '20'],
            }}
          />
        </Scrollbar>
      </Row>
    )
  }

  renderTradesPageContent() {
    return (
      <React.Fragment>
        {this.renderTradesTable()}
      </React.Fragment>
    )
  }

  render() {
    if (this.props.depositsRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
               <React.Fragment>
                 <DepositHistory deposits={this.props.deposits}/>
          <Row className="fade-in">
            <DepositForm className="mb-16"/>
            {this.renderTradesPageContent()}
          </Row>
        </React.Fragment>
        
      )
    }
  }
}

const mapStateToProps = (state) => {
  return { 
    deposits: Object.values(state.deposits.depositsList),
    notification: state.shared.notification,
    depositsRequestLoading: state.deposits.depositsRequestLoading
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, actions)
)(DepositPage);
