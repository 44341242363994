import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkValidToken } from 'actions';
import { setNotificationMessage } from 'actions/shared';
import appUtils from 'utils';

export default ChildComponent => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.props.checkValidToken();
    }

    componentDidUpdate() {
      const { history, auth } = this.props;
      const { user } = auth;

      if (typeof user !== 'undefined') {
        appUtils.redirectIfNotConfirmed(user.emailConfirmed, history, window.location.search);
      }
      
      appUtils.redirectIfTokenNotValid(auth.validToken, history, window.location.search);
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return { auth: state.auth };
  }
  return connect(mapStateToProps, { checkValidToken, setNotificationMessage })(ComposedComponent);
};