import {
  GET_REFERRAL_REWARDS,
  GET_REFERRAL_STATS,
  REFERRALS_REQ_IS_LOADING
} from 'actions/types';

const INITIAL_STATE = {
  referralRewards: [],
  referralStats: [],
  referralsRequestLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case GET_REFERRAL_STATS:
      return { ...state, referralStats: action.payload };
    case GET_REFERRAL_REWARDS:
      return { ...state, referralRewards: action.payload };
    case REFERRALS_REQ_IS_LOADING:
      return { ...state, referralsRequestLoading: action.payload }
    default:
      return state;
  }
}