import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import { fetchUsersWithdrawalsHistory, cancelWithdrawal, confirmWithdrawal, getGasPrice, filterUserWithdrawalsByAddress} from 'actions/withdrawals';
import { setNotificationMessage, clearNotificationMessage } from 'actions/shared';
import { Row, Table, Button, Modal, Input, Col, Tooltip, Icon } from 'antd';
import Web3 from 'web3';
import moment from 'moment';
import { abi } from './abi';
import toBaseUnit from './util';
import Scrollbar from 'react-smooth-scrollbar';
import Loader from "components/shared/Loader";

const { confirm } = Modal;

class WithdrawalsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gasPriceValue: '',
      buttonDisabled: true,
      searchByAddress: ''
    }
  }

  handleGasPriceChange(value) {
    let _this = this;
    this.setState({
      gasPriceValue: value
    }, function() {
      if (_this.state.gasPriceValue == '') {
        _this.setState({
          buttonDisabled: true
        })
      } else {
        _this.setState({
          buttonDisabled: false
        })
      }
    })
  }

  handleSearch(value)  {
    let _this = this;

    _this.props.filterUserWithdrawalsByAddress(value)

    this.setState({
      searchByAddress: value
    })
  }

  componentDidMount() {
    let _this = this;

    this.props.fetchUsersWithdrawalsHistory();
    this.props.getGasPrice(function(response) {
      if (response.data) {
        _this.setState({
          gasPriceValue: response.data.fast / 10,
          buttonDisabled: false
        })
      } else {
        _this.setState({
          buttonDisabled: true
        })
      }
    })
  }

  showConfirm(withdrawal, type) {
    let _this = this;
    if (type === 'cancel') {
      confirm({
        title: 'Are you sure?',
        content: `The withdrawal will be cancelled.`,
        onOk() {
          return new Promise((resolve, reject) => {
            _this.props.cancelWithdrawal(withdrawal._id, (hasError) => {
              _this.props.fetchUsersWithdrawalsHistory();
              resolve()
            });
          }).catch((err) => (err));
        },
        onCancel() {},
      });
    } else if (type === 'complete') {
      confirm({
        title: 'Are you sure?',
        content: `The withdrawal will be marked immediately as completed.`,
        onOk() {
          return new Promise((resolve, reject) => {
            _this.props.confirmWithdrawal({ id: withdrawal._id }, (hasError) => {
              _this.props.fetchUsersWithdrawalsHistory();
              resolve()
            });
          }).catch((err) => (err));
        },
        onCancel() {},
      });
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderStatusButton(status) {
    let capitalizedStatus;

    if (status) {
      capitalizedStatus = this.capitalizeFirstLetter(status);
    }

    switch(status) {
      case "Pending":
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
      case "Complete":
        return <div className="btn--status completed">{capitalizedStatus}</div>
      case "Cancelled":
        return <div className="btn--status cancelled">{capitalizedStatus}</div>
      default:
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
    }
  }

  renderTransactionElement = (transactionId, currency) => {
    if (currency == 'ETH' || currency == 'USDT' || currency == 'CYL') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    } else if (currency == 'BTC') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://blockchain.info/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    }
  }

  renderBalanceElement = (balances, currency, withdrawalAmount) => {
    let balance = balances[currency.toLowerCase()] + withdrawalAmount;
    return `${balance.toFixed(2)} ${currency}`;
  }

  renderTradesTable() {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, record) => value ? `${value} ${record.currency}` : '-'
      },
      {
        title: 'Email',
        dataIndex: 'user',
        key: 'user',
        render: value => value ? value[0].email : '-'
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        render: (value, record) => record ? this.renderBalanceElement(record.user[0].balances, record.currency, record.amount) : '-'
      },
      {
        title: 'Wallet Address',
        dataIndex: 'address',
        key: 'address',
        render: value => value ? <input type="text" value={value}/> : '-'
      },
      {
        title: 'Blockchain Record',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: (value, record) => value ? this.renderTransactionElement(value, record.currency) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => this.renderStatusButton(status)
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <div className="flex">
            <span className="mr-3">
              {record.status === 'Pending' ?
              <Tooltip title="Cancel withdrawal">
                <a className="text-crystal-primary" onClick={() => {this.showConfirm(record, 'cancel')}}>
                  <Icon type="stop" style={{color: "#eb3349", fontSize: "16px"}}/>
                </a>
              </Tooltip>
              : ''}
            </span>
            <span>
              {record.status === 'Pending' ?
              <Tooltip title="Complete withdrawal">
              <a className="text-crystal-primary" onClick={() => {this.showConfirm(record, 'complete')}}>
                <Icon type="check-circle" style={{color: "#2a9f4c", fontSize: "16px"}}/>
              </a>
              </Tooltip>
              : ''}
            </span>
          </div>
        ),
      }
    ];

    return (
      <Row>
        <h3 className="title-underline font-semibold">Withdrawals</h3>
        <Scrollbar>
          <Table
            rowClassName="table-row"
            dataSource={this.props.adminWithdrawals}
            columns={columns}
          />
        </Scrollbar>
      </Row>
    )
  }

  renderTradesPageContent() {
    return (
      <React.Fragment>
        {this.renderTradesTable()}
      </React.Fragment>
    )
  }

  async processTransactions() {
    if (window.ethereum) {
      console.log('Modern dapp browser');
      window.web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        window.web3.eth.defaultAccount = window.ethereum.selectedAddress;
      } catch (error) {
        // User denied account access
        this.props.setNotificationMessage({
          title: "Error",
          message: "Could not connect to MetaMask",
          icon: "error"
        })
        return;
      }
    } else if (window.web3) {
      console.log('Legacy dapp browser');
      const defaultAccount = window.web3.eth.defaultAccount;
      window.web3 = new Web3(window.web3.currentProvider);
      window.web3.eth.defaultAccount = defaultAccount;
    } else {
      console.log('Non-dapp browser');
      this.props.setNotificationMessage({
        title: "Error",
        message: "Browser not supported or MetaMask not installed",
        icon: "error"
      })
      return;
    }

    let web3 = window.web3;

    this.props.adminWithdrawals.map((withdrawal) => {
      let withdrawalToken = (withdrawal.currency).toLowerCase();

      switch (withdrawalToken) {
        case "eth":
          this.processEthTransaction(withdrawal, web3);
          break;
        case "usdt":
        case "cyl":
          this.processTokenTransaction(withdrawal, withdrawalToken, web3);
          break;
        default:
          break;
      }
    })
  }

  processEthTransaction(withdrawal, web3) {
    const transaction = {
      from: window.web3.eth.defaultAccount,
      to: withdrawal.address,
      value: web3.utils.toWei(String(withdrawal.amount), 'ether'),
      gas: '21000',
      gasPrice: String(this.state.gasPriceValue * 1000000000)
    }

    let _this = this;

    web3.eth.sendTransaction(transaction)
      .on('transactionHash', function(transactionHash) {
        _this.props.confirmWithdrawal({ id: withdrawal._id, transactionId: transactionHash }, function(response) {
          _this.props.clearNotificationMessage();
          _this.props.setNotificationMessage({
            title: "Success",
            message: "Transaction sent",
            icon: "success"
          })
          _this.props.fetchUsersWithdrawalsHistory();
        })
      })
      .on('error', function(error) {
        _this.props.clearNotificationMessage();
        _this.props.setNotificationMessage({
          title: "Error",
          message: "Transaction failed",
          icon: "error"
        })
      });
  }

  processTokenTransaction(withdrawal, token, web3) {
    let contractAddress;
    let decimals;
    let amount = String(withdrawal.amount);
    let _this = this;

    switch (token.toLowerCase()) {
      case 'cyl':
        contractAddress = '0x26CB3641aaA43911f1D4cB2ce544eb652AAc7c47';
        decimals = 18;
        break;
      case 'usdt':
        contractAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
        decimals = 6;
        break;
      default:
        return;
    }
    console.log(web3.eth.defaultAccount);
    var contract = new web3.eth.Contract(abi, contractAddress, {
      from: web3.eth.defaultAccount,
      gas: '60000',
      gasPrice: String(this.state.gasPriceValue * 1000000000)
    });

    let value = toBaseUnit(amount, decimals, web3.utils.BN);

    contract.methods.transfer(withdrawal.address, web3.utils.toHex(value)).send()
      .on('transactionHash', function(transactionHash) {
        _this.props.confirmWithdrawal({ id: withdrawal._id, transactionId: transactionHash }, function(response) {
          _this.props.clearNotificationMessage();
          _this.props.setNotificationMessage({
            title: "Success",
            message: "Transaction sent",
            icon: "success"
          })
          _this.props.fetchUsersWithdrawalsHistory();
        })
      })
      .on('error', function(error) {
        _this.props.clearNotificationMessage();
        _this.props.setNotificationMessage({
          title: "Error",
          message: "Transaction failed",
          icon: "error"
        })
      });
  }

  render() {
    if (this.props.withdrawalsRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <Row className="mb-6">
            <Col span={24} className="flex flex-col md:flex-row items-end justify-between md:max-w-sm">
              <div className="flex flex-col w-full">
                <div className="mb-2 font-semibold" style={{ color: "rgba(0,0,0,0.85)" }}>Gas Price</div>
                <Input type="number" placeholder="Set a value" value={this.state.gasPriceValue} onChange={(e) => this.handleGasPriceChange(e.target.value)}></Input>
              </div>
              <Button className="w-full mt-4 md:ml-4" disabled={this.state.buttonDisabled} type="primary" onClick={() => this.processTransactions()}>Process Transactions</Button>
            </Col>
          </Row>
          <Row className="mb-6">
            <Col xs={{ span: 24 }} lg={{ span: 6 }} className="flex items-end justify-between md:max-w-xs">
              <div className="flex flex-col w-full">
                <div className="mb-2 font-semibold" style={{ color: "rgba(0,0,0,0.85)" }}>Search</div>
                <Input type="email" value={this.state.searchByAddress} onChange={(e) => this.handleSearch(e.target.value)} placeholder="Wallet address"></Input>
              </div>
            </Col>
          </Row>
          <Row className="fade-in">
            {this.renderTradesPageContent()}
          </Row>
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminWithdrawals: Object.values(state.withdrawals.adminWithdrawalsList),
    notification: state.shared.notification,
    withdrawalsRequestLoading: state.withdrawals.withdrawalsRequestLoading
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, { setNotificationMessage, clearNotificationMessage, fetchUsersWithdrawalsHistory, filterUserWithdrawalsByAddress, confirmWithdrawal, cancelWithdrawal, getGasPrice })
)(WithdrawalsPage);
