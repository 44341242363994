import React from 'react';
import { Form } from 'antd';

import Button from 'components/shared/ResetPasswordForm/components/Button';
import Field from 'components/shared/ResetPasswordForm/components/Field';
import Label from 'components/shared/ResetPasswordForm/components/Label';

class ResetPasswordForm extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.onSubmit}>
        <div className="flex flex-col">
          <Label name="Password"/>
          <Field name="password" type="password"/>
        </div>

        <Button 
          name="Reset Password"
          icon="login"
          iconLoading={this.props.iconLoading}
        />
      </Form>
    )
  }
}

export default ResetPasswordForm;