import usersAxios from 'apis/users';

import { 
  SET_NOTIFICATION_MESSAGE,
  FEES_SELECTED_COIN,
  SET_USER_SELECTED_FEE_CURRENCY,
  USERS_REQ_IS_LOADING
} from 'actions/types';

export const setNotificationMessage = (notification) => {
  const notificationPayload = {
    message: notification.message,
    type: notification.title,
    icon: notification.icon
  }

  return {
    type: SET_NOTIFICATION_MESSAGE,
    payload: notificationPayload
  };
}

export const selectFeesCoin = (coinObject) => {
  return {
    type: FEES_SELECTED_COIN,
    payload: coinObject
  }
}

export const setUserSelectedFeeCurrency = (payload, callback) => async (dispatch, getState) => {
  try {
    usersAxios.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });
    const response = await usersAxios.post('/selected-fee-currency', payload);

    dispatch({ type: SET_USER_SELECTED_FEE_CURRENCY, payload: response.data.result })

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });

    callback();
  } catch (err) {
    const notificationPayload = {
      message: "Could not process withdrawal",
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    
    callback();
  }
}

export const clearNotificationMessage = () => {
  const notificationPayload = {
    message: '',
    type: '',
    icon: ''
  }

  return {
    type: SET_NOTIFICATION_MESSAGE,
    payload: notificationPayload
  };
}