// TODO: Breadcrumbs
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Signup from 'containers/SignupPage';
import Signin from 'containers/SigninPage';
import Signout from 'containers/SignoutPage';
import ForgotPassword from 'containers/ForgotPasswordPage';
import ResetPassword from 'containers/ResetPasswordPage';
import Dashboard from 'containers/DashboardPage';
import Reports from 'containers/ReportsPage';
import Header from 'components/shared/Header';
import Exchanges from 'containers/ExchangesPage';
import Trades from 'containers/TradesPage';
import Withdrawal from 'containers/WithdrawalPage';
import Deposit from 'containers/DepositPage';
import Profile from 'containers/ProfilePage';
import InviteFriends from 'containers/InviteFriendsPage';
import AdminUsers from 'containers/AdminDashboardPage/UsersPage';
import AdminWithdrawals from 'containers/AdminDashboardPage/WithdrawalsPage';
import AdminAddDeposit from 'containers/AdminDashboardPage/AddDepositPage';
import AdminReports from 'containers/AdminDashboardPage/AdminReports';
import AdminMainReports from 'containers/AdminDashboardPage/AdminMainReports';
import AdminTraders from 'containers/AdminDashboardPage/AdminTradersPage';
import SideNavigation from 'components/shared/SideNavigation';
import ConfirmWithdrawalAddress from 'containers/ConfirmWithdrawalAddress';
import EmailVerification from 'containers/EmailVerificationPage';
import ConfirmEmail from 'containers/ConfirmEmail';
import Redirect from 'components/shared/Redirect';
import Marketplace from 'containers/MarketplacePage';

import { 
  ROOT_URL,
  DASHBOARD_URL,
  EXCHANGES_PAGE_URL,
  TRADES_PAGE_URL,
  WITHDRAWAL_PAGE_URL,
  DEPOSIT_PAGE_URL,
  PROFILE_PAGE_URL,
  SIGNIN_URL,
  SIGNUP_URL,
  SIGNOUT_URL,
  FORGOT_URL,
  RESET_URL,
  ADMIN_USERS_PAGE_URL,
  ADMIN_REPORTS_PAGE_URL,
  ADMIN_MAIN_REPORTS_PAGE_URL,
  ADMIN_WITHDRAWALS_PAGE_URL,
  ADMIN_ADD_DEPOSIT_PAGE_URL,
  CONFIRM_WITHDRAWAL_ADDRESS_PAGE_URL,
  REPORTS_PAGE_URL,
  EMAIL_VERIFICATION_PAGE_URL,
  CONFIRM_EMAIL_PAGE_URL,
  INVITE_FRIENDS_PAGE_URL,
  MARKETPLACE_URL,
  MARKETPLACE_URL_SINGLE,
  ADMIN_TRADERS_PAGE_URL,
  ADMIN_TRADERS_PAGE_URL_EDIT
} from 'containers/Router/constants';

const renderSignedOutHeader = () => {
  if (window.location.pathname === FORGOT_URL || 
      window.location.pathname.includes('reset') ||
      window.location.pathname === SIGNIN_URL || 
      window.location.pathname === SIGNUP_URL || 
      window.location.pathname === EMAIL_VERIFICATION_PAGE_URL || 
      window.location.pathname.includes('confirm') ||
      window.location.pathname.includes('verify')) {
    return (
      <Header />
    );
  }
}

const dashboardRoutes = () => (
  <React.Fragment>
    <SideNavigation>
      <Switch>
        <Route exact path={DASHBOARD_URL} component={Dashboard}></Route>
        <Route path={EXCHANGES_PAGE_URL} component={Exchanges}></Route>
        <Route path={TRADES_PAGE_URL} component={Trades}></Route>
        <Route path={WITHDRAWAL_PAGE_URL} component={Withdrawal}></Route>
        <Route path={DEPOSIT_PAGE_URL} component={Deposit}></Route>
        <Route path={PROFILE_PAGE_URL} component={Profile}></Route>
        <Route path={INVITE_FRIENDS_PAGE_URL} component={InviteFriends}></Route>
        <Route path={MARKETPLACE_URL_SINGLE} component={Marketplace}></Route>
        <Route path={MARKETPLACE_URL} component={Marketplace}></Route>
        <Route path={REPORTS_PAGE_URL} component={Reports}></Route>
        <Route path={ADMIN_USERS_PAGE_URL} component={AdminUsers}></Route>
        <Route path={ADMIN_WITHDRAWALS_PAGE_URL} component={AdminWithdrawals}></Route>
        <Route path={ADMIN_ADD_DEPOSIT_PAGE_URL} component={AdminAddDeposit}></Route>
        <Route path={ADMIN_REPORTS_PAGE_URL} component={AdminReports}></Route>
        <Route path={ADMIN_MAIN_REPORTS_PAGE_URL} component={AdminMainReports}></Route>
        <Route path={ADMIN_TRADERS_PAGE_URL_EDIT} component={AdminTraders}></Route>
        <Route path={ADMIN_TRADERS_PAGE_URL} component={AdminTraders}></Route>
        <Route render={() => <Redirect to={DASHBOARD_URL} />} />
      </Switch>
    </SideNavigation>
  </React.Fragment>
);

const Router = () => (
  <React.Fragment>
    { renderSignedOutHeader() }
    <Route exact path={ROOT_URL} render={() => <Redirect to="/dashboard" preserveQueryString />}></Route>
    <Route exact path={SIGNUP_URL} component={Signup}></Route>
    <Route exact path={SIGNIN_URL} component={Signin}></Route>
    <Route exact path={SIGNOUT_URL} component={Signout}></Route>
    <Route exact path={FORGOT_URL} component={ForgotPassword}></Route>
    <Route exact path={RESET_URL} component={ResetPassword}></Route>

    <Route exact path={CONFIRM_WITHDRAWAL_ADDRESS_PAGE_URL} component={ConfirmWithdrawalAddress}></Route>
    <Route exact path={CONFIRM_EMAIL_PAGE_URL} component={ConfirmEmail}></Route>
    <Route exact path={EMAIL_VERIFICATION_PAGE_URL} component={EmailVerification}></Route>
        
    <Route path={DASHBOARD_URL} component={dashboardRoutes}></Route>
  </React.Fragment>
);

export default withRouter(Router);
