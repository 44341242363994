import React from 'react';
import { Icon, Button, Modal, Form, Input, Alert } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getTwoFactorSecret, removeTwoFactorSecret } from 'actions/profile';
import { verifyTwoFactorCode } from 'actions';
const { confirm } = Modal;

class TwoFactorSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      twoFactorCode: '',
      twoFactorSecretKey: '',
      twoFactorQrCode: '',
      googleAuthenticatorModalVisible: false
    }
  }

  showGoogleAuthenticatorModal = () => {
    this.setState({
      googleAuthenticatorModalVisible: true,
    });
  };

  handleGoogleAuthenticatorModalOk = () => {
    const { twoFactorCode, twoFactorSecretKey } = this.state;
    this.props.verifyTwoFactorCode({code: twoFactorCode, secretKey: twoFactorSecretKey}, (response, hasError) => {
      if (!hasError) {
        this.setState({
          googleAuthenticatorModalVisible: false
        })
        setTimeout(function() {
          window.location.reload();
        }, 250)
      }
    });
  };

  handleGoogleAuthenticatorModalCancel = () => {
    this.setState({ googleAuthenticatorModalVisible: false });
  };

  handleContractModalCancel = () => {
    this.setState({ contractModalVisible: false });
  };

  handleTwoFactorCodeChange = (event) => {
    this.setState({
      twoFactorCode: event.target.value
    })
  }

  showConfirmEnable() {
    let _this = this;
    confirm({
      okText: 'Enable now',
      cancelText: 'Later',
      title: 'Enable two-factor authentication (2FA)',
      content: `Please enable 2FA. It helps protect your account from unauthorized access.`,
      onOk() {
        return new Promise((resolve, reject) => {
          _this.props.getTwoFactorSecret((response) => {
            if (!response) {
              resolve()
            } else {
              resolve()
              _this.setState({
                twoFactorSecretKey: response.result.secret,
                twoFactorQrCode: response.result.qr
              })
              _this.showGoogleAuthenticatorModal();
            }
          });
        }).catch((err) => (err));
      },
      onCancel() {},
    });
  }

  showConfirmDisable() {
    let _this = this;
    confirm({
      okText: 'Disable now',
      cancelText: 'Later',
      title: 'Disable two-factor authentication (2FA)',
      content: `Please keep 2FA enabled. It helps protect your account from unauthorized access.`,
      onOk() {
        return new Promise((resolve, reject) => {
          _this.props.removeTwoFactorSecret(() => {
            resolve()
            setTimeout(function() {
              window.location.reload();
            }, 100)
          });
        }).catch((err) => (err));
      },
      onCancel() {},
    });
  }

  render() {
    const { googleAuthenticatorModalVisible } = this.state;
    const { twoFactorRequestLoading } = this.props;
    const { user } = this.props.auth;
    return (
      <>
        <div className="icon-circle-wrapper flex mb-6">
          <span className={`icon-circle ${!user.twoFactorSecret ? 'red' : ''}`}>
            <Icon type="lock" style={{fontSize: "16px"}}/>
          </span>
          <div className="ml-4">
            <div className="uppercase text-xs font-semibold">Two-factor Authentication</div>
            { user.twoFactorSecret && <a onClick={() => {this.showConfirmDisable()}}>Enabled</a>}
            { !user.twoFactorSecret && <span>Disabled</span>}
          </div>
        </div>

        { !user.twoFactorSecret && 
        <Button style={{order: "2"}} type="primary" className="mt-4 w-full" onClick={() => {this.showConfirmEnable()}}>
          Enable 2FA
        </Button> }

        <Modal
            visible={googleAuthenticatorModalVisible}
            closable={false}
            maskClosable={false}
            title="Connect Google authenticator"
            onOk={this.handleGoogleAuthenticatorModalOk}
            onCancel={this.handleGoogleAuthenticatorModalCancel}
          >
            <div className="text-center">
              <img className="my-6 mx-auto" src={this.state.twoFactorQrCode} width="150"/>
              <p>Don't forget to backup your secret key:</p>
              <p><b>{this.state.twoFactorSecretKey}</b></p>
            </div>

            <Form className="mt-5">
              <Input onChange={this.handleTwoFactorCodeChange} placeholder="2FA code"/>
            </Form>
          </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    signatureEmbeddedLink: state.profile.signatureEmbeddedLink,
    signaturesRequestLoading: state.profile.signaturesRequestLoading
  }
}

export default compose(
  connect(mapStateToProps, { getTwoFactorSecret, removeTwoFactorSecret, verifyTwoFactorCode })
)(TwoFactorSection);