const {
  SIGNIN_URL,
  EMAIL_VERIFICATION_PAGE_URL
} = require('containers/Router/constants');

exports.redirectIfTokenNotValid = (validToken, history, searchParams) => {
  if (!validToken) {
    history.push({
      pathname: SIGNIN_URL,
      search: searchParams
    });
  }
}

exports.redirectIfNotConfirmed = (emailConfirmed, history, searchParams) => {
  if (!emailConfirmed) {
    history.push({
      pathname: EMAIL_VERIFICATION_PAGE_URL,
      search: searchParams
    });
  }
}

exports.pointFormatter = (number, decimals) => {
  var isNegative = number < 0 ? '-' : '';
  return isNegative + '$' + Math.abs(number).toFixed(decimals ? decimals : 2);
}
