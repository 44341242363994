import React from 'react';

import moment from 'moment';
import CsvDownload from "components/shared/CsvDownload";

import { Row, Table, Tooltip, Icon, Tabs } from 'antd';
import _ from 'lodash';


class WithdrawalHistory extends React.Component {


  
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  
  renderDate(value) {
    let currentYear = false;

    if (moment(value).format('Y') == new Date().getFullYear()) {
      currentYear = true;
    }

    return (
      <div>
        <Tooltip title={moment(value).format('ll HH:hh')}>
          {moment(value).format('MMM D')}
          <Icon style={{position: "relative", top: "-3px", fontSize: "15px", marginLeft: "6px", color: "#3867d6"}} type="info-circle" />
        </Tooltip>
      </div>
    )
  }



  

  

  returnWithdrawalList(deposit, status) {
    return _.filter(deposit, function(o) { return o.status == status; });
  }

  render() {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, record) => value ? this.renderAmountElement(value, record.currency) : '-'
      },
      {
        title: 'Blockchain Record',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: (value, record) => value ? this.renderTransactionElement(value, record.transactionType || record.currency) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => this.renderStatusButton(status)
      },
    ];

    const operations = <CsvDownload data={this.props.withdrawals}>Download withdrawal history</CsvDownload>;
        
    return (
      <Row className="fade-in">
       <div>{operations}</div>  
       <div>{this.props.trades}</div>
      </Row>
    )
  }
}

export default WithdrawalHistory ;