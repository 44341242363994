import React from 'react';
import { Icon } from 'antd';
import { pointFormatter } from "utils";

class Earnings extends React.Component {
  renderEarnings = ( name, value ) => {
    return (
      <div className="mb-2 flex">
        {name}
        <span className="mx-1">|</span>
        <span className={`${value >= 0 ? "positive" : "negative"} flex items-center`}>
          <Icon type={`${value >= 0 ? "caret-up" : "caret-down"}`} style={{marginRight: "0.5px"}} /> {pointFormatter(value)}
        </span>
      </div>
    )
  }

  render() {
    const { binanceBalances } = this.props;

    return (
      <div>
        <h2 className="h4 mt-6 mb-2" style={{fontWeight: 600,}}>Earnings (to date)</h2>
        <div className="earnings vx-text-grey uppercase text-xs">
          {this.renderEarnings('Week', binanceBalances.weekProfitUsd)}
          {this.renderEarnings('Month', binanceBalances.monthProfitUsd)}
          {this.renderEarnings('Year', binanceBalances.yearProfitUsd)}
        </div>
      </div>
    )
  }
}

export default Earnings;