import React from 'react';
import { connect } from 'react-redux';
import { signout } from 'actions';
import { clearNotificationMessage } from 'actions/shared';
import requireAuth from 'components/requireAuth';

class SignoutPage extends React.Component {
  componentDidMount() {
    this.props.clearNotificationMessage();
    this.props.signout();
    localStorage.removeItem('step-1');
    localStorage.removeItem('step-2');
    localStorage.removeItem('step-3');
    localStorage.removeItem('step-4');
    localStorage.removeItem('hideOnboarding');
  };

  render() {
    return <div></div>
  }
}

export default requireAuth(connect(null, { signout, clearNotificationMessage })(SignoutPage));
