import { 
  SET_NOTIFICATION_MESSAGE,
  FEES_SELECTED_COIN
} from 'actions/types';

const INITIAL_STATE = {
  notification: {
    message: '',
    type: '',
    icon: '',
    duration: 1.5
  },
  selectedFeesCoin: ''
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case SET_NOTIFICATION_MESSAGE:
      return { ...state, notification: action.payload }
    case FEES_SELECTED_COIN:
      return { ...state, selectedFeesCoin: action.payload};
    default:
      return state;
  }
}