import _ from 'lodash';
import exist from 'exist.js';
import moment from 'moment';

import { 
  FETCH_ALL_USERS,
  USERS_REQ_IS_LOADING,
  FILTER_USERS_BY_EMAIL,
  ADMIN_ADD_DEPOSIT_SELECTED_COIN,
  ADMIN_REPORTS_REQ_IS_LOADING,
  ADMIN_FETCH_REPORTS,
  CREATE_DEPOSIT,
  FETCH_SEARCHED_USERS,
  ADMIN_FETCH_TRADES,
  ADMIN_TRADES_REQ_IS_LOADING,
  ADMIN_FETCH_HISTORY_EQUITY,
  ADMIN_HISTORY_EQUITY_REQ_IS_LOADING,
  ADMIN_RESET_2FA_REQ_IS_LOADING,
  ADMIN_USERS_SET_FETCH_USERS_DATE,
  ADMIN_MAIN_REPORTS_REQ_IS_LOADING,
  ADMIN_FETCH_MAIN_REPORTS,
} from 'actions/types';

const INITIAL_STATE = {
  usersList: [],
  usersListArr: [],
  usersListNotFiltered: [],
  usersRequestLoading: false,
  selectedAddDepositCoin: {
    name: undefined,
    fullName: undefined,
  },
  stats: {
    pieChartData: [],
    tradesStatsData: {
      winningTrades: 0,
      losingTrades: 0,
      averageWinner: 0,
      averageLoser: 0,
      winningStreak: 0,
      losingStreak: 0
    },
    accountBalances: {
      balances: [],
      totalPortfolioValue: 0
    }
  },
  adminReportsRequestLoading: false,
  searchedUsersList: [],
  adminTradesList: [],
  adminTradesRequestLoading: false,
  historyEquityRequestLoading: false,
  reset2FaRequestLoading: false,
  fetchUsersStartDate: moment().subtract(30, 'days').unix(),
  fetchUsersEndDate: moment().unix(),
  mainReportsList: [],
  mainReportsRequestLoading: false
};

function returnFilteredUser(userNotFiltered, payload) {
  payload = String(payload).toLowerCase();

  const exchangeExists = exist.get(userNotFiltered, 'exchanges[0]');
  let accountName = exist.get(userNotFiltered, 'exchanges[0].accountName');
  
  accountName = String(accountName).toLowerCase();

  if (exchangeExists) {
    return userNotFiltered.email.includes(payload) || accountName.includes(payload) 
  }
  
  return userNotFiltered.email.includes(payload)
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case ADMIN_FETCH_REPORTS:
      return { ...state, stats: action.payload };
    case FETCH_ALL_USERS:
      return { ...state, usersList: _.mapKeys(action.payload, '_id'), usersListNotFiltered: _.mapKeys(action.payload, '_id'), usersListArr: action.payload };
    case FETCH_SEARCHED_USERS:
        return { ...state, searchedUsersList: action.payload };
    case USERS_REQ_IS_LOADING:
      return { ...state, usersRequestLoading: action.payload }
    case FILTER_USERS_BY_EMAIL:
      return { ...state, usersList: _.filter(state.usersListNotFiltered, userNotFiltered => returnFilteredUser(userNotFiltered, action.payload)) }
    case ADMIN_ADD_DEPOSIT_SELECTED_COIN:
      return { ...state, selectedAddDepositCoin: action.payload};
    case ADMIN_REPORTS_REQ_IS_LOADING:
      return { ...state, adminReportsRequestLoading: action.payload }
    case ADMIN_FETCH_TRADES:
      return { ...state, adminTradesList: action.payload };
    case ADMIN_TRADES_REQ_IS_LOADING:
      return { ...state, adminTradesRequestLoading: action.payload }
    case ADMIN_FETCH_HISTORY_EQUITY:
      return { ...state, equityHistory: action.payload };
    case ADMIN_HISTORY_EQUITY_REQ_IS_LOADING:
      return { ...state, historyEquityRequestLoading: action.payload }
    case ADMIN_RESET_2FA_REQ_IS_LOADING:
      return { ...state, reset2FaRequestLoading: action.payload }
    case CREATE_DEPOSIT:
      return { ...state }
    case ADMIN_USERS_SET_FETCH_USERS_DATE:
      return { ...state, fetchUsersStartDate: action.payload.startDate, fetchUsersEndDate: action.payload.endDate}
    case ADMIN_FETCH_MAIN_REPORTS:
      return { ...state, mainReportsList: action.payload };
    case ADMIN_MAIN_REPORTS_REQ_IS_LOADING:
      return { ...state, mainReportsRequestLoading: action.payload }
    default:
      return state;
  }
}
