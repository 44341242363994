import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'actions/exchanges';
import { Card, Button, Row, Col, Modal, Switch } from 'antd';
import binanceLogo from 'images/icons/binance-logo.svg';
import ContractSection from 'components/shared/ProfileWidget/ContractSection';
import binanceLogoSimple from 'images/icons/binance.svg';
import bybitLogoSimple from 'images/icons/bybit.svg';

const { confirm } = Modal;

class ExchangesList extends React.Component {

  showConfirm(exchange) {
    let _this = this;
    confirm({
      title: 'Are you sure?',
      content: `The exchange will be permanently removed.`,
      onOk() {
        return new Promise((resolve, reject) => {
          _this.props.removeExchange(exchange.accountId, exchange.exchangeType, (hasError) => {
            console.log(hasError);
            if (!hasError) {
              resolve()
            } else {
              resolve()
            }
          });
        }).catch((err) => (err));
      },
      onCancel() {},
    });
  }

  renderDeleteButton(accountId) {
    return (
      <Button onClick={() => {this.showConfirm(accountId)}} type="danger">
        Delete
      </Button>
    )
  }

  renderCardTitle(title, type) {
    return (
      <div data-test='title' className="font-semibold text-sm uppercase tracking-wide">
        <div className="flex items-center">
          <img src={type == "binance" || type == "binance_us" ? binanceLogoSimple : bybitLogoSimple} className="mr-2" style={{width: "16px", height: "16px"}}/>
          {title || type}
        </div>
      </div>
    )
  }

  handleTradingStatusSwitch(status, accountId) {
    const { setTradingStatus, fetchExchanges } = this.props;
    setTradingStatus({ account_id: accountId, status: status }, function() {
      fetchExchanges();
    });
  }

  renderList() {
    const { user, exchanges } = this.props;
    return (
      exchanges.map(exchange => {
        return (
          <Col span={24} key={exchange._id} className="exchange-card lg:max-w-sm">
            <Card
              data-id={exchange.accountId}
              className="mb-4"
              title={this.renderCardTitle(exchange.exchangeName, exchange.exchangeType)}
              extra={this.renderDeleteButton(exchange)}
            >
              {exchange.exchangeType == 'binance' && <div className="font-semibold mb-6 text-black">Binance requires a minimum of $10 per trade.</div>}
              {exchange.exchangeType == 'binance_us' && <div className="font-semibold mb-6 text-black">Binance US requires a minimum of $10 per trade.</div>}
              {exchange.exchangeType == 'bybit' && <div className="font-semibold mb-6 text-black">&nbsp;</div>}
              <div className="flex items-center mb-5">
                {/* { !user.contractSigned && <div className="mr-2">
                  <ContractSection tooltipPosition="top" tooltipMessage="Not enough funds. Please deposit funds to enable trading." notProfile icon/>
                  </div>
                } */}
                <Switch
                  // disabled={!user.contractSigned}
                  onChange={(event) => this.handleTradingStatusSwitch(event, exchange.accountId)}
                  className=""
                  checkedChildren="Enabled"
                  unCheckedChildren="Disabled"
                  // checked={exchange.tradingEnabled && user.contractSigned}
                  checked={exchange.tradingEnabled}
                />
                <div className="ml-2 text-xs font-semibold uppercase">Trading</div>
              </div>
              <div className="flex align-items items-center">
                <span className="font-semibold mr-2">API Key:</span>
                <input className="flex-1 input-keys" type="text" defaultValue={exchange.publicKey}></input>
              </div>
            </Card>
          </Col>
        )
      })
    )
  }

  render() {
    return (
      <>
        <Row gutter={16} className="fade-in">
          {this.renderList()}
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    exchanges: Object.values(state.exchanges.exchangesList)
  }
};

export default connect(mapStateToProps, actions)(ExchangesList);
