import traders from 'apis/traders';

import {
  CREATE_TRADER,
  TRADERS_REQ_IS_LOADING,
  SET_NOTIFICATION_MESSAGE,
  FETCH_TRADERS,
  FETCH_TRADER,
  REMOVE_TRADER,
  UPDATE_TRADER
} from 'actions/types';

export const deleteTrader = (id) => async (dispatch, getState) => {
  try {
    traders.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: true });

    const response = await traders.delete(`/${id}`);

    dispatch({ type: REMOVE_TRADER, payload: id })

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    let message = "";

    if (err.response.data.error) {
      message = err.response.data.error.message;
    }

    const notificationPayload = {
      message: message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const fetchTrader = (id, callback) => async (dispatch, getState) => {
  try {
    traders.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: true });
    const response = await traders.get(`/${id}`);

    dispatch({ type: FETCH_TRADER, payload: response.data.result })
    callback(true);

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    let message = "";

    alert(err);

    if (err.response.data.error) {
      message = err.response.data.error.message;
    }

    const notificationPayload = {
      message: message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const fetchTraders = () => async (dispatch, getState) => {
  try {
    traders.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: true });
    const response = await traders.get('/');

    dispatch({ type: FETCH_TRADERS, payload: response.data.result })

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    let message = "";

    if (err.response.data.error) {
      message = err.response.data.error.message;
    }

    const notificationPayload = {
      message: message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const updateTrader = (id, formProps, callback) => async (dispatch, getState) => {
  try {
    traders.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: true });
    const response = await traders.put(`/${id}`, formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: UPDATE_TRADER, payload: response.data.message })
    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const createTrader = (formProps, callback) => async (dispatch, getState) => {
  try {
    traders.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: true });
    const response = await traders.post('/', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CREATE_TRADER, payload: response.data.message })
    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: TRADERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

// export const removeExchange = (accountId, exchangeType, callback) => async (dispatch, getState) => {
//   console.log('removing action');
//   try {
//     exchanges.defaults.headers = { Authorization: getState().auth.authenticated };

//     dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: true });
//     const response = await exchanges.delete('/', { data: { "account_id": accountId, "exchange_type": exchangeType } });

//     const notificationPayload = {
//       message: response.data.message,
//       type: 'Success',
//       icon: 'success'
//     }

//     dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
//     dispatch({ type: REMOVE_EXCHANGE, payload: accountId })
//     dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
//     callback();
//   } catch (err) {
//     const notificationPayload = {
//       message: err.response.data.error.message,
//       type: 'Error',
//       icon: 'error'
//     }
//     callback();
//     dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
//     dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
//   }
// };

export const loadingRequest = (isLoading) => {
  return {
    type: TRADERS_REQ_IS_LOADING,
    payload: isLoading
  }
}
