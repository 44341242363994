import _ from 'lodash';

import { 
  FETCH_TRADERS,
  CREATE_TRADER,
  REMOVE_TRADER,
  TRADERS_REQ_IS_LOADING,
  FETCH_TRADER,
  UPDATE_TRADER
} from 'actions/types';

const INITIAL_STATE = {
  tradersList: [],
  trader: '',
  tradesRequestLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case FETCH_TRADERS:
      return { ...state, tradersList: action.payload };
    case FETCH_TRADER:
      return { ...state, trader: action.payload };
    case CREATE_TRADER:
      return { ...state }
    case UPDATE_TRADER:
      return { ...state }
    case REMOVE_TRADER:
      return { ...state, tradersList: state.tradersList.filter((trader) => { return trader['_id'] !== action.payload } )};
    case TRADERS_REQ_IS_LOADING:
      return { ...state, tradesRequestLoading: action.payload }
    default:
      return state;
  }
}