import React from 'react';

class CoinIcon extends React.Component {
  render() {
    return (
      <div className="flex items-center">
        <img alt="" className="mr-2 w-5" src={this.props.icon}/>
        <span className="font-semibold">{this.props.coin}</span>
      </div>
    )
  }
}

export default CoinIcon;