import { 
  FETCH_REPORTS,
  FETCH_HISTORY,
  FETCH_BALANCES,
  FETCH_BINANCE_BALANCE,
  REPORTS_REQ_IS_LOADING,
  HISTORY_EQUITY_REQ_IS_LOADING,
  FETCH_HISTORY_EQUITY
} from 'actions/types';

const INITIAL_STATE = {
  stats: {
    pieChartData: [],
    tradesStatsData: {
      winningTrades: 0,
      losingTrades: 0,
      averageWinner: 0,
      averageLoser: 0,
      winningStreak: 0,
      losingStreak: 0
    },
    accountBalances: {
      balances: [],
      totalPortfolioValue: 0
    }
  },
  reportsRequestLoading: false,
  historyEquityRequestLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case FETCH_REPORTS:
      return { ...state, stats: action.payload };
    case FETCH_BINANCE_BALANCE:
      return { ...state, binanceBalances: action.payload};
    case FETCH_HISTORY:
      return { ...state, history: action.payload };
    case FETCH_HISTORY_EQUITY:
      return { ...state, equityHistory: action.payload };
    case FETCH_BALANCES:
      return { ...state, accountBalances: action.payload };
    case REPORTS_REQ_IS_LOADING:
      return { ...state, reportsRequestLoading: action.payload }
    case HISTORY_EQUITY_REQ_IS_LOADING:
      return { ...state, historyEquityRequestLoading: action.payload }
    default:
      return state;
  }
}