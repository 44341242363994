import auth from 'apis/auth';

import { 
  AUTH_USER,
  AUTH_VALID_TOKEN,
  SET_NOTIFICATION_MESSAGE,
  AUTH_IS_LOADING,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  TWO_FACTOR_REQ_LOADING,
  FETCH_USER
} from 'actions/types';

export const signup = (formProps, callback) => async dispatch => {
  try {
    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post('/signup', formProps );

    dispatch({ type: AUTH_USER, payload: response.data })
    localStorage.setItem('token', response.data.token);

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const signout = () => {
  localStorage.removeItem('token');

  return {
    type: AUTH_USER,
    payload: ''
  };
};

export const signin = (formProps, callback) => async dispatch => {
  try {
    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post('/signin', formProps );

    dispatch({ type: AUTH_USER, payload: response.data })

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    callback(response.data);
  } catch (err) {
    const notificationPayload = {
      message: 'Invalid sign in credentials',
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const changePassword = (formProps, callback) => async dispatch => {
  try {
    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post('/change-password', formProps );

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    
    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
}

export const loadingRequest = (isLoading) => {
  return {
    type: AUTH_IS_LOADING,
    payload: isLoading
  }
}

export const checkValidToken = () => async (dispatch, getState) => {
  try {
    auth.defaults.headers = { Authorization: getState().auth.authenticated };
    let response;
    try {
      response = await auth.post('/authenticated');
    } catch(err) {
      dispatch({ type: AUTH_VALID_TOKEN, payload: false })
    }
    dispatch({ type: AUTH_VALID_TOKEN, payload: response.data })
  } catch (err) {
  }
}

export const forgotPassword = (formProps) => async (dispatch, getState) => {
  try {
    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post('/forgot', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: FORGOT_PASSWORD, payload: response.data.message })
    dispatch({ type: AUTH_IS_LOADING, payload: false });
  } catch (err) {
    console.log(err);

    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const resetPassword = (formProps, token) => async (dispatch, getState) => {
  try {
    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post(`/reset/${token}`, formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: RESET_PASSWORD, payload: response.data.message })
    dispatch({ type: AUTH_IS_LOADING, payload: false });
  } catch (err) {
    console.log(err);

    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const verifyTwoFactorCode = (formProps, callback) => async (dispatch, getState) => {
  try {
    auth.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: TWO_FACTOR_REQ_LOADING, payload: true });
    const response = await auth.post('/verify-two-factor-code', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: TWO_FACTOR_REQ_LOADING, payload: false });
    callback(response);
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: TWO_FACTOR_REQ_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    callback(err, true);
  }
};

export const verifyEmail = (formProps, callback) => async (dispatch, getState) => {
  try {
    auth.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post('/verify-email', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: AUTH_IS_LOADING, payload: false });
    callback(response);
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    callback(err, true);
  }
};

export const sendEmailVerificationCode = (formProps, callback) => async (dispatch, getState) => {
  try {
    auth.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post('/send-email-verification-code', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: AUTH_IS_LOADING, payload: false });
    callback(response);
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    callback(err, true);
  }
};

export const fetchUser = () => async (dispatch, getState) => {
  try {
    auth.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.get('/current');

    dispatch({ type: FETCH_USER, payload: response.data.result })

    dispatch({ type: AUTH_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: AUTH_IS_LOADING, payload: false });
  }
};

export const finishOnboarding = (callback) => async (dispatch, getState) => {
  try {
    auth.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: AUTH_IS_LOADING, payload: true });
    const response = await auth.post('/finish-onboarding');

    dispatch({ type: AUTH_IS_LOADING, payload: false });
    callback(response);
  } catch (err) {
    console.log(err);
    dispatch({ type: AUTH_IS_LOADING, payload: false });
    callback(err, true);
  }
};