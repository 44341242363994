import React from 'react';

import {
  Button as ButtonAntd,
} from 'antd';

const Button = (props) => {
  return (
    <ButtonAntd
      size="large"
      loading={props.iconLoading}
      icon={props.icon}
      onClick={props.enterIconLoading}
      className={`mt-2 w-full items-center h-12 justify-center ${props.className}`}
      href={props.href}
      target={props.target}
      htmlType={props.href ? "button" : "submit"}
      style={{display: "flex"}}
      type={props.type || 'primary'}>
      {props.name}
    </ButtonAntd>
  )
}

export default Button;