import React from 'react';
import requireAuth from 'components/requireAuth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { clearNotificationMessage } from 'actions/shared';
import { fetchReports, fetchHistory, fetchEquityGraphHistory } from 'actions/reports';
import { fetchTrades } from 'actions/trades';
import StrikeRate from 'components/shared/Statistics/strikeRate';
import TradeHistory from 'components/shared/Statistics/tradeHistory';
import EquityGraph from 'components/shared/Statistics/equityGraph';
import RealizedReports from 'components/shared/Statistics/realizedReports';
import ReportPie from 'components/shared/Statistics/reportPie';
import Loader from "components/shared/Loader";
import EmptyState from "components/shared/EmptyState";

import {
  EXCHANGES_PAGE_URL
} from 'containers/Router/constants';

class ReportsPage extends React.Component {
  componentDidMount() {
    this.props.clearNotificationMessage();
    this.props.fetchReports();
    this.props.fetchTrades();
    this.props.fetchHistory(20);
    this.props.fetchEquityGraphHistory(9999);
  }

  renderTradesContent() {
    if (this.props.tradesRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <TradeHistory trades={this.props.trades}/>
        </React.Fragment>
      )
    }
  }

  renderReportsPageContent() {
    const { reportsStats, equityHistory } = this.props;
    if (this.props.reportsRequestLoading) {
      return (
        <Loader/>
      )
    } else if (reportsStats) {
      return (
        <>
          <EquityGraph history={equityHistory} fetchEquityHistory={this.props.fetchEquityGraphHistory}/>
          <div className="page-wrapper trades-page-wrapper reports-widget-wrapper mt-20">
            <div className="reports-widget portfolio-widget">
              <div className="report-widget-body">
                <ReportPie reports={reportsStats}/>
              </div>
            </div>

            <div className="reports-widget strike-widget">
              <StrikeRate reports={reportsStats}/>
            </div>

            <div className="reports-widget history-widget">
              <div className="">
                {this.renderTradesContent()}
              </div>
            </div>
          </div>
        </>
      )
    } else {
      let messageHtml = <div>Connect <b><a href={EXCHANGES_PAGE_URL}>exchange</a></b> to see reports</div>;
      return (
        <EmptyState message={messageHtml}/>
      )
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.renderReportsPageContent()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    trades: Object.values(state.trades.tradesList),
    reportsStats: state.reports.stats,
    reportsHistory: state.reports.history,
    reportsRequestLoading: state.reports.reportsRequestLoading,
    tradesRequestLoading: state.trades.tradesRequestLoading,
    equityHistory: state.reports.equityHistory,
    historyEquityRequestLoading: state.reports.historyEquityRequestLoading
  }
}

export default compose(
  requireAuth,
  connect(mapStateToProps, { clearNotificationMessage, fetchReports, fetchHistory, fetchTrades, fetchEquityGraphHistory })
)(ReportsPage);