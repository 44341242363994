import React from 'react';
import { Icon } from 'antd';
import ContractSection from './ContractSection';
import TwoFactorSection from './TwoFactorSection';

class ProfileWidget extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;
    return (
      <div className="widget widget--profile">
        <div className="mb-6">
          <div className="icon-circle-wrapper flex">
            <span className="icon-circle">
              <Icon type="mail" style={{fontSize: "16px"}}/>
            </span>
            <div className="ml-4">
              <div className="uppercase text-xs font-semibold">Email</div>
              <span>{user.email}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <TwoFactorSection/>
          {/* <ContractSection/> */}
        </div>
      </div>
    )
  }
}

export default ProfileWidget;