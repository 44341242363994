import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import moment from 'moment';
import CsvDownload from "components/shared/CsvDownload";
import { pointFormatter } from "utils";
import { Row, Table, Tooltip, Icon, Tabs } from 'antd';
import _ from 'lodash';
import ExchangeIcon from 'components/shared/ExchangeIcon';
import binanceLogoSimple from 'images/icons/binance.svg';
import bybitLogoSimple from 'images/icons/bybit.svg';

const { TabPane } = Tabs;

class TradeHistory extends React.Component {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderDate(value) {
    let currentYear = false;

    if (moment(value).format('Y') == new Date().getFullYear()) {
      currentYear = true;
    }

    return (
      <div>
        <Tooltip title={moment(value).format('ll HH:mm')}>
          {moment(value).format('MMM D')}
          <Icon style={{position: "relative", top: "-3px", fontSize: "15px", marginLeft: "6px", color: "#3867d6"}} type="info-circle" />
        </Tooltip>
      </div>
    )
  }

  renderSizeElement = (size, currency, side) => {
    return (
      <div className={`${side ? (side.toLowerCase() === 'sell' ? "flex negative" : "flex positive") : "flex" }`}>
        {`${side ? (side.toLowerCase() === 'sell' ? "-" : "") : "" }${Number(size).toFixed(6)}`}
        <div className="ml-2 font-semibold">{currency}</div>
      </div>
    )
  }

  renderPLValue = ( value, type, decimals ) => {
    switch (type) {
      case 'dollar':
        return (
          <div className={`${value > 0 ? "positive" : "negative"}`}>{pointFormatter(Number(value), decimals)}</div>
        )
      case 'percentage':
        return (
          <div className={`${value > 0 ? "positive" : "negative"}`}>{Number(value).toFixed(decimals ? decimals : 2)}%</div>
        )
      default:
    }
  }

  renderType = (value) => {
    const valueToShow = value.toLowerCase() === 'sell' ? "SHORT" : "LONG";

    return (
      <div className={`${value.toLowerCase() === 'sell' ? "negative" : "positive"} font-semibold`}>{valueToShow}</div>
    );
  }

  renderStatusButton(status, trade) {
    let capitalizedStatus;

    if (status) {
      capitalizedStatus = this.capitalizeFirstLetter(status);
    }

    switch(status) {
      case "in progress":
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
      case "completed":
        return <div className="btn--status completed">{capitalizedStatus}</div>
      case "failed":
        return <Tooltip title={trade.errorMessage}><div className="btn--status failed">{capitalizedStatus}</div></Tooltip>
      case "cancelled":
        return <div className="btn--status cancelled">{capitalizedStatus}</div>
      default:
        return <div className="btn--status in-progress">In progress</div>
    }
  }

  returnTradesList(trades, status) {
    return _.filter(trades, function(o) { return o.status == status; });
  }

  renderExchangeElement(value, record) {
    return (
      <>
        <ExchangeIcon label={value} icon={value === 'Binance' || value === 'Binance US' ? binanceLogoSimple : bybitLogoSimple} coin={value}/>
      </>
    )
  }

  render() {
    const columns = [
      {
        title: 'Exchange',
        dataIndex: 'accountMarket',
        key: 'accountMarket',
        sorter: (a, b) => a.accountMarket.localeCompare(b.accountMarket),
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => value ? this.renderExchangeElement(value, record) : '-',
      },
      {
        title: 'Pair',
        dataIndex: 'pair',
        key: 'pair',
        sorter: (a, b) => a.pair.localeCompare(b.pair),
        sortDirections: ['descend', 'ascend'],
        render: value => value ? value : '-'
      },
      {
        title: 'Type',
        dataIndex: 'side',
        key: 'side',
        sorter: (a, b) => a.side.localeCompare(b.side),
        sortDirections: ['descend', 'ascend'],
        render: value => value ? this.renderType(value) : '-'
      },
      {
        title: 'Opened',
        dataIndex: 'entryDatetime',
        key: 'entryDatetime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? this.renderDate(value) : '-'
      },
      {
        title: 'Closed',
        dataIndex: 'exitDatetime',
        key: 'exitDatetime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? this.renderDate(value) : '-'
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        sorter: (a, b) => a.size - b.size,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => value ? this.renderSizeElement(value, record.currency, record.side) : '-'
      },
      {
        title: 'Avg. Entry',
        dataIndex: 'averageEntryPrice',
        key: 'averageEntryPrice',
        sorter: (a, b) => a.averageEntryPrice - b.averageEntryPrice,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? Number(value).toFixed(6) : '-'
      },
      {
        title: 'Avg. Exit',
        dataIndex: 'averageExitPrice',
        key: 'averageExitPrice',
        sorter: (a, b) => a.averageExitPrice - b.averageExitPrice,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? Number(value).toFixed(6) : '-'
      },
      {
        title: 'P/L %',
        dataIndex: 'profitPercentage',
        key: 'profitPercentage',
        sorter: (a, b) => a.profitPercentage - b.profitPercentage,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? this.renderPLValue(value, 'percentage') : '-'
      },
      {
        title: 'Fee $',
        dataIndex: 'fee.amountUsd',
        key: 'fee.amountUsd',
        sorter: (a, b) => a.fee ? a.fee.amountUsd : 0 - b.fee ? b.fee.amountUsd : 0,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => value ? this.renderPLValue(value, 'dollar', 4) : '-'
      },
      {
        title: 'Net P/L $',
        dataIndex: 'netProfitUsd',
        key: 'netProfitUsd',
        sorter: (a, b) => a.netProfitUsd - b.netProfitUsd,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? this.renderPLValue(value, 'dollar', 4) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value, record) => this.renderStatusButton(value, record)
      },
    ];

    const operations = <CsvDownload data={this.props.trades}>Download trade history</CsvDownload>;

    return (
      <Row className="fade-in">
          <Tabs tabBarExtraContent={this.props.noDownload ? null : operations} animated={false}>
            <TabPane tab="Completed" key="1">
              <Scrollbar>
                <Table
                  rowClassName="table-row"
                  dataSource={this.returnTradesList(this.props.trades, 'completed')}
                  columns={columns}
                />
              </Scrollbar>
            </TabPane>
            <TabPane tab="In Progress" key="2">
              <Scrollbar>
                <Table
                  rowClassName="table-row"
                  dataSource={this.returnTradesList(this.props.trades, 'in progress')}
                  columns={columns}
                />
              </Scrollbar>
            </TabPane>
            <TabPane tab="Failed" key="3">
              <Scrollbar>
                <Table
                  rowClassName="table-row"
                  dataSource={this.returnTradesList(this.props.trades, 'failed')}
                  columns={columns}
                />
              </Scrollbar>
            </TabPane>
          </Tabs>
      </Row>
    )
  }
}

export default TradeHistory;