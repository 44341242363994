import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { sendEmailVerificationCode } from 'actions';
import { Button, Icon } from 'antd';
import { setNotificationMessage } from 'actions/shared';

import Loader from "components/shared/Loader";

class EmailVerificationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      emailConfirmed: true
    }
  }

  censorWord = function (str) {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }

  censorEmail = function (email){
    const arr = email.split("@");
    return this.censorWord(arr[0]) + "@" + this.censorWord(arr[1]);
  }

  sendEmailVerificationCode = function (userId) {
    const _this = this;

    this.props.sendEmailVerificationCode({ userId: userId }, function(response) {
      _this.props.setNotificationMessage({
        title: "Success",
        message: "Email sent", 
        icon: "success"
      })
    })
  }

  renderPageContent() {
    const { user } = this.props.auth;
    
    return (
      <div className="mt-20 md:mt-32 flex justify-center items-center fade-in">
        <h3 className="text-2xl text-white font-semibold text-center">
          <div className="flex flex-col">
            <span>
              <Icon type="info-circle" className="mb-4" style={{fontSize: '50px'}} />
            </span>
            <span className="">
              Email Verification
            </span>
            <div className="mx-10 text-sm md:text-xl mt-4 font-light max-w-lg">
              {user.email && <>We've sent an email to your registered email address: <b>{this.censorEmail(user.email)}</b>.
              Follow the instructions in the email to get started!</>}
              {!user.email && <>Unknown.</>}
            </div>
            {user.email && <Button className="mx-10 mt-6" type="secondary" size="large" onClick={() => this.sendEmailVerificationCode(user._id)}>
              Resend email
            </Button>}
          </div>
        </h3>
      </div>
    )
  }

  generateLoader() {
    return (
      <Loader fontSize="30" position="absolute" bottom="0"/>
    )
  }

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        {loading && this.generateLoader()}
        {!loading && this.renderPageContent()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default compose(
  connect(mapStateToProps, { sendEmailVerificationCode, setNotificationMessage })
)(EmailVerificationPage);