import React from 'react';
import { Form } from 'antd';

import Button from './Button';
import Field from './Field';
import Label from './Label';

class ReferralCodeForm extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.onSubmit}>
        <div className="flex flex-col">
          <Label name="Code"/>
          <Field name="code" type="text"/>
        </div>

        <Button 
          name="Create link"
          icon="login"
          iconLoading={this.props.iconLoading}
        />
      </Form>
    )
  }
}

export default ReferralCodeForm;