import { Link } from 'react-router-dom';
import React from 'react';
import crystalTokenLogo from 'images/icons/cyl_logo_dark_h.svg';

import {
  SIGNIN_URL,
  SIGNUP_URL
} from 'containers/Router/constants';

class Header extends React.Component {
  render() {
    return (
      <div className="header flex flex-col md:flex-row justify-between items-center pt-8 px-6 md:px-20">
        <div className="header-logo flex items-center">
          <img alt="logo" className="mr-3" width="250" src={crystalTokenLogo}></img>
        </div>
        <div className="header-navigation text-white font-semibold text-sm mt-4 md:mt-0">
          {!window.location.pathname.includes('confirm') &&
            <>
              <Link to={{ pathname: SIGNIN_URL, search: window.location.search }} className="mr-4 hover:text-white hover:opacity-75">
                Sign in
              </Link>
              <Link to={{ pathname: SIGNUP_URL, search: window.location.search }} className="hover:text-white hover:opacity-75">
                Create Account
              </Link>
            </>
          }
        </div>
      </div>
    )
  }
}

export default Header;