import React from 'react';
import { Form } from 'antd';

import Button from 'components/shared/ProfileForm/components/Button';
import Field from 'components/shared/ProfileForm/components/Field';
import Label from 'components/shared/ProfileForm/components/Label';

class ProfileForm extends React.Component {
  renderFormButton = () => {
    return (
      <React.Fragment>
        <Button 
          name="Change password"
          icon="login"
          iconLoading={this.props.iconLoading}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <Form onSubmit={this.props.onSubmit}>
        <div className="flex flex-col mr-10 w-full">
          <Label name="Password"/>
          <Field name="password" type="password"/>
        </div>

        <div className="flex flex-col w-full">
          <Label name="New password"/>
          <Field name="new_password" type="password"/>
        </div>

        <div className="flex flex-col">
          <Label name="Confirm new password"/>
          <Field name="confirm_new_password" type="password"/>
        </div>

        {this.renderFormButton()}
      </Form>
    )
  }
}

export default ProfileForm;