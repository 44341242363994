import exchanges from 'apis/exchanges';

import {
  FETCH_EXCHANGES,
  CREATE_EXCHANGE,
  REMOVE_EXCHANGE,
  SET_NOTIFICATION_MESSAGE,
  EXCHANGES_REQ_IS_LOADING,
  SET_TRADING_STATUS
} from 'actions/types';

export const fetchExchanges = () => async (dispatch, getState) => {
  try {
    exchanges.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: true });
    const response = await exchanges.get('/');

    dispatch({ type: FETCH_EXCHANGES, payload: response.data.result })

    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
  } catch (err) {
    let message = "";

    if (err.response.data.error) {
      message = err.response.data.error.message;
    }

    const notificationPayload = {
      message: message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const createExchange = (formProps, callback) => async (dispatch, getState) => {
  try {
    exchanges.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: true });
    const response = await exchanges.post('/', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CREATE_EXCHANGE, payload: response.data.message })
    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
    localStorage.setItem("step-1", "done");
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const removeExchange = (accountId, exchangeType, callback) => async (dispatch, getState) => {
  try {
    exchanges.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: true });
    const response = await exchanges.delete('/', { data: { "account_id": accountId, "exchange_type": exchangeType } });

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: REMOVE_EXCHANGE, payload: accountId })
    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }
    callback();
    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const setTradingStatus = (payload, callback) => async (dispatch, getState) => {
  try {
    exchanges.defaults.headers = { Authorization: getState().auth.authenticated };


    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: true });
    const response = await exchanges.put('/set-trading-status', payload);

    dispatch({ type: SET_TRADING_STATUS, payload: response })
    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    callback();
    dispatch({ type: EXCHANGES_REQ_IS_LOADING, payload: false });
  }
};

export const loadingRequest = (isLoading) => {
  return {
    type: EXCHANGES_REQ_IS_LOADING,
    payload: isLoading
  }
}
