import {
  DASHBOARD_URL,
  EXCHANGES_PAGE_URL,
  TRADES_PAGE_URL,
  WITHDRAWAL_PAGE_URL,
  DEPOSIT_PAGE_URL,
  PROFILE_PAGE_URL,
  SIGNOUT_URL,
  ADMIN_USERS_PAGE_URL,
  ADMIN_REPORTS_PAGE_URL,
  ADMIN_MAIN_REPORTS_PAGE_URL,
  ADMIN_WITHDRAWALS_PAGE_URL,
  ADMIN_ADD_DEPOSIT_PAGE_URL,
  ADMIN_TRADERS_PAGE_URL,
  REPORTS_PAGE_URL,
  INVITE_FRIENDS_PAGE_URL,
  MARKETPLACE_URL
} from 'containers/Router/constants';

export const getSelectedPage = () => {
  switch(window.location.pathname) {
    case DASHBOARD_URL:
      return ['1']
    case EXCHANGES_PAGE_URL:
      return ['2']
    case TRADES_PAGE_URL:
      return ['3']
    case REPORTS_PAGE_URL:
      return ['3']
    case DEPOSIT_PAGE_URL:
      return ['4']
    case WITHDRAWAL_PAGE_URL:
      return ['5']
    case PROFILE_PAGE_URL:
      return ['6']
    case INVITE_FRIENDS_PAGE_URL:
      return ['7']
    case MARKETPLACE_URL:
      return ['8']
    case ADMIN_USERS_PAGE_URL:
      return ['20']
    case ADMIN_WITHDRAWALS_PAGE_URL:
      return ['21']
    case ADMIN_ADD_DEPOSIT_PAGE_URL:
      return ['22']
    case ADMIN_TRADERS_PAGE_URL:
      return ['23']
    default: 
      return ['1']
  }
}

export const isAdminPageSelected = () => {
  switch(window.location.pathname) {
    case ADMIN_USERS_PAGE_URL:
      return ["admin"];
    case ADMIN_WITHDRAWALS_PAGE_URL:
      return ["admin"];
    case ADMIN_ADD_DEPOSIT_PAGE_URL:
      return ["admin"];
    default: 
      return ["admin"]
  }
}

export const handleMenuItem = (e, history) => {
  switch (e.key) {
    case "1":
      history.push(DASHBOARD_URL);
      break;
    case "2":
      history.push(EXCHANGES_PAGE_URL);
      break;
    case "3":
      history.push(REPORTS_PAGE_URL);
      break;
    case "4":
      history.push(DEPOSIT_PAGE_URL);
      break;
    case "5":
      history.push(WITHDRAWAL_PAGE_URL);
      break;
    case "6":
      history.push(PROFILE_PAGE_URL);
      break;
    case "7":
      history.push(INVITE_FRIENDS_PAGE_URL);
      break;
    case "8":
      history.push(MARKETPLACE_URL);
      break;
    case "9":
      history.push(SIGNOUT_URL);
      break;
    case "20":
      history.push(ADMIN_USERS_PAGE_URL);
      break;
    case "21":
      history.push(ADMIN_WITHDRAWALS_PAGE_URL);
      break;
    case "22":
      history.push(ADMIN_ADD_DEPOSIT_PAGE_URL);
      break;
    case "23":
      history.push(ADMIN_TRADERS_PAGE_URL);
      break;
    case "24":
      history.push(ADMIN_REPORTS_PAGE_URL);
      break;
    case "25":
      history.push(ADMIN_MAIN_REPORTS_PAGE_URL);
      break;
    default:
      console.log("Default side navigation item");
  }
}