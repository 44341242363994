import React from 'react';
import { Form } from 'antd';

import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as actions from 'actions/exchanges';

import Button from 'components/shared/ExchangeForm/components/Button';
import Field from 'components/shared/ExchangeForm/components/Field';
import Label from 'components/shared/ExchangeForm/components/Label';

class ExchangeForm extends React.Component {
  renderFormButton = (exchangeName, exchangeUrl) => {
    switch (this.props.type) {
      case "create":
        return (
          <div className="flex">
            <Button
              className="mr-10"
              name="Connect exchange"
              icon="login"
              iconLoading={this.props.iconLoading}
            />
            <Button
              name={`Register for a ${exchangeName} account`}
              type="secondary"
              icon="login"
              href={exchangeUrl}
              target="_blank"
            />
          </div>
        );
      default:
        console.log("Can not render form button");
    }
  }

  onSubmit = (formProps) => {
    formProps.exchange_type = this.props.exchange;
    this.props.createExchange(formProps, () => {
      this.props.fetchExchanges();
      this.props.exchangeAdded(true);
    });
  };

  render() {
    const { handleSubmit, exchange } = this.props;
    let exchangeUrl = '';
    let exchangeName = '';

    switch (exchange) {
      case "binance":
        exchangeUrl = "https://www.binance.com/en/register?ref=37250432";
        exchangeName = "Binance"
        break;
      case "binance_us":
        exchangeUrl = "https://www.binance.us/en/register?ref=37250432";
        exchangeName = "Binance US"
        break;
      case "bybit":
        exchangeUrl = "https://www.bybit.com/en-US/invite?ref=Y6N56";
        exchangeName = "Bybit"
        break;
    }

    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="flex justify-between">
          <div className="flex flex-col mr-6 w-full">
            <Label name="Exchange name"/>
            <Field name="exchange_name" type="text"/>
          </div>

          <div className="flex flex-col w-full">
            <Label name="API Key"/>
            <Field name="exchange_api_key" type="text"/>
          </div>
        </div>

        <div className="flex flex-col">
          <Label name="Secret Key"/>
          <Field name="exchange_secret_key" type="text"/>
        </div>

        <div className="flex flex-col">
          <span className="mb-6">{`In order to copy our ${exchangeName} trades, you must sign up for a new ${exchangeName} account using our affiliate link. We will be checking this regularly and will disconnect any accounts that are not under our affiliate link.`}</span>
        </div>

        {this.renderFormButton(exchangeName, exchangeUrl)}
      </Form>
    )
  }
}

const validate = values => {
  const errors = {};

  if (!values.exchange_name) {
    errors.exchange_name = "Exchange name required.";
  }

  if (!values.exchange_api_key) {
    errors.exchange_api_key = "Api key required.";
  }

  if (!values.exchange_secret_key) {
    errors.exchange_secret_key = "Secret key required";
  }

  return errors;
};

export default compose(
  connect(null, actions),
  reduxForm({
    form: 'exchanges' ,
    validate
  })
)(ExchangeForm);
