import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Row, Col } from 'antd';

import {
  FORGOT_URL
} from 'containers/Router/constants';

import AuthForm from 'components/shared/AuthForm';

import {
  DASHBOARD_URL
} from 'containers/Router/constants';

class SigninPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTwoFactorInput: false
    }
  }
  onSubmit = (formProps) => {
    this.props.signin(formProps, (response) => {
      const { code } = formProps;
      const { user, token } = response;

      if (!user.twoFactorSecret) {
        localStorage.setItem('token', token);
        this.props.history.push(DASHBOARD_URL);
      } else {
        if (code) {
          this.props.verifyTwoFactorCode({code: code, secretKey: user.twoFactorSecret}, (response, hasError) => {
            if (!hasError) {
              localStorage.setItem('token', token);
              this.props.history.push(DASHBOARD_URL);
            }
          });
        }

        this.setState({
          showTwoFactorInput: true
        })
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    
    return (
      <div className="pt-10">
        <h3 className="text-2xl text-white font-semibold text-center">
          <span className="font-light">Sign in to</span> Crystal Bot
        </h3>

        <Row className="pt-10 w-full md:flex md:justify-center">
          <Col span={21} className="rounded-bg p-6 pt-8 pb-8 w-full md:w-1/3">
            <AuthForm 
              type="signin"
              showTwoFactorInput={this.state.showTwoFactorInput}
              onSubmit={handleSubmit(this.onSubmit)}
              notification={this.props.notification}
              iconLoading={this.props.authRequestLoading}>
            </AuthForm>
            <div className="mt-8 flex justify-end">
              <Link to={FORGOT_URL}>Forgot your password?</Link>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required.";
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required.";
  }

  if (values.password) {
    if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters long."
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return { 
    notification: state.shared.notification,
    authRequestLoading: state.auth.authRequestLoading,
    authenticated: state.auth.authenticated
  }
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: 'signin' ,
    validate
  })
)(SigninPage);