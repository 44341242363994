export const ROOT_URL = '/';
export const SIGNUP_URL = '/signup';
export const SIGNIN_URL = '/signin';
export const SIGNOUT_URL = '/signout';
export const FORGOT_URL = '/forgot';
export const RESET_URL = '/reset/:token';
export const DASHBOARD_URL = '/dashboard';
export const EXCHANGES_PAGE_URL = '/dashboard/exchanges';
export const TRADES_PAGE_URL = '/dashboard/trades';
export const WITHDRAWAL_PAGE_URL = '/dashboard/withdrawal';
export const DEPOSIT_PAGE_URL = '/dashboard/deposit';
export const REPORTS_PAGE_URL = '/dashboard/reports';
export const PROFILE_PAGE_URL = '/dashboard/profile';
export const CONFIRM_WITHDRAWAL_ADDRESS_PAGE_URL = '/confirm/withdrawal-address/:address/:token';
export const EMAIL_VERIFICATION_PAGE_URL = '/email-verification';
export const CONFIRM_EMAIL_PAGE_URL = '/verify/:userId/:token'
export const ADMIN_USERS_PAGE_URL = '/dashboard/admin/users';
export const ADMIN_REPORTS_PAGE_URL = '/dashboard/admin/reports';
export const ADMIN_MAIN_REPORTS_PAGE_URL = '/dashboard/admin/main-reports';
export const ADMIN_WITHDRAWALS_PAGE_URL = '/dashboard/admin/withdrawals';
export const ADMIN_ADD_DEPOSIT_PAGE_URL = '/dashboard/admin/deposits/new';
export const INVITE_FRIENDS_PAGE_URL = '/dashboard/referral';
export const MARKETPLACE_URL = '/dashboard/marketplace';
export const MARKETPLACE_URL_SINGLE = '/dashboard/marketplace/:id';
export const ADMIN_TRADERS_PAGE_URL = '/dashboard/admin/traders';
export const ADMIN_TRADERS_PAGE_URL_EDIT = '/dashboard/admin/traders/:id';