import reports from 'apis/reports';

import { 
  FETCH_REPORTS,
  FETCH_HISTORY,
  FETCH_HISTORY_EQUITY,
  FETCH_BALANCES,
  REPORTS_REQ_IS_LOADING,
  HISTORY_EQUITY_REQ_IS_LOADING,
  SET_NOTIFICATION_MESSAGE,
  FETCH_BINANCE_BALANCE
} from 'actions/types';

export const fetchReports = () => async (dispatch, getState) => {
  reports.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: true });
  const response = await reports.get('/');

  dispatch({ type: FETCH_REPORTS, payload: response.data.result })

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: false });
};

export const fetchBinanceBalance = () => async (dispatch, getState) => {
  reports.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: true });
  const response = await reports.get('/binanceBalance');

  dispatch({ type: FETCH_BINANCE_BALANCE, payload: response.data.result })

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: false });
};

export const fetchHistory = (limit) => async (dispatch, getState) => {
  reports.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: true });
  const response = await reports.get(`/history/${limit}`);

  dispatch({ type: FETCH_HISTORY, payload: response.data.result })

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: false });
};

export const fetchBalances = () => async (dispatch, getState) => {
  reports.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: true });
  const response = await reports.get(`/balances`);

  dispatch({ type: FETCH_BALANCES, payload: response.data.result })

  dispatch({ type: REPORTS_REQ_IS_LOADING, payload: false });
};

export const fetchEquityGraphHistory = (limit) => async (dispatch, getState) => {
  reports.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: HISTORY_EQUITY_REQ_IS_LOADING, payload: true });
  const response = await reports.get(`/history/equity/${limit}`);

  dispatch({ type: FETCH_HISTORY_EQUITY, payload: response.data.result })

  dispatch({ type: HISTORY_EQUITY_REQ_IS_LOADING, payload: false });
};

export const loadingRequest = (isLoading) => {
  return {
    type: REPORTS_REQ_IS_LOADING,
    payload: isLoading
  }
}
