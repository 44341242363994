import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import * as actions from 'actions/withdrawals';
import { Row } from 'antd';
import AdminDepositForm from 'components/shared/AdminDepositForm';

class AddDepositPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row className="fade-in">
          <AdminDepositForm 
            className="mb-16"
          />
        </Row>
      </React.Fragment>
    )
  }
}

const validate = values => {
  const errors = {};

  if (!values.user_email) {
    errors.user_email = 'User email is required.';
  }

  if (!values.deposit_amount) {
    errors.deposit_amount = 'Deposit amount is required.';
  }

  return errors;
};

const mapStateToProps = (state) => {
  return { 
    notification: state.shared.notification,
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, actions),
  reduxForm({
    form: 'addDeposit' ,
    validate
  })
)(AddDepositPage);
