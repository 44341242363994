import React from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';

import Button from 'components/shared/WithdrawalForm/components/Button';
import Field from 'components/shared/ProfileForm/components/Field';
import Label from 'components/shared/ProfileForm/components/Label';
import AutoComplete from 'components/shared/WithdrawalForm/components/AutoComplete';

import { Row, Col, Spin, Icon, Select, Card } from 'antd';

import CoinIcon from 'components/shared/CoinIcon';
import CYLIcon from 'images/coins/cyl.png';
import ETHIcon from 'images/coins/eth.png';
import USDTIcon from 'images/coins/usdt.png';

import { selectWithdrawalCoin } from 'actions/withdrawals';
import { fetchWithdrawalInfo } from 'actions/withdrawals';

import Loader from "components/shared/Loader";

const { Option } = Select;

class WithdrawalForm extends React.Component {
  componentDidMount() {
    console.log('form: ', this.props);
  }

  handleChange = (value) => {
    let selectedCoinObject = {};

    switch (value) {
      case 'eth':
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum"
        break;
      case 'usdt':
        selectedCoinObject.name = "USDT";
        selectedCoinObject.fullName = "Tether"
        break;
      case 'cyl':
        selectedCoinObject.name = "CYL";
        selectedCoinObject.fullName = "Crystal Token"
        break;
      default:
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum"
        break;
    }

    this.props.selectWithdrawalCoin(selectedCoinObject);
    this.props.fetchWithdrawalInfo(value, () => {});
  }

  renderFormButton = () => {
    return (
      <React.Fragment>
        <Button
          name="Confirm"
          icon="login"
          iconLoading={this.props.iconLoading}
          disabled={this.props.form.syncErrors}
        />
      </React.Fragment>
    )
  }

  generateCardDetails() {
    return (
      <React.Fragment>
        <div className="mb-4">
          <div>Main account available:
            <span className="text-crystal-primary mr-1 ml-3 font-semibold">
              {this.props.withdrawalInfo.balance ? this.props.withdrawalInfo.balance : '0'}
            </span>
            {this.props.withdrawalInfo.balance ? this.props.selectedWithdrawalCoin.name : ''}
          </div>
        </div>

        <Card>
          <Form onSubmit={this.props.onSubmit}>
            <div className="flex flex-col mr-10 w-full">
              <Label name="Wallet address"/>
              {/* <Field name="wallet_address" type="text"/> */}
              <AutoComplete dataSource={this.props.withdrawalsAddress} name="wallet_address" type="text"/>
            </div>

            <div className="flex flex-col w-full">
              <Label name={`${this.props.selectedWithdrawalCoin.name} Amount`}/>
              <Field name="withdrawal_amount" type="number" step="any" min={this.props.withdrawalInfo.fee * 2}
                     placeholder={`Minimum withdraw amount: ${this.props.withdrawalInfo.fee * 2}`}/>
            </div>

            <div className="mb-4">
              <div>Fee:
                <span className="text-crystal-primary mr-1 ml-3 font-semibold">
                  {this.props.withdrawalInfo.fee ? this.props.withdrawalInfo.fee : '-'}
                </span>
                {this.props.withdrawalInfo.fee ? this.props.selectedWithdrawalCoin.name : ''}
              </div>
            </div>

            {this.renderFormButton()}
          </Form>
        </Card>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Row className={this.props.className}>
          <Col span={24} className="md:max-w-sm">
            <Select
              size={"large"}
              className="w-full h-10 mb-2"
              placeholder="Select a coin"
              defaultValue={this.props.selectedWithdrawalCoin.name}
              onChange={this.handleChange}>

              <Option value="cyl">
                <CoinIcon icon={CYLIcon} coin="CYL"/>
              </Option>

              <Option value="eth">
                <CoinIcon icon={ETHIcon} coin="ETH"/>
              </Option>

              <Option value="usdt">
                <CoinIcon icon={USDTIcon} coin="USDT (ERC-20)"/>
              </Option>
            </Select>

            {this.props.withdrawalInfoRequestLoading ? <Loader fontSize="24"/> : ""}
            {this.props.withdrawalInfo && !this.props.withdrawalInfoRequestLoading ? this.generateCardDetails() : ""}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedWithdrawalCoin: state.withdrawals.selectedWithdrawalCoin,
    withdrawalInfoRequestLoading: state.withdrawals.withdrawalInfoRequestLoading,
    withdrawalInfo: state.withdrawals.withdrawalInfo,
    withdrawalsAddress: Object.values(state.withdrawals.withdrawalsAddress),
    form: state.form.withdrawal
  }
};

export default connect(mapStateToProps, { selectWithdrawalCoin, fetchWithdrawalInfo } )(WithdrawalForm);