import React from 'react'
import csvDownload from 'json-to-csv-export'
import _ from 'lodash';
import moment from 'moment';
import { pointFormatter } from "utils";
import { Icon, Button } from 'antd';
import exist from 'exist.js';

const CsvDownloadUsers = props => {
  const { data, ...others } = props
  const filename = `cyl-platform-users-${moment().format('DD.m.YYYY.HH.hh')}.csv`;
  let usersTable = [];

  _.map(data, function(user) {
    let userObjectToExport = {};
    userObjectToExport["Created"] = user.createdAt;
    userObjectToExport["Email"] = user.email;
    userObjectToExport["Exchange"] = user.exchanges.length > 0 ? user.exchanges[0].accountName : '';
    userObjectToExport["CYL"] = exist.get(user, `balances[cyl]`);
    userObjectToExport["ETH"] = exist.get(user, `balances[eth]`);
    userObjectToExport["USDT"] = exist.get(user, `balances[usdt]`);
    userObjectToExport["Fees $"] = pointFormatter(user.totalFees);
    userObjectToExport["Net P/L $"] = pointFormatter(user.totalNetProfit);
    usersTable.push(userObjectToExport);
  })

  return (
    <div className="flex justify-end">
      <Button type="primary" className="mb-3 text-sm flex justify-end items-center cursor-pointer" onClick={() => csvDownload(usersTable, filename)} {...others}>
        <Icon type="download" style={{fontSize: "16px"}}/>
        {props.children || 'Download data'}
      </Button>
    </div>
  )
}
export default CsvDownloadUsers