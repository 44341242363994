import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import * as actions from 'actions/trades';
import { Row, Table } from 'antd';
import Loader from "components/shared/Loader";
import Scrollbar from 'react-smooth-scrollbar';
import moment from 'moment';
import { pointFormatter } from "utils";
import CsvDownload from "components/shared/CsvDownload";

class TradesPage extends React.Component {
  componentDidMount() {
    this.props.fetchTrades();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderStatusButton(status) {
    let capitalizedStatus;

    if (status) {
      capitalizedStatus = this.capitalizeFirstLetter(status);
    }

    switch(status) {
      case "in progress":
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
      case "completed":
        return <div className="btn--status completed">{capitalizedStatus}</div>
      case "failed":
        return <div className="btn--status failed">{capitalizedStatus}</div>
      case "cancelled":
        return <div className="btn--status cancelled">{capitalizedStatus}</div>
      default:
        return <div className="btn--status in-progress">In progress</div>
    }
  }

  renderSizeElement = (size, currency) => {
    return (
      <div className="flex">
        {Number(size).toFixed(8)}
        <div className="ml-2 font-semibold">{currency}</div>
      </div>
    )
  }

  renderPLValue = ( value, type ) => {
    switch (type) {
      case 'dollar':
        return (
          <div className={`${value > 0 ? "positive" : "negative"}`}>{pointFormatter(Number(value).toFixed(2))}</div>
        )
      case 'percentage':
        return (
          <div className={`${value > 0 ? "positive" : "negative"}`}>{Number(value).toFixed(2)}%</div>
        )
    }
  }

  renderTradesTable() {
    const columns = [
      // {
      //   title: 'Exchange',
      //   dataIndex: 'accountMarket',
      //   key: 'accountMarket',
      //   sorter: (a, b) => a.accountMarket.localeCompare(b.accountMarket),
      //   sortDirections: ['descend', 'ascend'],
      //   render: value => value ? value : '-'
      // },
      {
        title: 'Pair',
        dataIndex: 'pair',
        key: 'pair',
        sorter: (a, b) => a.pair.localeCompare(b.pair),
        sortDirections: ['descend', 'ascend'],
        render: value => value ? value : '-'
      },
      {
        title: 'Date Opened',
        dataIndex: 'entryDatetime',
        key: 'entryDatetime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Date Closed',
        dataIndex: 'exitDatetime',
        key: 'exitDatetime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        sorter: (a, b) => a.size - b.size,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => value ? this.renderSizeElement(value, record.currency) : '-'
      },
      {
        title: 'Avg. Entry',
        dataIndex: 'averageEntryPrice',
        key: 'averageEntryPrice',
        sorter: (a, b) => a.averageEntryPrice - b.averageEntryPrice,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? Number(value).toFixed(8) : '-'
      },
      {
        title: 'Avg. Exit',
        dataIndex: 'averageExitPrice',
        key: 'averageExitPrice',
        sorter: (a, b) => a.averageExitPrice - b.averageExitPrice,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? Number(value).toFixed(8) : '-'
      },
      {
        title: 'P/L %',
        dataIndex: 'profitPercentage',
        key: 'profitPercentage',
        sorter: (a, b) => a.profitPercentage - b.profitPercentage,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? this.renderPLValue(value, 'percentage') : '-'
      },
      {
        title: 'P/L $',
        dataIndex: 'profitUsd',
        key: 'profitUsd',
        sorter: (a, b) => a.profitPercentage - b.profitPercentage,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? this.renderPLValue(value, 'dollar') : '-'
      },
      {
        title: 'Fee',
        dataIndex: 'fee.amount',
        key: 'fee.amount',
        sorter: (a, b) => a.amount - b.amount,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => value ? this.renderSizeElement(value, record.fee.currency) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [
          {
            text: 'Cancelled',
            value: 'cancelled',
          },
          {
            text: 'Completed',
            value: 'completed',
          },
          {
            text: 'Failed',
            value: 'failed',
          },
          {
            text: 'In progress',
            value: 'in progress',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        render: status => this.renderStatusButton(status)
      },
    ];

    return (
      <Row className="fade-in">
        <Scrollbar>
          <Table
            rowClassName="table-row"
            dataSource={this.props.trades}
            columns={columns}
          />
        </Scrollbar>
      </Row>
    )
  }

  renderTradesPageContent() {
    if (this.props.tradesRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <CsvDownload data={this.props.trades}>Download trade history</CsvDownload>
          {this.renderTradesTable()}
        </React.Fragment>
      )
    }
  }

  render() {
    // console.log(this.props.trades);
    return (
      <React.Fragment>
        {this.renderTradesPageContent()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    trades: Object.values(state.trades.tradesList),
    notification: state.shared.notification,
    tradesRequestLoading: state.trades.tradesRequestLoading
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, actions)
)(TradesPage);
