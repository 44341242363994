import React, { Component } from "react";
import { ResponsivePie } from '@nivo/pie'
import {connect} from "react-redux";
import Widget from 'components/shared/Widget';
import Loader from 'components/shared/Loader';
import EmptyState from 'components/shared/EmptyState';

class ReportPie extends Component {
  constructor(props) {
    super(props);
  }

  generatePie() {
    let { pieChartData } = this.props.reports;

    if (pieChartData.length > 0) {
      return (
        <ResponsivePie
          data={pieChartData}
          sliceLabel="amount"
          margin={{
              "top": 40,
              "right": 70,
              "bottom": 40,
              "left": 70
          }}
          innerRadius={0.5}
          tooltip={function({color, id, label, value}) {return <div>{id}: <b>{label}</b></div>}}
          cornerRadius={3}
          colorBy="id"
          borderWidth={1}
          borderColor="inherit:darker(0.2)"
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor="orange"
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor="#333333"
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          endAngle={360}
          legends={undefined}
        />
      )
    } else {
      return (
        <EmptyState/>
      )
    }
  }

  render() {
    return (
      <Widget>
        <div className="ant-row-flex vx-px-4 vx-pt-4" style={{marginBottom: "30px"}}>
          <h2 className="h4 vx-mb-3" style={{fontWeight: 600}}>Exchange Balance</h2>
        </div>
        <div className="report-pie">
          { this.props.reportsRequestLoading ? <Loader/> : this.generatePie() }
        </div>
      </Widget>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportsRequestLoading: state.reports.reportsRequestLoading,
  }
}

export default connect(mapStateToProps, { })(ReportPie);