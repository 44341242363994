import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';

import reducers from './reducers';
import App from './containers/App';

import Router from './containers/Router';
import Root from 'Root';

import './styles/tailwind.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  {
    auth: { 
      authenticated: localStorage.getItem('token'),
      validToken: false,
      user: {
        email: '',
        loginHistory: [],
        selectedFeeCurrency: ''
      },
      selectedFeeCurrency: ''
    }
  },
  composeEnhancers(applyMiddleware(reduxThunk))
);

const dsn = window.ENV.REACT_APP_SENTRY_DSN;
if (dsn) {
  Sentry.init({ dsn });
}

ReactDOM.render(
  <Root>
    <Provider store={store}>
      <App>
        <Router/>
      </App>
    </Provider>
  </Root>,
  document.querySelector('#root')
)