import React from 'react';

class ExchangeIcon extends React.Component {
  render() {
    return (
      <div className="flex items-center" style={{height: "38px"}}>
        <img alt="" style={{width: '16px', height: '16px'}} className="mr-2" src={this.props.icon}/>
        {this.props.label}
      </div>
    )
  }
}

export default ExchangeIcon;