import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Tooltip, Icon } from "antd";
import LineIndicator from "../LineIndicator";
import Widget from "../Widget";
import Loader from "components/shared/Loader";
import EmptyState from "components/shared/EmptyState";
import ContractSection from "components/shared/ProfileWidget/ContractSection";
import { 
  DEPOSIT_PAGE_URL,
  WITHDRAWAL_PAGE_URL,
} from 'containers/Router/constants';

function pointFormatter (number) {
  var isNegative = number < 0 ? '-' : '';
  return isNegative + '$' + Math.abs(number).toFixed(2);
}

class AccountBalance extends React.Component {
  render() {
    const { accountBalances } = this.props;
    let messageEmptyState = <div>No funds available.<br/>Please <b><a href={DEPOSIT_PAGE_URL}>deposit</a></b>.</div>;

    if (accountBalances) {
      return (
        <Widget>
          <div className="flex vx-mb-3">
            { accountBalances.totalPortfolioValue <= 0 && 
            <div className="mr-3">
              <Tooltip title="Not enough funds. Please deposit funds to enable trading.">
                <Icon style={{position: "relative", top: "-3px", fontSize: "22px", color: "rgb(226, 92, 59)"}} type="warning" />
              </Tooltip>
            </div> 
            }
            <h2 className="h4" style={{fontWeight: 600}}>Account Balance</h2>
          </div>
          <Row>
            <Col lg={12} md={12} sm={12} xs={24}>
  
              <div className="ant-row-flex" style={{marginBottom: "20px"}}>
                <h2 className="vx-mr-2 vx-mb-0 vx-ifs-xxxl vx-font-weight-medium" style={{fontWeight: 600, fontSize: "30px"}}>{pointFormatter(accountBalances.totalPortfolioValue)}</h2>
              </div>
              <div className="ant-row-flex vx-mb-3 vx-mb-md-2">
                <Link to={DEPOSIT_PAGE_URL}><Button className="vx-mr-2" type="primary">Deposit</Button></Link>
                <Link to={WITHDRAWAL_PAGE_URL}><Button className="vx-btn-cyan" type="primary">Withdraw</Button></Link>
              </div>
            </Col>
  
            <Col lg={12} md={12} sm={12} xs={24} className="mt-8 lg:mt-0">
              <div className="vx-site-dash">
                {accountBalances.balances.length > 0 &&
                  <>
                    <ul className="vx-line-indicator vx-ifs-sm">
                    {
                      accountBalances.balances.map(function (balance) {
                        return (
                          <li>
                            <LineIndicator currency={balance.currency} value={balance.value} color={balance.color} percent={balance.percent}/>
                          </li>
                        )
                      })
                    }
                    </ul>
                  </>
                }
                {accountBalances.balances.length == 0 && <EmptyState message={messageEmptyState} minHeight="90px"/>}
              </div>
            </Col>
          </Row>
        </Widget>
      );
    } else {
      return (
        <Widget>
          <Loader minHeight="300px"/>
        </Widget>
      )
    }
  }
}

export default AccountBalance;
