import React from 'react';

import { 
  Button as ButtonAntd, 
} from 'antd';

const Button = (props) => {
  return (
    <ButtonAntd 
      size="large"
      loading={props.iconLoading}
      icon={props.icon}
      onClick={props.enterIconLoading}
      className="mt-2 w-full items-center h-7 justify-center"
      htmlType="submit" 
      style={{display: "flex"}}
      type="primary"
      disabled={props.disabled}>
      {props.name}
    </ButtonAntd>
  )
}

export default Button;