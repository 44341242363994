import React from 'react';
import { Icon } from 'antd';
import _ from 'lodash';
import exist from 'exist.js';

const steps = ["Connect Exchange account", "Deposit funds", "Finish"]
// const steps = ["Connect Exchange account", "Deposit funds", "Sign Contract", "Finish"]

class Navigation extends React.Component {
  constructor() {
    super();

    this.state = {
      currentStep: 1
    }
  }

  componentDidMount() {
    const { user } = this.props;
    const exchangeExists = exist.get(user, 'exchanges[0]');

    if (exchangeExists) {
      localStorage.setItem('step-1', 'done');
    }

    // if (user.contractSigned) {
    //   localStorage.setItem('step-2', 'done');
    // }

    if (!_.isEmpty(user.balances)) {
      localStorage.setItem('step-2', 'done');
    }

    this.goToFirstUndoneStep();
  }

  goToFirstUndoneStep() {
    const { totalSteps, goToStep } = this.props;

    for (var i = 1; i <= totalSteps; i++) {
      if (!localStorage.getItem(`step-${i}`)) {
        goToStep(i);
        return;
      }
    }
  }

  makePreviouslyStepsDone(step) {
    const { goToStep } = this.props;

    for (var i = step - 1; i >= 1; i--) {
      localStorage.setItem(`step-${i}`, 'done')
    }

    goToStep(step);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentStep: nextProps.currentStep
    })
  }

  handleNextStep = (step) => {
    const { currentStep } = this.props;
    localStorage.setItem(`step-${currentStep}`, 'done');
    this.props.goToStep(step);
  }

  renderCircle = (step) => {
    const isDone = localStorage.getItem(`step-${step}`);

    if (isDone) {
      return <Icon type="check"/>
    }

    return step;
  }

  isDone = (step) => {
    return localStorage.getItem(`step-${step}`);
  }

  render() {
    const { currentStep } = this.state;
    const _this = this;

    return (
      <div className="navigation">
        {
          steps.map(function (step, idx) {
            return (
              <div
                onClick={() => _this.makePreviouslyStepsDone(idx + 1)}
                className={`step ${currentStep == idx + 1 ? 'active' : ''} ${_this.isDone(idx + 1) ? 'done' : ''}`}>
                <div className="circle">
                  {_this.renderCircle(idx + 1)}
                </div>
                <div className="label">{step}</div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default Navigation;