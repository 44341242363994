import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Icon, Tooltip } from "antd";
import LineIndicator from "../LineIndicator";
import Widget from "../Widget";
import Loader from "components/shared/Loader";
import EmptyState from "components/shared/EmptyState";
import { pointFormatter } from "utils";
import Earnings from "components/shared/Earnings";
import {
  REPORTS_PAGE_URL
} from 'containers/Router/constants';
import ContractSection from '../ProfileWidget/ContractSection';

class BinanceAccountBalance extends React.Component {
  render() {
    const { binanceBalances, user } = this.props;

    // console.log('binance balances: ', binanceBalances);

    let messageEmptyState = <div>No data available</div>;

    if (binanceBalances) {
      return (
        <Widget>
          <div className="flex vx-mb-3">
            {/* { !user.contractSigned && <div className="mr-3"><ContractSection notProfile icon/></div> } */}
            <h2 className="h4" style={{fontWeight: 600}}>Exchange Account Balance</h2>
          </div>

          <Row className="fade-in">
            <Col lg={12} md={12} sm={12} xs={24}>

              <div className="ant-row-flex" style={{marginBottom: "20px"}}>
                <h2 className="vx-mr-2 vx-mb-0 vx-ifs-xxxl vx-font-weight-medium" style={{fontWeight: 600, fontSize: "30px"}}>
                  {binanceBalances.totalBalancesValue && pointFormatter(binanceBalances.totalBalancesValue)}
                </h2>
              </div>
              <div className="ant-row-flex vx-mb-3 vx-mb-md-2">
                <a href={REPORTS_PAGE_URL}><Button className="vx-mr-2" type="primary">Reports</Button></a>
              </div>
              <Earnings binanceBalances={binanceBalances} />
            </Col>

            <Col lg={12} md={12} sm={12} xs={24} className="mt-8 lg:mt-0">
              <div className="vx-site-dash">
                {binanceBalances.balances && binanceBalances.balances.length > 0 &&
                  <>
                    <ul className="vx-line-indicator vx-ifs-sm">
                    {
                      binanceBalances.balances.map(function (balance) {
                        return (
                          <li>
                            <LineIndicator currency={balance.id} value={balance.label} color="blue" percent={balance.value}/>
                          </li>
                        )
                      })
                    }
                    </ul>
                  </>
                }
                {binanceBalances.balances && binanceBalances.balances.length == 0 && <EmptyState message={messageEmptyState} minHeight="90px"/>}
              </div>
            </Col>
          </Row>
        </Widget>
      );
    } else if (typeof binanceBalances == 'undefined') {
      return (
        <Widget center>
          <Row className="w-64 flex justify-center">
            <Col lg={12} md={12} sm={12} xs={24} className="mt-8 lg:mt-0">
              <div className="vx-site-dash w-full">
                <EmptyState message={messageEmptyState} minHeight="90px"/>
              </div>
            </Col>
          </Row>
        </Widget>
      )
    } else {
      return (
        <Widget>
          <Loader minHeight="300px"/>
        </Widget>
      )
    }
  }
}

export default BinanceAccountBalance;
