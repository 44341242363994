import _ from 'lodash';

import { 
  SET_TWO_FACTOR_SECRET,
  GET_TWO_FACTOR_SECRET,
  USERS_REQ_IS_LOADING,
  TWO_FACTOR_REQ_LOADING,
  CREATE_SIGNATURE_EMBEDDED_LINK,
  SIGNATURES_REQ_IS_LOADING
} from 'actions/types';

const INITIAL_STATE = {
  twoFactorSecret: '',
  twoFactorRequestLoading: false,
  signatureEmbeddedLink: '',
  signaturesRequestLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case GET_TWO_FACTOR_SECRET:
      return { ...state, twoFactorSecret: action.payload.secret };
    case SET_TWO_FACTOR_SECRET:
      return { ...state }
    case USERS_REQ_IS_LOADING:
      return { ...state, exchangesRequestLoading: action.payload }
    case TWO_FACTOR_REQ_LOADING:
      return { ...state, twoFactorRequestLoading: action.payload }
    case CREATE_SIGNATURE_EMBEDDED_LINK:
      return { ...state, signatureEmbeddedLink: action.payload };
    case SIGNATURES_REQ_IS_LOADING:
      return { ...state, signaturesRequestLoading: action.payload }
    default:
      return state;
  }
}