import React from 'react';
import { Form } from 'antd';

import Button from 'components/shared/ForgotPasswordForm/components/Button';
import Field from 'components/shared/ForgotPasswordForm/components/Field';
import Label from 'components/shared/ForgotPasswordForm/components/Label';
import { Field as ReduxField } from 'redux-form';
import Recaptcha from 'components/shared/Recaptcha';

class ForgotPasswordForm extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.onSubmit}>
        <div className="flex flex-col">
          <Label name="Email"/>
          <Field name="email" type="text"/>
        </div>

        <ReduxField
          component={Recaptcha}
          classNames="justify-content-end"
          name="recaptcha"
          ref={el => {
          this.captcha = el;
          }}
          withRef
        />

        <Button 
          name="Reset Password"
          icon="login"
          iconLoading={this.props.iconLoading}
        />
      </Form>
    )
  }
}

export default ForgotPasswordForm;