import React from 'react';
import '../../styles/styles.scss';
import { connect } from 'react-redux';
import Notification from 'components/shared/Notification';

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.notification.message 
        ? 
          <Notification 
            type={this.props.notification.type}
            icon={this.props.notification.icon}
            notificationMessage={this.props.notification.message} 
            duration={this.props.notification.duration}
          /> : 
        ""}
        {this.props.children}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.shared.notification,
  }
}

export default connect(mapStateToProps, null )(App);