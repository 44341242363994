import _ from 'lodash';
import React from 'react';

import { Field as ReduxField } from 'redux-form';
import { 
  Form, 
  AutoComplete, 
} from 'antd'

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, children, hasFeedback, label, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <Component {...input} {...rest} children={children} />
    </FormItem>
  );
};

const AAutoComplete = makeField(AutoComplete);

const Field = (props) => {
  let dataSourceArr = [];

  _.map(props.dataSource, (addressObj) => {
    dataSourceArr.push(addressObj.address)
  });

  return (
    <ReduxField
      name={props.name}
      dataSource={dataSourceArr}
      placeholder="Write or select a withdrawal address"
      component={AAutoComplete}
      className="w-full h-7"
    />
  )
}

export default Field;