import React from 'react';
import { notification } from 'antd';

import { connect } from 'react-redux';
import { clearNotificationMessage } from 'actions/shared';

class Notification extends React.Component {
  componentDidMount() {
    this.openNotificationWithIcon(this.props.icon);
  }

  openNotificationWithIcon = type => {
    notification[type]({
      message: this.props.type,
      onClose: this.props.clearNotificationMessage,
      description: this.props.notificationMessage,
      duration: this.props.duration
    });
  };

  render() {
    return "";
  }
};

export default connect(null, { clearNotificationMessage })(Notification);