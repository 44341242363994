// TODO: Must be a separate component other than Antd. 
// It should hide rest of the links if you're not already there.
// It should behave like a "current page in the app", now it behaves like a "navigation"
// ANTD does not let us to achieve this behaviour so we need a separate component with its styles

import React from 'react';

const routes = {
  "/dashboard": "Dashboard",
  "/dashboard/exchanges": "Exchanges",
  "/dashboard/trades": "Trades",
  "/dashboard/withdrawal": "Withdrawal",
  "/dashboard/deposit": "Deposit",
  "/dashboard/profile": "Profile",
  "/dashboard/reports": "Reports",
  "/dashboard/admin/users": "Users",
  "/dashboard/admin/reports": "Reports",
  "/dashboard/admin/main-reports": "Main Reports",
  "/dashboard/admin/withdrawals": "Withdrawals",
  "/dashboard/admin/deposits/new": "Add Deposit",
  "/dashboard/referral": "Referral"
}

class Breadcrumbs extends React.Component {
  render() {
    return (
      <div>
        {routes[window.location.pathname]}
      </div>
    )
  }
}

export default Breadcrumbs;