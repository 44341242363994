import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/shared/WithdrawalForm/components/Button';
import { Row, Col, Card, Select, Input, Form, DatePicker, AutoComplete } from 'antd';
import CoinIcon from 'components/shared/CoinIcon';
import CYLIcon from 'images/coins/cyl.png';
import ETHIcon from 'images/coins/eth.png';
import USDTIcon from 'images/coins/usdt.png';

import { selectAddDepositCoin, fetchAllUsers, fetchSearchedUser, createDeposit } from 'actions/admin';
import moment from 'moment';
import Loader from "components/shared/Loader";

const FormItem = Form.Item
const { Option } = Select;

class AdminDepositForm extends React.Component {
  constructor() {
    super();
    this.state = {
      searchedUsers: []
    }
  }
  componentDidMount() {
    // console.log(this.props);
    this.props.fetchAllUsers();
  }

  handleChange = (value) => {
    let selectedCoinObject = {};

    switch (value) {
      case 'eth':
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum"
        break;
      case 'usdt':
        selectedCoinObject.name = "USDT";
        selectedCoinObject.fullName = "Tether"
        break;
      case 'cyl':
        selectedCoinObject.name = "CYL";
        selectedCoinObject.fullName = "Crystal Token"
        break;
      default:
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum"
        break;
    }

    this.props.selectAddDepositCoin(selectedCoinObject);
  }

  renderFormButton = () => {
    return (
      <FormItem>
        <Button
          name="Confirm"
          icon="login"
          iconLoading={this.props.iconLoading}
          disabled={false}
        />
      </FormItem>
    )
  }

  submitDeposit = (e) => {
    const { form, selectedAddDepositCoin } = this.props;
    e.preventDefault();

    form.validateFields((error, values) => {
      if (error) {
        console.log(error);
      } else {
        const payload = {
          createdAt: values.createdAt,
          userEmail: values.userEmail,
          // address: values.walletAddress,
          amount: values.depositAmount,
          currency: selectedAddDepositCoin.name,
          // transactionId: values.transactionId,
          status: values.status,
        }

        this.props.createDeposit(payload, () => {
          form.resetFields();
        });
      }
    });
  };

  handleUsersSelect = (value) => {
    this.props.form.setFieldsValue({userEmail: value})
  }

  handleStatusSelect = (value) => {
    this.props.form.setFieldsValue({status: value})
  }

  handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    this.props.form.setFieldsValue({[name] : value});
  }

  handleUserSearch = (value) => {
    const { fetchSearchedUser } = this.props;

    if (value.length > 2) {
      fetchSearchedUser(value);
    }
  }

  generateCardDetails() {
    const { getFieldDecorator } = this.props.form

    return (
      <React.Fragment>
        <Card className="mt-2">
          <Form onSubmit={this.submitDeposit}>
            <FormItem label="User email">
              {getFieldDecorator('userEmail', {
                rules: [{ required: true, message: 'User email is required.' }],
              })(
                <AutoComplete
                  onChange={this.handleUserSearch}
                  className="w-full mt-2"
                  dataSource={this.props.searchedUsersList}
                  placeholder="User email"
                />
              )}
            </FormItem>

            <FormItem label="Created at">
              {getFieldDecorator('createdAt', {
                initialValue: moment(),
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Created date is required.',
                    whitespace: true,
                  },
                ],
              })(<DatePicker showTime className="w-full"/>)}
            </FormItem>

            <FormItem label="Deposit amount">
              {getFieldDecorator('depositAmount', {
                rules: [
                  {
                    type: 'string',
                    required: true,
                    message: 'Deposit amount is required.',
                    whitespace: true,
                  },
                ],
              })(<Input type="number" step="any" name="depositAmount" onChange={this.handleInputChange}/>)}
            </FormItem>

            {/* <FormItem label="Wallet address">
              {getFieldDecorator('walletAddress', {
                rules: [
                  {
                    type: 'string',
                    required: true,
                    message: 'Wallet address is required.',
                    whitespace: true,
                  },
                ],
              })(<Input type="text" name="walletAddress" onChange={this.handleInputChange}/>)}
            </FormItem> */}

            {/* <FormItem label="Transaction id">
              {getFieldDecorator('transactionId', {
                rules: [
                  {
                    type: 'string',
                    required: true,
                    message: 'Transaction ID is required.',
                    whitespace: true,
                  },
                ],
              })(<Input type="text" name="transactionId" onChange={this.handleInputChange}/>)}
            </FormItem> */}

            {/* <FormItem label="Status">
              {getFieldDecorator('status', {
                rules: [{ required: true, message: 'Status is required.' }],
              })(
                <Select showSearch onChange={this.handleStatusSelect}>
                  <Option value="Pending">Pending</Option>
                  <Option value="Complete">Complete</Option>
                </Select>
              )}
            </FormItem> */}

            <FormItem label="Comment">
              {getFieldDecorator('status', {
                rules: [
                  {
                    type: 'string',
                    required: true,
                    message: 'Comment is required.',
                    whitespace: true,
                  },
                ],
              })(<Input type="text" name="status" onChange={this.handleInputChange}/>)}
            </FormItem>

            {this.renderFormButton()}
          </Form>
        </Card>
      </React.Fragment>
    )
  }

  generateLoader() {
    return (
      <Loader fontSize="24"/>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Row className={this.props.className}>
          <Col span={24} className="md:max-w-sm">
            <Select
              size={"large"}
              className="w-full h-10 mb-2"
              placeholder="Select a coin"
              defaultValue={this.props.selectedAddDepositCoin.name}
              onChange={this.handleChange}>

              <Option value="cyl">
                <CoinIcon icon={CYLIcon} coin="CYL"/>
              </Option>

              <Option value="eth">
                <CoinIcon icon={ETHIcon} coin="ETH"/>
              </Option>

              <Option value="usdt">
                <CoinIcon icon={USDTIcon} coin="USDT (ERC-20)"/>
              </Option>
            </Select>

            {this.props.selectedAddDepositCoin.name ? this.generateCardDetails() : ""}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    searchedUsersList: state.admin.searchedUsersList,
    usersListArr: state.admin.usersListArr,
    selectedAddDepositCoin: state.admin.selectedAddDepositCoin,
  }
};

export default connect(mapStateToProps, { selectAddDepositCoin, fetchAllUsers, fetchSearchedUser, createDeposit } )(Form.create({})(AdminDepositForm));