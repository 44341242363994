import trades from 'apis/trades';

import { 
  FETCH_TRADES,
  SET_NOTIFICATION_MESSAGE,
  TRADES_REQ_IS_LOADING
} from 'actions/types';

export const fetchTrades = (status) => async (dispatch, getState) => {
  try {
    trades.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: TRADES_REQ_IS_LOADING, payload: true });
    const response = await trades.get(`${status ? `/?status=${status}` : ''}`);

    dispatch({ type: FETCH_TRADES, payload: response.data.result })

    dispatch({ type: TRADES_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: TRADES_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const loadingRequest = (isLoading) => {
  return {
    type: TRADES_REQ_IS_LOADING,
    payload: isLoading
  }
}
