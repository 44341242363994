import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import Scrollbar from 'react-smooth-scrollbar';

class LoginHistory extends React.Component {
  render() {
    const currentUserHistory = this.props.auth.user ? this.props.auth.user.loginHistory : "";
    const columns = [
      {
        title: 'Date',
        dataIndex: 'name',
        key: 'name',
        render: text => <span>{text}</span>,
      },
      {
        title: 'IP Address',
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: 'Device',
        key: 'action',
        render: (text, record) => <span>{record.device}</span>,
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: status => (
          <span>
            {status.map(status => {
              return (
                <div className={`btn--status ${status.toLowerCase()}`}>
                  {status}
                </div>
              );
            })}
          </span>
        ),
      },
    ];
    const data = [];

    for (let i = currentUserHistory.length - 1; i >= 0; i--) {
      const dataObj = {
        key: i,
        name: moment(currentUserHistory[i].createdAt).format('ll HH:mm'),
        device: currentUserHistory[i].device,
        address: currentUserHistory[i].ipAddress,
        status: currentUserHistory[i].status
          ? ['Successful']
          : ['Unsuccessful'],
      };

      data.push(dataObj);
    }

    return (
      <Scrollbar>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 4,
            showSizeChanger: true,
            pageSizeOptions: ['6', '12', '24'],
          }}
        />
      </Scrollbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, {})(LoginHistory);
