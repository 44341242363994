import React from 'react';
import { Spin, Icon } from 'antd';

class Loader extends React.Component {
  componentDidMount() {
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: this.props.fontSize || 34, position: this.props.position, bottom: this.props.bottom }} spin />;

    return <div className="w-full h-full flex justify-center items-center" style={{minHeight: this.props.minHeight}}>
      <Spin indicator={antIcon} />
    </div>
  }
};

export default Loader;