import deposits from 'apis/deposits';

import { 
  FETCH_DEPOSITS,
  SET_NOTIFICATION_MESSAGE,
  DEPOSITS_REQ_IS_LOADING,
  FETCH_DEPOSIT_ADDRESS,
  DEPOSITS_SELECTED_COIN,
  DEPOSITS_ADDRESS_REQ_IS_LOADING
} from 'actions/types';

export const fetchDepositsHistory = () => async (dispatch, getState) => {
  try {
    deposits.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: DEPOSITS_REQ_IS_LOADING, payload: true });
    const response = await deposits.get('/');

    dispatch({ type: FETCH_DEPOSITS, payload: response.data.result })

    dispatch({ type: DEPOSITS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: DEPOSITS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const fetchDepositAddress = (currency, callback) => async (dispatch, getState) => {
  try {
    deposits.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: DEPOSITS_ADDRESS_REQ_IS_LOADING, payload: true });
    const response = await deposits.get(`/address/${currency}`);

    dispatch({ type: FETCH_DEPOSIT_ADDRESS, payload: response.data.result })

    dispatch({ type: DEPOSITS_ADDRESS_REQ_IS_LOADING, payload: false });

    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: DEPOSITS_ADDRESS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
}

export const selectDepositCoin = (coinObject) => {
  return {
    type: DEPOSITS_SELECTED_COIN,
    payload: coinObject
  }
}

export const loadingRequest = (isLoading) => {
  return {
    type: DEPOSITS_REQ_IS_LOADING,
    payload: isLoading
  }
}
