import React from 'react';
import { compose } from 'redux';
import requireAuth from 'components/requireAuth';
import { connect } from 'react-redux';
import * as actions from 'actions/traders';
import { Link } from 'react-router-dom';
import {
  MARKETPLACE_URL,
  MARKETPLACE_URL_SINGLE
} from 'containers/Router/constants';
import { Card, Button, Row, Col, Modal, Switch } from 'antd';

class MarketplacePage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      pageMode: 'list'
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.fetchTrader(id, () => {
      });
      this.setState({
        pageMode: 'edit'
      })
    } else {
      this.props.fetchTraders();
      this.setState({
        pageMode: 'list'
      })
    }
  }

  render() {
    const { pageMode } = this.state;
    const { trader, traders } = this.props;
    // console.log(trader);
    return (
      <>
        {pageMode === 'edit' &&
          <div>
            <div className="mb-4">
              <a href={ MARKETPLACE_URL }>
                <Button type="secondary">
                  Back
                </Button>
              </a>
            </div>
            <div className="flex justify-between">
              <div className="w-1/5 mr-10">
                <div>
                  <img
                      width="100%" height="150px" src="https://d1d6vn08901bud.cloudfront.net/Crystal%20Token_logo.png">
                    </img>
                    <div className="font-semibold text-base my-2 text-center">{trader.name}</div>
                    <div className="text-center"><b>Cost:</b> {trader.cost} CYL</div>
                    <div className="text-center"><b>Exchange:</b> {trader.exchange}</div>
                    <Button className="w-full my-4" type="primary">Subscribe</Button>
                </div>
                <div>
                  <div><b>Seller:</b> ??</div>
                  <div>
                    <a href={`mailto:${trader.supportEmail}`}>Mail support</a>
                  </div>
                </div>
                <div className="text-lg mt-6 mb-2">Details</div>
                <div dangerouslySetInnerHTML={{__html: trader.details}}></div>
              </div>
              <div className="w-4/5" dangerouslySetInnerHTML={{__html: trader.overview}}></div>
            </div>
          </div>
        }
        {pageMode === 'list' &&
        <Row gutter={16} className="fade-in">
          {traders.map((trader) => {
            return <Col span={24} key={trader._id} className="exchange-card lg:max-w-sm">
              <Card className="mb-4 text-center" title={trader.name}>
                <div className="flex justify-center">
                <div className="img-wrapper-trader">
                  <img
                    className="mb-6"
                    width="150px" height="150px" src="https://d1d6vn08901bud.cloudfront.net/Crystal%20Token_logo.png">
                  </img>
                </div>
                </div>
                <div className="justify-center flex">
                  <div className="font-semibold mr-1">Cost:</div>
                  <div className="mr-1">{trader.cost}</div>
                  <b>CYL</b>
                </div>

                <div className="flex justify-center mb-6">
                  <div className="font-semibold mr-1">Exchange:</div>
                  {trader.exchange}
                </div>

                <a href={`${MARKETPLACE_URL}/${trader._id}`} className="w-full">
                  <Button className="w-full" type="primary">View more info</Button>
                </a>
              </Card>
            </Col>
          })}
        </Row>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    traders: Object.values(state.traders.tradersList),
    trader: state.traders.trader,
    tradesRequestLoading: state.traders.tradesRequestLoading
  }
}

export default compose(
  requireAuth,
  connect(mapStateToProps, actions)
)(MarketplacePage);