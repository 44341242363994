import React from 'react';
import requireAuth from 'components/requireAuth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { clearNotificationMessage } from 'actions/shared';
import { Table, Col, Row } from 'antd';
import AccountBalance from "components/shared/AccountBalance";
import BinanceAccountBalance from "components/shared/BinanceAccountBalance";
import BalanceHistory from "components/shared/BalanceHistory";
import { fetchTrades } from 'actions/trades';
import { fetchHistory, fetchBalances, fetchBinanceBalance } from 'actions/reports';
import { finishOnboarding, fetchUser } from 'actions';
import Loader from "components/shared/Loader";
import Scrollbar from 'react-smooth-scrollbar';
import Onboarding from "components/shared/Onboarding";
import moment from 'moment';

class DashboardPage extends React.Component {
  constructor() {
    super();

    this.state = {
      showOnboarding: true
    }
  }

  componentDidMount() {
    this.props.clearNotificationMessage();
    this.props.fetchTrades("in progress");
    this.props.fetchBalances();
    this.props.fetchBinanceBalance();
    this.props.fetchHistory(31);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderStatusButton(status) {
    let capitalizedStatus;

    if (status) {
      capitalizedStatus = this.capitalizeFirstLetter(status);
    }

    switch(status) {
      case "in progress":
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
      case "completed":
        return <div className="btn--status completed">{capitalizedStatus}</div>
      case "failed":
        return <div className="btn--status failed">{capitalizedStatus}</div>
      case "cancelled":
        return <div className="btn--status cancelled">{capitalizedStatus}</div>
      default:
        return <div className="btn--status in-progress">In progress</div>
    }
  }

  renderSizeElement = (size, currency) => {
    return (
      <div className="flex">
        {Number(size).toFixed(8)}
        <div className="ml-2 font-semibold">{currency}</div>
      </div>
    )
  }

  renderTradesTable() {
    const columns = [
      // {
      //   title: 'Exchange',
      //   dataIndex: 'accountMarket',
      //   key: 'accountMarket',
      //   sorter: (a, b) => a.accountMarket.localeCompare(b.accountMarket),
      //   sortDirections: ['descend', 'ascend'],
      //   render: value => value ? value : '-'
      // },
      {
        title: 'Pair',
        dataIndex: 'pair',
        key: 'pair',
        sorter: (a, b) => a.pair.localeCompare(b.pair),
        sortDirections: ['descend', 'ascend'],
        render: value => value ? value : '-'
      },
      {
        title: 'Date Opened',
        dataIndex: 'entryDatetime',
        key: 'entryDatetime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Date Closed',
        dataIndex: 'exitDatetime',
        key: 'exitDatetime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        sorter: (a, b) => a.size - b.size,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => value ? this.renderSizeElement(value, record.currency) : '-'
      },
      {
        title: 'Avg. Entry',
        dataIndex: 'averageEntryPrice',
        key: 'averageEntryPrice',
        sorter: (a, b) => a.averageEntryPrice - b.averageEntryPrice,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? Number(value).toFixed(8) : '-'
      },
      {
        title: 'Avg. Exit',
        dataIndex: 'averageExitPrice',
        key: 'averageExitPrice',
        sorter: (a, b) => a.averageExitPrice - b.averageExitPrice,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? Number(value).toFixed(8) : '-'
      },
      {
        title: 'P/L %',
        dataIndex: 'profitPercentage',
        key: 'profitPercentage',
        sorter: (a, b) => a.profitPercentage - b.profitPercentage,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? `${Number(value).toFixed(2)} %` : '-'
      },
      {
        title: 'P/L $',
        dataIndex: 'profitUsd',
        key: 'profitUsd',
        sorter: (a, b) => a.profitPercentage - b.profitPercentage,
        sortDirections: ['descend', 'ascend'],
        render: value => value ? `$${Number(value).toFixed(2)}` : '-'
      },
      // {
      //   title: 'Fee',
      //   dataIndex: 'fee.amount',
      //   key: 'fee.amount',
      //   sorter: (a, b) => a.amount - b.amount,
      //   sortDirections: ['descend', 'ascend'],
      //   render: (value, record) => value ? this.renderSizeElement(value, record.fee.currency) : '-'
      // },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [
          {
            text: 'Cancelled',
            value: 'cancelled',
          },
          {
            text: 'Completed',
            value: 'completed',
          },
          {
            text: 'Failed',
            value: 'failed',
          },
          {
            text: 'In progress',
            value: 'in progress',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        render: status => this.renderStatusButton(status)
      },
    ];

    return (
      <Row>
        <Scrollbar>
        <Table
        rowClassName="table-row"
        dataSource={this.props.trades}
        columns={columns}
      />
        </Scrollbar>
      </Row>
    )
  }

  handleOnBoardingClose = () => {
    const { finishOnboarding, fetchUser } = this.props;

    finishOnboarding(function() {
      fetchUser();
    })

    this.setState({
      showOnboarding: !this.state.showOnboarding
    })
  }

  renderDashboardPageContent() {
    const { reportsHistory, accountBalances, user, binanceBalances} = this.props;
    // console.log("BN B", binanceBalances);
    // console.log('AC B', accountBalances);
    const { showOnboarding } = this.state;

    if (this.props.reportsRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <div className="dashboard fade-in">
          {(!user.onboardingDone) && <Onboarding user={user} handleOnBoardingClose={this.handleOnBoardingClose} />}

          {(user.onboardingDone) &&
            <div className="fade-in">
              <Row gutter={50}>
                <Col xl={12} lg={24} md={12} sm={24} xs={24} className="mb-8 lg:mb-0 balances">
                  <AccountBalance accountBalances={accountBalances} />
                </Col>
                <Col xl={12} lg={24} md={12} sm={24} xs={24} className="balances">
                  <BinanceAccountBalance user={user} binanceBalances={binanceBalances} />
                </Col>
              </Row>

              <Row style={{marginTop: "40px"}}>
                <BalanceHistory user={user} history={reportsHistory}/>
              </Row>
            </div>
          }
        </div>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDashboardPageContent()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    trades: Object.values(state.trades.tradesList),
    binanceBalances: state.reports.binanceBalances,
    accountBalances: state.reports.accountBalances,
    reportsRequestLoading: state.reports.reportsRequestLoading,
    tradesRequestLoading: state.trades.tradesRequestLoading,
    reportsHistory: state.reports.history
  }
}

export default compose(
  requireAuth,
  connect(mapStateToProps, { clearNotificationMessage, fetchBinanceBalance, fetchTrades, fetchHistory, fetchBalances, finishOnboarding, fetchUser })
)(DashboardPage);