import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import { Row, AutoComplete } from 'antd';
import Button from 'components/shared/WithdrawalForm/components/Button';
import { fetchSearchedUser, fetchAdminUserReports, fetchAdminUserTrades, fetchAdminEquityGraphHistory } from 'actions/admin';

import { Form } from 'antd';

const FormItem = Form.Item

class AdminReportsForm extends React.Component {
  handleUserSearch = (value) => {
    const { fetchSearchedUser } = this.props;

    if (value.length > 2) {
      fetchSearchedUser(value);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <React.Fragment>
        <Row className="fade-in">
          <Form onSubmit={(e) => {this.props.onSubmit(this.props.form, e)}}>
            <FormItem label="User Email" style={{fontWeight: "600"}}>
              {getFieldDecorator('userEmail', {
                rules: [{ required: true, message: 'User email is required.' }],
              })(
                <AutoComplete
                  onChange={this.handleUserSearch}
                  className="w-full mt-2"
                  dataSource={this.props.searchedUsersList}
                  placeholder="Write user email to fetch reports"
                />
              )}
            </FormItem>
            <FormItem>
              <Button 
                name="Fetch reports"
                icon="login"
                iconLoading={this.props.iconLoading}
                disabled={false}
              />
            </FormItem>
          </Form>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    searchedUsersList: state.admin.searchedUsersList
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, { fetchSearchedUser, fetchAdminUserReports, fetchAdminUserTrades, fetchAdminEquityGraphHistory }),
)(Form.create({})(AdminReportsForm));
