import React from 'react';
import { Icon, Button, Modal, Form, Input, Alert, AutoComplete, Tooltip } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchUser } from 'actions';
import { createContract } from 'actions/profile';
import countries from './countries';
import bannedCountries from './bannedCountries';

const FormItem = Form.Item

class ContractSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      country: '',
      signedContractEmbeddedLink: '',
      contractModalVisible: false,
      embeddedContractModalVisible: false,
      embeddedSignedContractModalVisible: false
    }
  }
  
  showContractModal = () => {
    this.setState({
      contractModalVisible: true,
    });
  };

  showEmbeddedContractModal = () => {
    this.setState({
      embeddedContractModalVisible: true,
    });
  };

  showEmbeddedSignedContractModal = (url) => {
    this.setState({
      embeddedSignedContractModalVisible: true,
      signedContractEmbeddedLink: url
    });
  };

  handleContractModalCancel = () => {
    this.setState({ contractModalVisible: false });
  };

  handleNameChange = (event) => {
    this.setState({
      fullName: event.target.value
    })
  }

  handleCountryChange = (value) => {
    this.setState({
      country: value
    })
  }

  handleGenerateDraft = () => {
    const { fullName, country } = this.state;
    const { user } = this.props.auth;

    const _this = this;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.createContract({name: fullName, email: user.email, country: country}, (response, hasError) => {
          if (!hasError) {
            this.setState({
              contractModalVisible: false
            }, function() {
              _this.showEmbeddedContractModal();
            })
          }
        });
      }
    });
  }

  handleEmbeddedContractModalClose = () => {
    this.setState({
      embeddedContractModalVisible: !this.state.embeddedContractModalVisible
    })

    this.props.fetchUser();
  }

  validateCountry = (rule, value, callback) => {
    if (bannedCountries.includes(value)) {
      callback(`${value} is not supported.`);
    } else {
      callback();
    }
  };

  hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { contractModalVisible, embeddedContractModalVisible, embeddedSignedContractModalVisible } = this.state;
    const { signaturesRequestLoading, notProfile, onBoarding, icon } = this.props;
    const { user } = this.props.auth;
    return (
      <>
        <div className="icon-circle-wrapper flex">
          {!notProfile && <>
            <span className={`icon-circle ${!user.contractSigned ? 'red' : ''}`}>
              <Icon type="paper-clip" style={{fontSize: "16px"}}/>
            </span>
            <div className="ml-4">
              <div className="uppercase text-xs font-semibold">Contract</div>
              { user.contractSigned && <a onClick={() => {this.showEmbeddedSignedContractModal(user.contractUrl)}}>Signed</a>}
              { user.contractPending && <div>Pending</div>}
              { (!user.contractSigned && !user.contractPending) && <span>Not signed</span>}
            </div>
          </>} 

          {/* <div className={`${onBoarding ? "font-normal" : "font-semibold"}`}>
            {!user.contractSigned ? "Please sign the contract in order to deposit." : "Contract already signed."}
          </div> */}
        </div>

        { (!user.contractSigned && !user.contractPending && !icon) && 
        <Button 
          icon={`${onBoarding ? "login" : ""}`}
          style={{order: "4", display: "flex", alignItems: "center", justifyContent: "center"}} 
          size={`${onBoarding ? "large" : "normal"}`} 
          type="primary" 
          className={`mt-4 w-full ${onBoarding ? "h-12" : ""}`} 
          onClick={() => {this.showContractModal()}}>
          Sign contract
        </Button> }

        {icon && 
          <Tooltip onClick={() => {this.showContractModal()}} placement={this.props.tooltipPosition || "left"} title="Contract not signed, click to sign">
            <Icon style={{position: "relative", top: "-3px", fontSize: "22px", color: "rgb(226, 92, 59)"}} type="warning" />
          </Tooltip>
        }

        <Modal
          visible={contractModalVisible}
          closable={false}
          maskClosable={false}
          title="Contract - 1 of 2"
          onOk={this.handleGenerateDraft}
          onCancel={this.handleContractModalCancel}
          footer={[
            <Button key="back" onClick={this.handleContractModalCancel}>
              Cancel
            </Button>,
            <Button disabled={this.hasErrors(getFieldsError())} key="submit" type="primary" loading={signaturesRequestLoading} onClick={this.handleGenerateDraft}>
              Generate draft
            </Button>,
          ]}
        >
          <h3>In order to generate your contract we need your full name.</h3>
          <Form className="mt-4">
            <FormItem>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Full name is required.' }],
              })(
                <Input onChange={this.handleNameChange} placeholder="Full name"/>
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('country', {
                rules: [
                  { required: true, message: 'Country is required.' },
                  { validator: this.validateCountry }
                ],
              })(
                <AutoComplete
                  onChange={this.handleCountryChange}
                  className="w-full mt-2"
                  dataSource={countries}
                  placeholder="Country"
                  filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              )}
            </FormItem>
          </Form>
        </Modal>

        <Modal
          visible={embeddedContractModalVisible}
          closable={true}
          maskClosable={true}
          onCancel={this.handleEmbeddedContractModalClose}
          width="800px"
          title="Contract - 2 of 2"
          footer={null}
        >
          <Alert message="Please read the contract and sign on the bottom of the page" banner />
          <iframe className="my-6" style={{width: "100%", minHeight: "400px"}} src={this.props.signatureEmbeddedLink} name="esignatures-io-iframe"></iframe>
        </Modal>

        <Modal
          visible={embeddedSignedContractModalVisible}
          closable={true}
          maskClosable={false}
          width="800px"
          title="Signed contract"
          footer={null}
        >
          <iframe className="my-2" style={{width: "100%", minHeight: "400px"}} src={this.state.signedContractEmbeddedLink} name="esignatures-io-iframe"></iframe>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    signatureEmbeddedLink: state.profile.signatureEmbeddedLink,
    signaturesRequestLoading: state.profile.signaturesRequestLoading
  }
}

export default compose(
  connect(mapStateToProps, { createContract, fetchUser })
)((Form.create({})(ContractSection)));