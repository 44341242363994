import _ from 'lodash';

import {
  CREATE_WITHDRAWAL,
  CANCEL_WITHDRAWAL,
  FETCH_WITHDRAWALS,
  FETCH_USERS_WITHDRAWALS,
  FILTER_USER_WITHDRAWALS_BY_ADDRESS,
  WITHDRAWALS_REQ_IS_LOADING,
  WITHDRAWALS_INFO_REQ_IS_LOADING,
  FETCH_WITHDRAWAL_INFO,
  WITHDRAWALS_SELECTED_COIN,
  FETCH_WITHDRAWAL_ADDRESS,
  CREATE_WITHDRAWAL_ADDRESS,
  CONFIRM_WITHDRAWAL_ADDRESS
} from 'actions/types';

const INITIAL_STATE = {
  adminWithdrawalsList: [],
  withdrawalsList: [],
  withdrawalsRequestLoading: false,
  withdrawalInfoRequestLoading: false,
  selectedWithdrawalCoin: {
    name: undefined,
    fullName: undefined,
  },
  withdrawalInfo: undefined,
  withdrawalsAddress: []
};

function returnFilteredWithdrawal(withdrawalNotFiltered, payload) {
  return withdrawalNotFiltered.address.includes(payload)
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USERS_WITHDRAWALS:
      return { ...state, adminWithdrawalsList: _.mapKeys(action.payload, '_id'),  adminWithdrawalsListNotFiltered: _.mapKeys(action.payload, '_id') };
    case FILTER_USER_WITHDRAWALS_BY_ADDRESS:
      return { ...state, adminWithdrawalsList: _.filter(state.adminWithdrawalsListNotFiltered, withdrawalNotFiltered => returnFilteredWithdrawal(withdrawalNotFiltered, action.payload)) }
    case FETCH_WITHDRAWALS:
      return { ...state, withdrawalsList: _.mapKeys(action.payload, '_id') };
    case FETCH_WITHDRAWAL_ADDRESS:
        return { ...state, withdrawalsAddress: _.mapKeys(action.payload, '_id') };
    case CREATE_WITHDRAWAL:
      return { ...state }
    case CREATE_WITHDRAWAL_ADDRESS:
      return { ...state }
    case CONFIRM_WITHDRAWAL_ADDRESS:
      return { ...state }
    case CANCEL_WITHDRAWAL:
      return { ...state }
    case FETCH_WITHDRAWAL_INFO:
      return { ...state, withdrawalInfo: action.payload};
    case WITHDRAWALS_REQ_IS_LOADING:
      return { ...state, withdrawalsRequestLoading: action.payload }
    case WITHDRAWALS_SELECTED_COIN:
      return { ...state, selectedWithdrawalCoin: action.payload};
    case WITHDRAWALS_INFO_REQ_IS_LOADING:
      return { ...state, withdrawalInfoRequestLoading: action.payload };
    default:
      return state;
  }
}
