import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Row, Col } from 'antd';

import AuthForm from 'components/shared/AuthForm';
import {
  DASHBOARD_URL
} from 'containers/Router/constants';

class SignupPage extends React.Component {
  onSubmit = (formProps) => {
    const params = new URL(window.location.href).searchParams;
    const referralCode = params.get('ref');

    formProps.referralCode = referralCode;

    this.props.signup(formProps, () => {
      this.props.history.push(DASHBOARD_URL);
    });
  };

  render() {
    const { handleSubmit } = this.props;
    
    return (
      <div className="pt-10">
        <h3 className="text-2xl text-white font-semibold text-center">
          <span className="font-light">Create your account</span>
        </h3>

        <Row className="pt-10 w-full md:flex md:justify-center">
          <Col span={21} className="rounded-bg p-6 pt-8 w-full pb-10 md:w-1/3">
            <AuthForm 
              type="signup"
              onSubmit={handleSubmit(this.onSubmit)}
              notification={this.props.notification}
              iconLoading={this.props.authRequestLoading}>
            </AuthForm>
          </Col>
        </Row>
      </div>
    )
  }
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required.";
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required.";
  }

  if (values.password) {
    if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters long."
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return { 
    notification: state.shared.notification,
    authRequestLoading: state.auth.authRequestLoading,
  }
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: 'signup' ,
    validate
  })
)(SignupPage);