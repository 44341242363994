import React, { Component } from "react";
import {connect} from "react-redux";
import moment from "moment";
import Loader from "components/shared/Loader";

class StrikeRate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { tradesStatsData } = this.props.reports
    return (
      <div className="strike-rate-wrapper">
        <div className="strike-rate-wrapper-widgets">
          <div className="row flex flex-wrap strike-rate-elements">
            <div className="strike-rate-element total">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Total Net Profit</div>
                  <div>{ parseFloat(tradesStatsData.totalNetProfitPercent).toFixed(6) } %</div>
                </div>
              }
            </div>

            <div className="strike-rate-element total">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Total Net Profit $</div>
                  <div>{ parseFloat(tradesStatsData.totalNetProfit).toFixed(6) }</div>
                </div>
              }
            </div>

            <div className="strike-rate-element losing">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Average Profit per Month</div>
                  <div>{ parseFloat(tradesStatsData.averageNetProfitPercentagePerMonth).toFixed(6) } %</div>
                </div>
              }
            </div>

            <div className="strike-rate-element winning">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Number of Months Running</div>
                  <div>{ parseFloat(tradesStatsData.numberOfMonthsRunning).toFixed(2) }</div>
                </div>
              }
            </div>

            <div className="strike-rate-element winning">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Number of Trades</div>
                  <div>{ tradesStatsData.numberOfTrades }</div>
                </div>
              }
            </div>

            <div className="strike-rate-element winning">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Average Number of Trades per Day</div>
                  <div>{ parseFloat(tradesStatsData.numberOfTradesPerDay).toFixed(2) }</div>
                </div>
              }
            </div>

            <div className="strike-rate-element losing">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Percentage of Trades Profitable</div>
                  <div>{ parseFloat(tradesStatsData.percentProfitable).toFixed(2) } %</div>
                </div>
              }
            </div>

            <div className="strike-rate-element winning">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Average Profit/Loss per Trade $</div>
                  <div>{ parseFloat(tradesStatsData.averageNetProfitLoss).toFixed(6) }</div>
                </div>
              }
            </div>

            <div className="strike-rate-element winning">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Average Gain on Profitable Trades $</div>
                  <div>{ parseFloat(tradesStatsData.averageGain).toFixed(6) }</div>
                </div>
              }
            </div>

            <div className="strike-rate-element winning">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Average Loss on Lost Trades $</div>
                  <div>{ parseFloat(tradesStatsData.averageLoss).toFixed(6) }</div>
                </div>
              }
            </div>

            <div className="strike-rate-element losing">
              { this.props.reportsRequestLoading ? <Loader/> :
                <div className="strike-rate-element-inner">
                  <div>Profit Factor</div>
                  <div>{ parseFloat(tradesStatsData.profitFactor).toFixed(2) }</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );

    // return (
    //   <div className="strike-rate-wrapper">
    //     <div className="strike-rate-wrapper-widgets">
    //       <div className="row flex flex-wrap strike-rate-elements">
    //         <div className="strike-rate-element total">
    //           { this.props.reportsRequestLoading ? <Loader/> :
    //             <div className="strike-rate-element-inner">
    //               <div>Total trades</div>
    //               <div>{ tradesStatsData.winningTrades + tradesStatsData.losingTrades }</div>
    //             </div>
    //           }
    //         </div>

    //         <div className="strike-rate-element winning">
    //           { this.props.reportsRequestLoading ? <Loader/> :
    //             <div className="strike-rate-element-inner">
    //               <div>Winning trades</div>
    //               <div className="strike-rate-element-value">{tradesStatsData.winningTrades} <span> ( { !isNaN((100 * tradesStatsData.winningTrades / (tradesStatsData.winningTrades + tradesStatsData.losingTrades)).toFixed(2)) ? (100 * tradesStatsData.winningTrades / (tradesStatsData.winningTrades + tradesStatsData.losingTrades)).toFixed(2) : 0.00} % ) </span></div>
    //             </div>
    //           }
    //         </div>

    //         <div className="strike-rate-element losing">
    //           { this.props.reportsRequestLoading ? <Loader/> :
    //             <div className="strike-rate-element-inner">
    //               <div>Losing trades</div>
    //               <div className="strike-rate-element-value">{tradesStatsData.losingTrades} <span> ( {!isNaN((100 * tradesStatsData.losingTrades / (tradesStatsData.winningTrades + tradesStatsData.losingTrades)).toFixed(2)) ? (100 * tradesStatsData.losingTrades / (tradesStatsData.winningTrades + tradesStatsData.losingTrades)).toFixed(2) : 0.00} % ) </span></div>
    //             </div>
    //           }
    //         </div>

    //         <div className="strike-rate-element winning">
    //           { this.props.reportsRequestLoading ? <Loader/> :
    //             <div className="strike-rate-element-inner">
    //               <div>Average winner</div>
    //               <div>{parseFloat(tradesStatsData.averageWinner).toFixed(2)} %</div>
    //             </div>
    //           }
    //         </div>

    //         <div className="strike-rate-element losing">
    //           { this.props.reportsRequestLoading ? <Loader/> :
    //             <div className="strike-rate-element-inner">
    //               <div>Average loser</div>
    //               <div>{parseFloat(tradesStatsData.averageLoser).toFixed(2)} %</div>
    //             </div>
    //           }
    //         </div>

    //         <div className="strike-rate-element winning">
    //           { this.props.reportsRequestLoading ? <Loader/> :
    //             <div className="strike-rate-element-inner">
    //               <div>Winning streak</div>
    //               <div>{ tradesStatsData.winningStreak }</div>
    //             </div>
    //           }
    //         </div>

    //         <div className="strike-rate-element losing">
    //           { this.props.reportsRequestLoading ? <Loader/> :
    //             <div className="strike-rate-element-inner">
    //               <div>Losing streak</div>
    //               <div>{tradesStatsData.losingStreak}</div>
    //             </div>
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

function mapStateToProps(state) {
  return {
    reportsRequestLoading: state.reports.reportsRequestLoading,
  }
}

export default connect(mapStateToProps, { })(StrikeRate);