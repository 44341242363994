import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import requireAuth from 'components/requireAuth';
import { connect } from 'react-redux';
import { clearNotificationMessage, setNotificationMessage } from 'actions/shared';
import { Col, Row, Button, Card, Statistic, Table, Divider } from 'antd';
import { setReferralCode, fetchReferralRewards, fetchReferralStats } from 'actions/referrals';
import ReferralCodeForm from 'components/shared/ReferralCodeForm';
import Loader from "components/shared/Loader";
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Scrollbar from 'react-smooth-scrollbar';

const gridStyle = {
  width: '100%',
  height: "20px",
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px'
};

class InviteFriendsPage extends React.Component {
  componentDidMount() {
    this.props.clearNotificationMessage();
    this.props.fetchReferralStats();
    this.props.fetchReferralRewards();
  }

  onSubmit = (formProps) => {
    this.props.setReferralCode(formProps, function() {
      setTimeout(function() {
        window.location.reload();
      }, 250);
    });
  };

  renderReferredForm() {
    const { handleSubmit } = this.props;

    return (
      <ReferralCodeForm 
        onSubmit={handleSubmit(this.onSubmit)}
        notification={this.props.notification}
        iconLoading={this.props.referralsRequestLoading}>
      </ReferralCodeForm>
    )
  }

  renderAmountElement = (size, currency) => {
    return (
      <div className="flex">
        {size}
        <div className="ml-2 font-semibold">{currency}</div>
      </div>
    )
  }

  renderTradesTable() {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, record) => value ? value : '-'
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        render: (value, record) => value ? value : '-'
      }
    ];
    
    return (
      <Row>
        <div className="my-6 mb-6">
          <h3 className="title-underline font-semibold">Latest Commission History</h3>
          <Scrollbar>
            <Table 
              rowClassName="table-row"
              dataSource={this.props.referralRewards} 
              columns={columns}
              pagination={{
                defaultPageSize: 4,
                showSizeChanger: true,
                pageSizeOptions: ['4', '10', '20'],
              }}
            />
          </Scrollbar>
        </div>
      </Row>
    )
  }

  renderReferredContent() {
    const { auth, referralStats } = this.props;
    const { user } = auth;
    const referralLink = `${window.location.origin}/?ref=${user.referralCode}`;
    const handleFocus = (event) => event.target.select();
    const Input = (props) => <input className="referral-input" type="text" value={referralLink} onFocus={handleFocus} />
    return (
      <>
        <Row className="mb-6 md:flex">
          <Col span={24} className="flex items-end justify-between md:max-w-md">
            <Card className="w-full">
              <Card.Grid hoverable={false} style={gridStyle}>
                <div className="flex flex-col md:flex-row justify-between w-full">
                  <div>Your referral link</div>
                  <div>Comission rate: <span className="font-semibold">20%</span></div>
                </div>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle} className="py-6">
                <Input/>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle} className="py-8">
                <CopyToClipboard text={`${referralLink}`}
                  onCopy={() => this.props.setNotificationMessage({title: `Referral link`, message: "Copied to clipboard", icon: "success"})}>
                  <Button type="primary" className="w-full">Copy link</Button>
                </CopyToClipboard>
              </Card.Grid>
            </Card>
          </Col>

          <Col span={24} className="mt-8 md:mt-0 md:max-w-xs md:ml-auto">
            <Card size="small">
              <Statistic
                className="text-center font-semibold"
                title="Referrals"
                value={referralStats}
                valueStyle={{ color: '#3f8600', fontSize: '30px', fontWeight: '500' }}
              />
            </Card>
          </Col>
        </Row>

        {this.renderTradesTable()}

        <Divider>
          <div>Program details</div>
        </Divider>

        <Row className="md:flex">
          <Col span={24} className="md:max-w-5xl md:mx-auto">
            <div>
              <ul>
                <li><b className="mr-2">1.</b> Create a referral link (above)</li>
                <li><b className="mr-2">2.</b> Share this link with your friends.</li>
                <li><b className="mr-2">3.</b> The commission you receive from the referral program is set at <b>20%</b>.</li>
                <li>
                  <b className="mr-2">4.</b>
                  The fee commission will be sent instantly in real-time to your account as your referee completes each trade and will be 
                  paid to you in the exact token the original fee was paid in (e.g. <b>CYL</b>, <b>ETH</b>, or <b>USDT</b>).
                </li>
                <li>
                  <b className="mr-2">5.</b>
                  There is no limit to the number of friends you can refer, although we do reserve the right to adjust or change the referral 
                  program rules at any time.
                </li>
                <li>
                  <b className="mr-2">6.</b>
                  Each referee must be signed up through your Referral Link.
                </li>
                <li>
                  <b className="mr-2">7.</b>
                  <b>Crystal Bot</b> will check for duplicate or fake accounts and will not pay out referral bonuses on these accounts. Duplicate or shared finances will result in disqualification.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </>
    )
  }

  render() {
    const { auth, referralsRequestLoading } = this.props;
    const { user } = auth;

    if (referralsRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          {!user.referralCode && this.renderReferredForm()}
          {user.referralCode && this.renderReferredContent()}
        </React.Fragment>
      )
    }
  };
}

const validate = values => {
  const errors = {};

  if (!values.code) {
    errors.code = "Referral code required.";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    referralRewards: state.referrals.referralRewards,
    referralStats: state.referrals.referralStats,
    referralsRequestLoading: state.referrals.referralsRequestLoading
  }
}

export default compose(
  requireAuth,
  connect(mapStateToProps, { setNotificationMessage, clearNotificationMessage, setReferralCode, fetchReferralRewards, fetchReferralStats}),
  reduxForm({
    form: 'referrals' ,
    validate
  })
)(InviteFriendsPage);