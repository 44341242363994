import React from 'react'
import csvDownload from 'json-to-csv-export'
import _ from 'lodash';
import moment from 'moment';
import { pointFormatter } from "utils";
import { Icon, Button } from 'antd';

const CsvDownload = props => {
  const { data, ...others } = props
  const filename = `cyl-platform-trades-${moment().format('DD.m.YYYY.HH.hh')}.csv`;
  let tradesTable = [];

  _.map(data, function(trade) {
    let tradeObjectToExport = {};
    
    if(trade.pair){
      tradeObjectToExport["Exchange"] = trade.accountMarket;
      tradeObjectToExport["Pair"] = trade.pair;
      tradeObjectToExport["Date Opened"] = moment(trade.entryDatetime).format('ll HH:mm');
      tradeObjectToExport["Date Closed"] = moment(trade.exitDatetime).format('ll HH:mm');
      tradeObjectToExport["Size"] = `${Number(trade.size).toFixed(8)} ${trade.currency}`;
      tradeObjectToExport["Avg. Entry"] = Number(trade.averageEntryPrice).toFixed(8);
      tradeObjectToExport["Avg. Exit"] = Number(trade.averageExitPrice).toFixed(8);
      tradeObjectToExport["P/L %"] = `${Number(trade.profitPercentage).toFixed(2)}%`;
      tradeObjectToExport["P/L $"] = Number(trade.profitUsd).toFixed(4); //pointFormatter(Number(trade.profitUsd), 4);
      tradeObjectToExport["Fee"] = trade.fee ? `${Number(trade.fee.amount).toFixed(8)} ${trade.fee.currency}` : '';
      tradeObjectToExport["Fee $"] = trade.fee ? Number(trade.fee.amountUsd).toFixed(4) : ''; //pointFormatter(Number(trade.fee.amountUsd), 4);
      tradeObjectToExport["Net P/L $"] = trade.netProfitUsd ? Number(trade.netProfitUsd).toFixed(4) : ''; //pointFormatter(Number(trade.netProfitUsd), 4);
      tradeObjectToExport["Status"] = (trade.status).charAt(0).toUpperCase() + (trade.status).slice(1);

    }
    else
    {
      
      tradeObjectToExport["Time"] = moment(trade.createdAt).format('ll HH:mm');
      tradeObjectToExport["Amount"] = trade.amount ? `${Number(trade.amount).toFixed(8)} ${trade.currency}` : '';
      tradeObjectToExport["BlockchainRecord"] = (trade.transactionId) ? `https://etherscan.io/tx/`+(trade.transactionId) : '';
      tradeObjectToExport["Status"] = (trade.status).charAt(0).toUpperCase() + (trade.status).slice(1);
      if(trade.fee)
         {
          tradeObjectToExport["Time"] = moment(trade.createdAt).format('ll HH:mm');
          tradeObjectToExport["Amount"] = trade.amount ? `${Number(trade.amount).toFixed(8)} ${trade.currency}` : '';
          tradeObjectToExport["WalletAddress"] = (trade.address) ? (trade.address) : '';
          tradeObjectToExport["BlockchainRecord"] = (trade.transactionId) ? `https://etherscan.io/tx/`+(trade.transactionId) : '';
          tradeObjectToExport["Status"] = (trade.status).charAt(0).toUpperCase() + (trade.status).slice(1);
          tradeObjectToExport["Fee $"] = trade.fee ? Number(trade.fee).toFixed(4) : '';

         }
      
      
    }
    
    
    tradesTable.push(tradeObjectToExport);
  })

  return (
    <div className="flex justify-end">
      <Button type="primary" className="mb-3 text-sm flex justify-end items-center cursor-pointer" onClick={() => csvDownload(tradesTable, filename)} {...others}>
        <Icon type="download" style={{fontSize: "16px"}}/>
        {props.children || 'Download data'}
      </Button>
    </div>
  )
}
export default CsvDownload