import referrals from 'apis/referrals';

import { 
  SET_REFERRAL_CODE,
  GET_REFERRAL_STATS,
  GET_REFERRAL_REWARDS,
  REFERRALS_REQ_IS_LOADING,
  SET_NOTIFICATION_MESSAGE
} from 'actions/types';

export const setReferralCode = (formProps, callback) => async (dispatch, getState) => {
  try {
    referrals.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: REFERRALS_REQ_IS_LOADING, payload: true });
    const response = await referrals.post('/createCode', formProps );

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: SET_REFERRAL_CODE, payload: response.data.message })
    dispatch({ type: REFERRALS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: REFERRALS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const fetchReferralStats = () => async (dispatch, getState) => {
  referrals.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: REFERRALS_REQ_IS_LOADING, payload: true });
  const response = await referrals.get('/stats');

  dispatch({ type: GET_REFERRAL_STATS, payload: response.data.result })

  dispatch({ type: REFERRALS_REQ_IS_LOADING, payload: false });
};

export const fetchReferralRewards = () => async (dispatch, getState) => {
  referrals.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: REFERRALS_REQ_IS_LOADING, payload: true });
  const response = await referrals.get('/rewards');

  dispatch({ type: GET_REFERRAL_REWARDS, payload: response.data.result })

  dispatch({ type: REFERRALS_REQ_IS_LOADING, payload: false });
};