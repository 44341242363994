import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "config";

const DELAY = 0;

class Recaptcha extends React.Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false"
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
  }

  handleChange = value => {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };

  render() {
    const { value, callback, load, expired } = this.state || {};
    return (
      <div className="recaptcha">
        {load && (
          <ReCAPTCHA
            className="g-recaptcha"
            style={{ display: "flex" }}
            theme="light"
            ref={this._reCaptchaRef}
            sitekey={config.RECAPTCHA}
            onChange={this.props.input.onChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}
      </div>
    );
  }
}

export default Recaptcha;
