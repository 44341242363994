import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Row, Col, Icon, Select, Card, Popover, Input} from 'antd';

import CoinIcon from 'components/shared/CoinIcon';
import CYLIcon from 'images/coins/cyl.png';
import ETHIcon from 'images/coins/eth.png';
import USDTIcon from 'images/coins/usdt.png';
import BTCIcon from 'images/coins/btc.png';

import { setNotificationMessage, clearNotificationMessage } from 'actions/shared';
import { selectDepositCoin } from 'actions/deposits';
import { fetchDepositAddress } from 'actions/deposits';

import Loader from "components/shared/Loader";

import ContractSection from 'components/shared/ProfileWidget/ContractSection'

const QRCode = require('qrcode.react');
const { Option } = Select;

let coinIcons = {
  "ETH": ETHIcon,
  "USDT": USDTIcon,
  "CYL": CYLIcon,
  "BTC": BTCIcon
}

const gridStyle = {
  width: '100%',
  height: "20px",
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px'
};

class DepositForm extends React.Component {
  handleChange = (value) => {
    let selectedCoinObject = {};

    switch (value) {
      case 'ETH':
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum"
        break;
      case 'USDT':
        selectedCoinObject.name = "USDT";
        selectedCoinObject.fullName = "Tether"
        break;
      case 'CYL':
        selectedCoinObject.name = "CYL";
        selectedCoinObject.fullName = "Crystal Token"
        break;
      case 'BTC':
        selectedCoinObject.name = "BTC";
        selectedCoinObject.fullName = "Bitcoin"
        break;
      default:
        selectedCoinObject.name = "ETH";
        selectedCoinObject.fullName = "Ethereum"
        break;
    }

    this.props.selectDepositCoin(selectedCoinObject);
    this.props.fetchDepositAddress(value, () => {});
  }

  generateCardDetails = () => {
    const { user } = this.props.auth;

    return (
      <>
        <Card>
          <Card.Grid hoverable={false} style={gridStyle}>
            <img alt="" className="w-5 mr-2" src={coinIcons[this.props.selectedDepositCoin.name]}></img>
            <div>
              <span className="font-semibold mr-2">{(this.props.selectedDepositCoin.name)}</span>
              <span className="font-xs opacity-75 mr-2">{this.props.selectedDepositCoin.fullName}</span>
              Wallet address:
            </div>
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <input className="font-semibold w-full text-input" value={this.props.depositAddress}></input>
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <div className="flex ml-auto">
              <CopyToClipboard text={`${this.props.depositAddress}`} className="mr-3 hover:text-crystal-primary"
                onCopy={() => this.props.setNotificationMessage({title: `${(this.props.selectedDepositCoin.name).toUpperCase()} Address`, message: "Copied to clipboard", icon: "success"})}>
                <Icon className="cursor-pointer hover:text-crystal-primary" type="copy" style={{fontSize: '16px'}}></Icon>
              </CopyToClipboard>
              <Popover content={<QRCode value={this.props.depositAddress} />}>
                <Icon className="cursor-pointer"type="qrcode" style={{fontSize: '16px'}}></Icon>
              </Popover>
            </div>
          </Card.Grid>
        </Card>
      </>
    )
  }

  render() {
    const { onBoarding } = this.props;

    return (
      <React.Fragment>
        <Row className={this.props.className}>
          <Col span={24} className={`${onBoarding ? "md:w-full" : "md:max-w-sm"}`}>
            <Select
              size={"large"}
              className="w-full h-10 mb-5"
              placeholder="Select a coin"
              defaultValue={this.props.selectedDepositCoin.name}
              onChange={this.handleChange}>

              <Option value="CYL">
                <CoinIcon icon={CYLIcon} coin="CYL"/>
              </Option>

              <Option value="ETH">
                <CoinIcon icon={ETHIcon} coin="ETH"/>
              </Option>

              <Option value="USDT">
                <CoinIcon icon={USDTIcon} coin="USDT (ERC-20)"/>
              </Option>

              {/* <Option value="BTC">
                <CoinIcon icon={BTCIcon} coin="BTC"/>
              </Option> */}
            </Select>
            {this.props.depositsAddressRequestLoading ? <Loader fontSize="24"/> : ""}
            {this.props.depositAddress && !this.props.depositsAddressRequestLoading ? this.generateCardDetails() : ""}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    selectedDepositCoin: state.deposits.selectedDepositCoin,
    depositsAddressRequestLoading: state.deposits.depositsAddressRequestLoading,
    depositAddress: state.deposits.depositAddress
  }
};

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = "Current password required.";
  }

  if (!values.new_password) {
    errors.new_password = "New password required.";
  }

  if (!values.confirm_new_password) {
    errors.confirm_new_password = "Confirm new password required.";
  }

  return errors;
};

export default compose(
  connect(mapStateToProps, { setNotificationMessage, clearNotificationMessage, selectDepositCoin, fetchDepositAddress } ),
  reduxForm({
    form: 'deposit' ,
    validate
  })
)(DepositForm);