import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'actions/exchanges';
import ExchangeForm from 'components/shared/ExchangeForm';
import { Button, Select } from 'antd';

import ExchangeIcon from 'components/shared/ExchangeIcon';
import binanceLogoSimple from 'images/icons/binance.svg';
import bybitLogoSimple from 'images/icons/bybit.svg';

import {
  EXCHANGES_PAGE_URL
} from 'containers/Router/constants';

const { Option } = Select;

class ConnectBinanceAccount extends React.Component {
  state = {
    selectedExchange: 'binance'
  }

  componentDidMount() {
    this.props.fetchExchanges();
  }

  handleNextStep = () => {
    const { currentStep } = this.props;
    localStorage.setItem(`step-${currentStep}`, 'done');
    this.props.nextStep();
  }

  handleExchangeChange = (value) => {
    switch (value) {
      case 'binance':
        this.setState({
          selectedExchange: 'binance'
        })
        break;
      case 'binance_us':
        this.setState({
          selectedExchange: 'binance_us'
        })
        break;
      case 'bybit':
        this.setState({
          selectedExchange: 'bybit'
        })
        break;
      default:
    }
  }

  renderCreateExchange() {
    const { exchangesRequestLoading, exchanges } = this.props;

    return (
      <div className="step-content">
        {
          exchanges.length < 1
          ?
          <>
            <div className="mb-2">Select an exchange</div>
            <Select
              size={"large"}
              className="w-full h-10 mb-6"
              placeholder="Select an exchange you want to connect"
              onChange={this.handleExchangeChange}
              defaultValue={this.state.selectedExchange}>

              <Option value="binance">
                <ExchangeIcon label="Binance" icon={binanceLogoSimple} coin="binance"/>
              </Option>

              <Option value="binance_us">
                <ExchangeIcon label="Binance US" icon={binanceLogoSimple} coin="binance_us"/>
              </Option>

              <Option value="bybit">
                <ExchangeIcon label="Bybit" icon={bybitLogoSimple} coin="bybit"/>
              </Option>
            </Select>
            <ExchangeForm
              type="create"
              exchange={this.state.selectedExchange}
              exchangeAdded={this.handleNextStep}
              iconLoading={exchangesRequestLoading}>
            </ExchangeForm>
          </>
          :
          <div>Exchange added. See all <a href={EXCHANGES_PAGE_URL}><b>exchanges</b></a>.</div>
        }
        <div className="flex">
          <Button size="large" className="step-btn w-full mt-4" onClick={this.handleNextStep}>Continue</Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderCreateExchange()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    exchanges: Object.values(state.exchanges.exchangesList),
    exchangesRequestLoading: state.exchanges.exchangesRequestLoading
  }
};

export default compose(
  connect(mapStateToProps, actions),
)(ConnectBinanceAccount);
