import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import { Row } from 'antd';
import AdminFetchReportsForm from 'components/shared/AdminFetchReportsForm';
import Loader from "components/shared/Loader";
import EquityGraph from 'components/shared/Statistics/equityGraph';
import StrikeRate from 'components/shared/Statistics/strikeRate';
import ReportPie from 'components/shared/Statistics/reportPie';
import TradeHistory from 'components/shared/Statistics/tradeHistory';
import exist from 'exist.js';
import { Icon } from 'antd';
import { fetchAdminUserReports, fetchAdminUserTrades, fetchAdminEquityGraphHistory } from 'actions/admin';

class AdminReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: this.props.searchedUsersList[0] || ''
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderUserReports()}
      </React.Fragment>
    )
  }

  fetchUserReports = (form, e) => {
    e.preventDefault();

    form.validateFields((error, values) => {
      if (error) {
        console.log(error);
      } else {

        this.setState({
          userEmail: values.userEmail
        })

        this.props.fetchAdminUserReports(values.userEmail);
        this.props.fetchAdminUserTrades(values.userEmail);
        this.props.fetchAdminEquityGraphHistory(values.userEmail, 9999);
      }
    });
  }

  componentWillUnmount() {
    this.props.fetchAdminUserReports('');
  }

  renderUserReports() {
    return (
      <Row className="fade-in">
        <AdminFetchReportsForm onSubmit={this.fetchUserReports}/>

        {this.props.adminReportsRequestLoading
        ?
        <div className="mt-56"><Loader/></div>
        :
        this.renderReportsPageContent()
        }

      </Row>
    )
  }

  renderReportsPageContent() {
    const { reportsStats, equityHistory } = this.props;

    const pieChartDataExists = exist.get(reportsStats, 'pieChartData');

    if (pieChartDataExists && pieChartDataExists.length > 0) {
      return (
        <>
          <EquityGraph history={equityHistory} fetchEquityHistory={this.props.fetchAdminEquityGraphHistory} userEmail={this.state.userEmail}/>
          <div className="page-wrapper trades-page-wrapper reports-widget-wrapper mt-20">
            <div className="reports-widget portfolio-widget">
              <div className="report-widget-body">
                <ReportPie reports={reportsStats}/>
              </div>
            </div>

            <div className="reports-widget strike-widget">
              <StrikeRate reports={reportsStats}/>
            </div>

            <div className="reports-widget history-widget">
              <div className="">
                {this.renderTradesContent()}
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <div className="text-center mt-48">
          <Icon type="info-circle" style={{fontSize: "54px", color: "#e8e8e8"}} />

          <div style={{ marginTop: "10px", color: "#00000040" }}>
            Not enough data available
          </div>
        </div>
      )
    }
  };

  renderTradesContent() {
    if (this.props.adminTradesRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <TradeHistory trades={this.props.trades}/>
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    trades: Object.values(state.admin.adminTradesList),
    searchedUsersList: state.admin.searchedUsersList,
    adminReportsRequestLoading: state.admin.adminReportsRequestLoading,
    adminTradesRequestLoading: state.admin.adminTradesRequestLoading,
    reportsStats: state.admin.stats,
    equityHistory: state.admin.equityHistory
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, { fetchAdminEquityGraphHistory, fetchAdminUserReports, fetchAdminUserTrades }),
)(AdminReports);
