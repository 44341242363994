import React from 'react';
import requireAuth from 'components/requireAuth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { clearNotificationMessage } from 'actions/shared';
import { fetchTrades } from 'actions/trades';
import { fetchMainReportsTrades } from 'actions/admin';
import TradeHistory from 'components/shared/Statistics/tradeHistory';
import Loader from "components/shared/Loader";

class AdminMainReportsPage extends React.Component {
  componentDidMount() {
    this.props.clearNotificationMessage();
    // TODO: fetchTrades should be removed after mainReports API is finished
    this.props.fetchTrades();
    this.props.fetchMainReportsTrades();
  }

  renderTradesContent() {
    if (this.props.tradesRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          {/* TODO: this.props.mainReportsList when mainReports API is finished */}
          <TradeHistory trades={this.props.trades} noDownload/>
        </React.Fragment>
      )
    }
  }

  renderReportsPageContent() {
    return (
      <>
        <div className="page-wrapper trades-page-wrapper reports-widget-wrapper">
          <div className="reports-widget history-widget">
            <div className="">
              {this.renderTradesContent()}
            </div>
          </div>
        </div>
      </>
    )
  };

  render() {
    return (
      <React.Fragment>
        {this.renderReportsPageContent()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    trades: Object.values(state.trades.tradesList),
    tradesRequestLoading: state.trades.tradesRequestLoading,
    mainReports: Object.values(state.admin.mainReportsList)
  }
}

export default compose(
  requireAuth,
  connect(mapStateToProps, { clearNotificationMessage, fetchTrades, fetchMainReportsTrades })
)(AdminMainReportsPage);