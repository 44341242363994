import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button } from 'antd';
import DepositForm from 'components/shared/DepositForm';

import { selectDepositCoin } from 'actions/deposits';
import { fetchDepositAddress } from 'actions/deposits';

class DepositFunds extends React.Component {
  componentDidMount() {
    let selectedCoinObject = {};
    selectedCoinObject.name = "CYL";
    selectedCoinObject.fullName = "Crystal Token"

    this.props.selectDepositCoin(selectedCoinObject);
    this.props.fetchDepositAddress('cyl', () => {});
  }

  handleNextStep = () => {
    const { currentStep } = this.props;
    localStorage.setItem(`step-${currentStep}`, 'done');
    this.props.nextStep();
  }

  render() {
    return (
      <div className="step-content">
        <DepositForm selected="CYL" onBoarding/>
        <div className="flex">
          <Button size="large" className="step-btn w-full mt-4" onClick={this.props.previousStep}>Back</Button>
          <Button size="large" className="step-btn w-full mt-4 ml-4" onClick={this.handleNextStep}>Continue</Button>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(null, { selectDepositCoin, fetchDepositAddress } ),
)(DepositFunds);