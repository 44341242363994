import _ from 'lodash';

import { 
  FETCH_EXCHANGES,
  CREATE_EXCHANGE,
  REMOVE_EXCHANGE,
  EXCHANGES_REQ_IS_LOADING
} from 'actions/types';

const INITIAL_STATE = {
  exchangesList: [],
  exchangesRequestLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) { 
    case FETCH_EXCHANGES:
      return { ...state, exchangesList: _.mapKeys(action.payload, 'accountId') };
    case CREATE_EXCHANGE:
      return { ...state }
    case REMOVE_EXCHANGE:
      return { ...state, exchangesList: _.omit(state.exchangesList, action.payload)};
    case EXCHANGES_REQ_IS_LOADING:
      return { ...state, exchangesRequestLoading: action.payload }
    default:
      return state;
  }
}