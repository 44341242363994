/**
 *
 * LineIndicator
 *
 */

import React from 'react';

class LineIndicator extends React.Component {
  render() {
    let widthPercentage = Number.parseInt(this.props.percent, 10) * 2.8;
    return (
      <div>
        <div className="ant-row-flex">
          <p className="vx-mr-1">{this.props.currency}</p>
          {this.props.currency !== "Others" ? <p className="vx-text-grey">| {this.props.value}</p> : ""}
        </div>
        <div className="vx-line-indi-info">
          <div className={`vx-line-indi vx-bg-${this.props.color}`} style={{
            width: widthPercentage > 10 ? widthPercentage : 10
          }}/>
          <span className="vx-line-indi-count vx-ml-2">{this.props.percent}%</span>
        </div>
      </div>
    );
  }
}

export default LineIndicator;
