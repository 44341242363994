import React from 'react';
import { Form, Upload, message, Icon, Checkbox, Select, Input } from 'antd';

import Button from './components/button';
import Field from './components/field';
import Label from './components/label';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html'

import ExchangeIcon from 'components/shared/ExchangeIcon';
import binanceLogoSimple from 'images/icons/binance.svg';
import bybitLogoSimple from 'images/icons/bybit.svg';

import * as actions from 'actions/traders';

import { compose } from 'redux';
import { connect } from 'react-redux';

const { Option } = Select;
const FormItem = Form.Item;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class TraderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overviewState: EditorState.createEmpty(),
      detailsState: EditorState.createEmpty(),
      loading: false,
      selectedExchange: '',
      profilePicture: '',
      traderEnabled: false,
      name: '',
      supportEmail: '',
      cost: '',
      publicKey: '',
      secretKey: '',
    }
  }

  componentDidMount() {
    const { traderId } = this.props;
    if (traderId) {
      this.props.fetchTrader(traderId, () => {
        const { trader } = this.props;
        this.setState({
          name: trader.name,
          supportEmail: trader.supportEmail,
          cost: trader.cost,
          selectedExchange: trader.exchange,
          traderEnabled: trader.traderEnabled,
          overviewState: EditorState.createWithContent(stateFromHTML(trader.overview)),
          detailsState: EditorState.createWithContent(stateFromHTML(trader.details)),
          publicKey: trader.publicKey,
          secretKey: trader.secretKey,
        })
      });
    }
  }

  onOverviewStateChange = (overviewState) => {
    this.setState({
      overviewState,
    }, () => {
      // this.validate()
    });
  };

  onDetailsStateChange = (detailsState) => {
    this.setState({
      detailsState,
    }, () => {
      // this.validate()
    });
  };

  renderFormButton = () => {
    const { traderId } = this.props;
    return (
      <div className="mt-4">
        <Button
          name={traderId ? 'Save edit' : 'Add trader'}
          icon="login"
          iconLoading={this.props.iconLoading}
        />
      </div>
    )
  }

  handleInputChange(e) {
    if (e.file) {
      if (e.file.status === 'uploading') {
        this.setState({ loading: true });
        return;
      }
      if (e.file.status === 'done' || e.file.status === 'error') {
        // Get this url from response in real world.
        getBase64(e.file.originFileObj, imageUrl =>
          this.setState({
            profilePicture: imageUrl,
            loading: false,
          }),
        );
      }
    } else {
      if (typeof e.target !== 'undefined') {
        if (e.target.type === 'checkbox') {
          this.setState({
            traderEnabled: e.target.checked
          }, () => {
            // this.validate()
          })
        } else {
          this.setState({
            [e.target.name]: e.target.value
          }, () => {
            // this.validate()
          })
        }
      } else {
        this.setState({
          selectedExchange: e
        }, () => {
          // this.validate()
        })
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.validate();

    const payload = {
      overview: draftToHtml(convertToRaw(this.state.overviewState.getCurrentContent())),
      details: draftToHtml(convertToRaw(this.state.detailsState.getCurrentContent())),
      exchange: this.state.selectedExchange,
      // profilePicture: this.state.profilePicture,
      traderEnabled: this.state.traderEnabled,
      name: this.state.name,
      supportEmail: this.state.supportEmail,
      cost: this.state.cost,
      publicKey: this.state.publicKey,
      secretKey: this.state.secretKey,
    }

    this.props.handleSubmit(payload);

    // let detailsContent = draftToHtml(convertToRaw(this.state.detailsState.getCurrentContent()));
  };

  validate = () => {
    if (this.state.selectedExchange === '') {
      this.setState({
        hasError: true,
        selectedExchangeError: 'Please select an exchange.'
      })
    } else { this.setState({selectedExchangeError: ''}) }

    if (this.state.name === '') {
      this.setState({
        hasError: true,
        nameError: 'Please write a trader name.'
      })
    } else { this.setState({nameError: ''}) }

    if (this.state.supportEmail === '') {
      this.setState({
        hasError: true,
        supportEmailError: 'Please write a support email.'
      })
    } else { this.setState({supportEmailError: ''}) }

    if (this.state.cost === '') {
      this.setState({
        hasError: true,
        costError: 'Please write a cost.'
      })
    } else { this.setState({costError: ''}) }

    if (!this.state.detailsState.getCurrentContent().hasText()) {
      this.setState({
        hasError: true,
        detailsStateError: 'Details can not be empty.'
      })
    } else { this.setState({detailsStateError: ''}) }

    if (!this.state.overviewState.getCurrentContent().hasText()) {
      this.setState({
        hasError: true,
        overviewStateError: 'Overview can not be empty.'
      })
    } else { this.setState({overviewStateError: ''}) }

    if (this.state.publicKey === '') {
      this.setState({
        hasError: true,
        publicKeyError: 'Please write a public key.'
      })
    } else { this.setState({publicKeyError: ''}) }

    if (this.state.secretKey === '') {
      this.setState({
        hasError: true,
        secretKeyError: 'Please write a secret key.'
      })
    } else { this.setState({secretKeyError: ''}) }
  }

  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? 'Loading...' : <div style={{fontSize: "20px"}}><Icon type="plus"/></div>}
      </div>
    );
    const { profilePicture } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <div className="flex flex-col mr-10 w-full">
          <Label name="Select an exchange"/>
          <Select
            size={"large"}
            className="w-full h-10"
            placeholder="Trader exchange"
            name="selectedExchange"
            onChange={(e) => this.handleInputChange(e)}
            value={this.state.selectedExchange}>

            <Option value="binance">
              <ExchangeIcon label="Binance" icon={binanceLogoSimple} coin="binance"/>
            </Option>

            <Option value="binance_us">
              <ExchangeIcon label="Binance US" icon={binanceLogoSimple} coin="binance_us"/>
            </Option>

            <Option value="bybit">
              <ExchangeIcon label="Bybit" icon={bybitLogoSimple} coin="bybit"/>
            </Option>
          </Select>
          <div className="ant-form-item-control has-error mt-2 mb-4">
            {this.state.hasError && <div className="ant-form-explain">{this.state.selectedExchangeError}</div>}
          </div>
        </div>

        <div className="flex flex-col mr-10 w-full">
          <Label name="Profile picture"/>
          <Upload
            name="profilePicture"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={''}
            beforeUpload={beforeUpload}
            onChange={(e) => this.handleInputChange(e)}
          >
            {profilePicture ? <img src={profilePicture} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
          <div className="ant-form-item-control has-error mt-2 mb-2">
            {this.state.hasError && <div className="ant-form-explain">{this.state.profilePictureError}</div>}
          </div>
        </div>

        <div className="flex flex-col w-full mb-6">
          <Checkbox checked={this.state.traderEnabled} onChange={(e) => this.handleInputChange(e)}>Is trader enabled?</Checkbox>
        </div>

        <div className="flex flex-col mr-10 w-full">
          <Label name="Name"/>
          <Input type="text" name="name" onChange={(e) => this.handleInputChange(e)} value={this.state.name}/>
          <div className="ant-form-item-control has-error mt-2 mb-4">
            {this.state.hasError && <div className="ant-form-explain">{this.state.nameError}</div>}
          </div>
        </div>

        <div className="flex flex-col w-full">
          <Label name="Support email"/>
          <Input type="email" name="supportEmail" onChange={(e) => this.handleInputChange(e)} value={this.state.supportEmail}/>
          <div className="ant-form-item-control has-error mt-2 mb-4">
            {this.state.hasError && <div className="ant-form-explain">{this.state.supportEmailError}</div>}
          </div>
        </div>

        <div className="flex flex-col">
          <Label name="Cost"/>
          <Input type="text" name="cost" onChange={(e) => this.handleInputChange(e)} value={this.state.cost}/>
          <div className="ant-form-item-control has-error mt-2 mb-4">
            {this.state.hasError && <div className="ant-form-explain">{this.state.costError}</div>}
          </div>
        </div>

        <div className="flex flex-col">
          <Label name="Public key"/>
          <Input type="text" name="publicKey" onChange={(e) => this.handleInputChange(e)} value={this.state.publicKey}/>
          <div className="ant-form-item-control has-error mt-2 mb-4">
            {this.state.hasError && <div className="ant-form-explain">{this.state.publicKeyError}</div>}
          </div>
        </div>

        <div className="flex flex-col">
          <Label name="Secret key"/>
          <Input type="password" name="secretKey" onChange={(e) => this.handleInputChange(e)} value={this.state.secretKey}/>
          <div className="ant-form-item-control has-error mt-2 mb-4">
            {this.state.hasError && <div className="ant-form-explain">{this.state.secretKeyError}</div>}
          </div>
        </div>

        <div className="trader-editor">
          <div className="mb-10">
            <div className="mb-2">Details</div>
            <Editor
              editorState={this.state.detailsState}
              toolbar={{
                fontFamily: {
                  options: ['Open Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                }
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-content"
              onEditorStateChange={this.onDetailsStateChange}
            />
            <div className="ant-form-item-control has-error mt-2 mb-4">
              {this.state.hasError && <div className="ant-form-explain">{this.state.detailsStateError}</div>}
            </div>
          </div>

          <div>
            <div className="mb-2">Overview</div>
            <Editor
              editorState={this.state.overviewState}
              toolbar={{
                fontFamily: {
                  options: ['Open Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                }
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-content"
              onEditorStateChange={this.onOverviewStateChange}
            />
            <div className="ant-form-item-control has-error mt-2 mb-4">
              {this.state.hasError && <div className="ant-form-explain">{this.state.overviewStateError}</div>}
            </div>
          </div>
        </div>

        {this.renderFormButton()}
      </Form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    traders: Object.values(state.traders.tradersList),
    trader: state.traders.trader,
    tradesRequestLoading: state.traders.tradesRequestLoading
  }
};

export default compose(
  connect(mapStateToProps, actions),
)(TraderForm);