import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Row, Col } from 'antd';

import {
  SIGNIN_URL
} from 'containers/Router/constants';

import ForgotPasswordForm from 'components/shared/ForgotPasswordForm';

class ForgotPasswordPage extends React.Component {
  onSubmit = (formProps) => {
    this.props.forgotPassword(formProps);
  };

  render() {
    const { handleSubmit } = this.props;
    
    return (
      <div className="pt-10">
        <h3 className="text-2xl text-white font-semibold text-center">
          <span className="font-light">Forgot your</span> password?
        </h3>

        <Row className="pt-10 w-full md:flex md:justify-center">
          <Col span={21} className="rounded-bg p-6 pt-8 w-full pb-8 md:w-1/3">
            <ForgotPasswordForm 
              onSubmit={handleSubmit(this.onSubmit)}
              notification={this.props.notification}
              iconLoading={this.props.authRequestLoading}>
            </ForgotPasswordForm>
            <div className="mt-8 flex justify-end">
              <Link to={SIGNIN_URL}>Sign in</Link>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required.";
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required.";
  }

  if (values.password) {
    if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters long."
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return { 
    notification: state.shared.notification,
    authRequestLoading: state.auth.authRequestLoading,
    authenticated: state.auth.authenticated
  }
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: 'forgotPassword' ,
    validate
  })
)(ForgotPasswordPage);