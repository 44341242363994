import React from 'react';
import { Icon } from 'antd';

class EmptyState extends React.Component {
  componentDidMount() {
  }

  render() {
    return <div className="w-full h-full flex justify-center items-center" style={{minHeight: this.props.minHeight}}>
      <div style={{textAlign: "center", position: "absolute", top: "50%", transform: "translateY(-50%)"}}>
        <Icon type="info-circle" style={{fontSize: "54px", color: "#e8e8e8"}} />

        <div style={{ marginTop: "10px", color: "#00000040" }}>
          {this.props.message || 'Not enough data available'}
        </div>
      </div>
    </div>
  }
};

export default EmptyState;