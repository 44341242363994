import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { verifyEmail } from 'actions';
import { Button } from 'antd';

import Loader from "components/shared/Loader";

class ConfirmEmailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tokenValid: true
    }
  }

  componentDidMount() {
    const { userId, token } = this.props.match.params;
    const _this = this;

    this.props.verifyEmail({ userId, token }, function(response, hasError) {
      if (!hasError) {
        _this.setState({
          loading: false,
          tokenValid: false
        })
      } else {
        _this.setState({
          loading: false,
          tokenValid: true
        })
      }
    })
  }

  renderPageContent() {
    const { tokenValid } = this.state;

    return (
      <div className="mt-32 flex justify-center items-center fade-in">
        <h3 className="text-2xl text-white font-semibold text-center">
          <div className="flex flex-col">
            <span className="">
              { tokenValid && "Account activation failed"}
              { !tokenValid && "Account activated"}
            </span>
            <div className="text-xl mt-4 font-light">
              { !tokenValid && "Congratulations! You have successfully activated your Crystal Bot account."}
              { tokenValid && "Please check your activation link and try again."}
            </div>
            <Button className="mt-6" type="secondary" size="large">
              <a href="/">
                Back to app
              </a>
            </Button>
          </div>
        </h3>
      </div>
    )
  }

  generateLoader() {
    return (
      <Loader fontSize="30" position="absolute" bottom="0"/>
    )
  }

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        {loading && this.generateLoader()}
        {!loading && this.renderPageContent()}
      </React.Fragment>
    )
  }
}

export default compose(
  connect(null, { verifyEmail })
)(ConfirmEmailPage);