import React from 'react';
import StepWizard from 'react-step-wizard';
import Navigation from './Navigation';
import ConnectBinanceAccount from './Steps/ConnectBinanceAccount';
import SignContract from './Steps/SignContract';
import DepositFunds from './Steps/DepositFunds';
import Finish from './Steps/Finish';
import { Icon, Alert } from 'antd';
import _ from 'lodash';

class Onboarding extends React.Component {
  componentDidMount() {
    const contentDOM = document.querySelector('.ant-layout-content');
    if (contentDOM) {
      contentDOM.classList.add('onboarding-wrapper')
    }
  }

  componentWillUnmount() {
    const contentDOM = document.querySelector('.ant-layout-content');
    if (contentDOM) {
      contentDOM.classList.remove('onboarding-wrapper')
    }
  }

  render() {
    const { user } = this.props;

    return (
      <div>
        <Alert closable style={{fontSize: "13px"}} message={<div>ATTENTION - IMPORTANT NOTICE: It is <b>prohibited to access or use our services if you are located</b>,
        incorporated or otherwise established in, or a citizen or resident of, that if you are located in or a resident of the <b>United States of America</b> or <b>Québec (Canada)</b>, <b>Singapore</b>, <b>Cuba</b>, <b>Crimea</b>, <b>Sevastopol</b>, <b>Iran</b>, <b>Syria</b>, <b>North Korea</b>, <b>Sudan</b>, or any other jurisdiction where the
        Services offered by CrystalBot.io are restricted, you also understand and acknowledge that you are <b>prohibited from holding positions or
        entering into contracts</b> at CrystalBot.io.</div>} type="warning" showIcon />

        <Alert closable style={{fontSize: "13px", marginTop: "20px", marginBottom: "20px"}} message={<div>You understand and acknowledge that if it is determined that any
        CrystalBot.io trading participant has given false representations as to their location or place of residence, CrystalBot.io <b>reserves the right to close any
        of their accounts immediately and to liquidate any open positions</b>.</div>} type="warning" showIcon />
        <Icon onClick={this.props.handleOnBoardingClose} type="close" className="onboarding-close" style={{fontSize: "20px"}}/>
        <StepWizard
          className="onboarding"
          nav={<Navigation user={user}/>}>
          <ConnectBinanceAccount/>
          <DepositFunds/>
          {/* <SignContract/> */}
          <Finish handleOnBoardingClose={this.props.handleOnBoardingClose}/>
        </StepWizard>
      </div>
    )
  }
}

export default Onboarding;